import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

import { getUserToken, getDeviceToken, subscribeToStatus } from "./auth"

const tenant = process.env.REACT_APP_TENANT === "irishlife" ? "IL" : "WS"

const uri = process.env.REACT_APP_API_BASE_URL || "https://api.i18t.net"

const authLink = setContext(async (_, { headers }) => {
  const token = getUserToken()
  const deviceToken = getDeviceToken()

  let ourHeaders = { ...headers }
  if (token) {
    ourHeaders = { ...ourHeaders, Authorization: `Bearer ${token}` }
  }
  if (deviceToken) {
    ourHeaders = { ...ourHeaders, "X-Trusted-Device": deviceToken }
  }

  ourHeaders = {
    ...ourHeaders,
    "X-Tenant": tenant,
    "X-Application": `${tenant}-admin`,
  }

  return {
    headers: ourHeaders,
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(
    createHttpLink({ uri: `${uri}/graphql`, credentials: "include" })
  ),
  cache: new InMemoryCache(),
})

// Clear store on logout
subscribeToStatus((status) => {
  if (status === "Anonymous") {
    apolloClient.clearStore()
  }
})

export default apolloClient
