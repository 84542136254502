import React from "react"
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client"
import { useParams } from "react-router"
import Table, { Row } from "../../../components/Table"
import { bankAccounts } from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import { formatTimeDate } from "../../../helpers/formatters"

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 30px auto;
`
export const BankAccountsQuery = gql`
  query bankAccounts($id: String!) {
    node(id: $id) {
      id
      __typename
      ... on Person {
        id
        bankAccounts {
          items {
            id
            accountNumber
            holderName
            disabledAt
            createdAt
          }
        }
      }
    }
  }
`
export const headings = [
  "Bank Account Number",
  "Date Added",
  "Holder",
  "Currency",
  "Status",
]

const BankAccounts = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<bankAccounts>(BankAccountsQuery, {
    variables: { id },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  if (data.node.__typename !== "Person") {
    return <div>User not found</div>
  }

  const rows = data.node.bankAccounts.items

  return (
    <Wrapper data-testid="bankAccounts">
      <Table columnNames={headings} isEmpty={rows?.length === 0}>
        {rows.map(
          ({
            id: bankAccountId,
            accountNumber,
            holderName,
            disabledAt,
            createdAt,
          }) => (
            <Row
              key={bankAccountId}
              values={[
                accountNumber,
                formatTimeDate(createdAt),
                holderName || "-",
                "EUR",
                disabledAt ? "Inactive" : "Active",
              ]}
            />
          )
        )}
      </Table>
    </Wrapper>
  )
}

export default BankAccounts
