import { getUserToken, subscribeToStatus } from "../auth"
import { DecodedUserToken, decodeToken } from "../auth/tokenUtils"

interface FeatureDefinition {
  [featureName: string]: (
    tenant: string,
    role: string | null,
    personId: string | null
  ) => boolean
}

let subscriptions: (() => void)[] = []
let featuresConfig: FeatureDefinition = {}

subscribeToStatus(() => {
  subscriptions.forEach((cb) => cb())
})

export function initFeatureConfig(config: FeatureDefinition) {
  featuresConfig = config
}

/**
 * Allows a component to listen to feature enable changes
 *
 * @param callback (featureStatus: boolean[]):void => {}
 */
export function subscribeToFeature(callback: () => void): void {
  subscriptions = [...subscriptions, callback]
}

/**
 * Clean up existing subscription.
 *
 * @param callback (featureStatus: featureStatus[]):void => {}
 */
export function unsubscribeToFeature(callback: () => void): void {
  subscriptions = subscriptions.filter((cb) => cb !== callback)
}

/**
 * Returns the status based in the global state when the call happen. Useful for example to initialise
 * useState.
 *
 * But, if you want to react to state changes, better use useFeatureConfig hook.
 *
 * @param features string[]
 */
export function getCurrentFeatureStatus(features: string[]): boolean[] {
  const tenant = process.env.REACT_APP_TENANT === "irishlife" ? "IL" : "WS"
  const decodedUserToken = decodeToken(getUserToken()) as DecodedUserToken
  const role = decodedUserToken?.role || null
  const personId = decodedUserToken?.sub || null

  return features.map((feature) => {
    if (!featuresConfig[feature]) {
      return false
    }
    return featuresConfig[feature](tenant, role, personId)
  })
}
