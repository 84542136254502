import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const CREATE_MARKET_INSIGHT = gql`
  mutation createMarketInsights(
    $title: String!
    $subtitle: String!
    $description: String!
  ) {
    createMarketInsights(
      input: { title: $title, subtitle: $subtitle, description: $description }
    ) {
      title
      subtitle
      description
    }
  }
`

export const FETCH_MARKETING_INSIGHTS = gql`
  query getMarketingInsight {
    getMarketingInsight {
      id
      title
      subtitle
      description
    }
  }
`

export const UPDATE_MARKET_INSIGHTS = gql`
  mutation updateMarketingInsight(
    $id: String!
    $title: String!
    $subtitle: String!
    $description: String!
  ) {
    updateMarketingInsight(
      input: {
        id: $id
        title: $title
        subtitle: $subtitle
        description: $description
      }
    ) {
      id
      title
      subtitle
      description
    }
  }
`
export const DELETE_MARKET_INSIGHTS = gql`
  mutation deleteMarketingInsight($id: String!) {
    deleteMarketingInsight(input: { id: $id }) {
      title
    }
  }
`
