import React from "react"
import styled from "styled-components"

import Title from "../../../components/Title"
import Actions from "./Actions"
import LoginHistory from "./LoginHistory"

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 30px auto;
`

const Security = () => {
  return (
    <Wrapper data-testid="security">
      <Title>Login History</Title>
      <Actions />
      <LoginHistory />
    </Wrapper>
  )
}

export default Security
