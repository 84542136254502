import { gql } from "@apollo/client"

export const GET_PERSON_NAME_QUERY = gql`
  query getPersonName {
    person {
      id
      name {
        first
      }
    }
  }
`
