import React, { ReactElement } from "react"

import Button from "../../components/Button"
import QrCode from "./QrCode"
import { ButtonView, Paragraph, TitleText } from "./styles"

const ScanQrCode = ({
  onNext,
  qrDataUrl,
  otpAuthUrl,
}: {
  onNext: () => void
  qrDataUrl: string
  otpAuthUrl: string
}): ReactElement => {
  return (
    <>
      <TitleText>Scan this code with your app</TitleText>
      <Paragraph>
        Link your authenticator app to your account by scanning the code below.
        When you have scanned it, continue to the next step.
      </Paragraph>
      {qrDataUrl && otpAuthUrl ? (
        <QrCode qrDataUrl={qrDataUrl} otpAuthUrl={otpAuthUrl} />
      ) : (
        <p>An error occurred. Please go back and try again</p>
      )}
      <ButtonView>
        <Button
          type="submit"
          text="Next"
          onClick={onNext}
          loading={false}
          disabled={!qrDataUrl || !otpAuthUrl}
        />
      </ButtonView>
    </>
  )
}

export default ScanQrCode
