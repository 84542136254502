import { gql, useMutation } from "@apollo/client"
import React, { ReactElement, useState } from "react"
import {
  resetPassword,
  resetPasswordVariables,
  signInOnboarding,
} from "../../@types/graphql"
import { clearUserToken, setUserToken } from "../../auth"

import Button from "../../components/Button"
import InputField from "../../components/InputField"
import useInputState, { matchRegex, notEmpty } from "../../hooks/useInputState"
import { ButtonView, ErrorMessage, TitleText } from "./styles"
import { decryptString } from "../../utils/encryptionCrypto"

export const SIGN_IN_MUTATION = gql`
  mutation signInOnboarding(
    $emailAddress: String!
    $password: String!
    $mfaToken: String
    $multiSession: Boolean
  ) {
    signInWithEmailAddress(
      input: {
        emailAddress: $emailAddress
        password: $password
        mfaToken: $mfaToken
        multiSession: $multiSession
      }
    ) {
      userToken
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`

const ResetPassword = ({
  onNext,
  email,
  code,
}: {
  onNext: () => void
  email: string
  code: string
}): ReactElement => {
  const [errorMessage, setErrorMessage] = useState("")
  const decryptedEmail = decryptString(email)

  const inputStateNewPassword = useInputState("", [
    notEmpty("Password is required"),
    matchRegex("At least one lowercase is required", /[a-z]/),
    matchRegex("At least one uppercase is required", /[A-Z]/),
    matchRegex("At least one number is required", /[0-9]/),
    matchRegex(
      "At least one special character is required",
      /(?=.*?[#?!@$%^&*-])+/
    ),
    (value) => (value.length < 8 ? "At least 8 characters is required" : null),
  ])

  const inputStateConfirmPassword = useInputState("", [
    notEmpty("Password is required"),
    (confirmPasswordValue) =>
      confirmPasswordValue !== inputStateNewPassword.value
        ? "Password does not match"
        : null,
  ])

  const [signInMutation] = useMutation<signInOnboarding>(SIGN_IN_MUTATION)

  const [
    resetPasswordMutation,
    { loading: resetPasswordLoading },
  ] = useMutation<resetPassword, resetPasswordVariables>(
    RESET_PASSWORD_MUTATION
  )

  const handleSignInWithNewPassword = async (): Promise<void> => {
    await resetPasswordMutation({
      variables: {
        input: {
          emailAddress: decryptedEmail,
          code,
          password: inputStateNewPassword.value.trim(),
        },
      },
    })
      .then(async (response) => {
        if (response.data?.resetPassword.success) {
          await signInMutation({
            variables: {
              emailAddress: decryptedEmail,
              password: inputStateConfirmPassword.value.trim(),
            },
          })
            .then((result) => {
              const { userToken } = result.data?.signInWithEmailAddress || {
                userToken: null,
              }

              if (userToken) {
                setErrorMessage("")
                setUserToken(userToken)
                onNext()
              } else {
                setErrorMessage("Something went wrong")
                clearUserToken()
              }
            })
            .catch(() => setErrorMessage("Something went wrong"))
        }
      })
      .catch((mutationError) => {
        if (mutationError.graphQLErrors[0].message === "Not Found") {
          setErrorMessage("Link is already used")
        } else {
          setErrorMessage("Something went wrong")
        }
      })
  }

  return (
    <>
      <TitleText>Reset your password</TitleText>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <InputField
        disabled
        type="email"
        fieldName="email"
        labelText="Email"
        placeholder={decryptedEmail || ""}
        value={decryptedEmail || ""}
        onChange={() => {}}
        onBlur={() => {}}
        errorMessage=""
      />
      <InputField
        type="password"
        fieldName="new password"
        labelText="New Password"
        value={inputStateNewPassword.value}
        onChange={inputStateNewPassword.onChange}
        onBlur={inputStateNewPassword.onBlur}
        errorMessage={inputStateNewPassword.errorMessage}
      />
      <InputField
        type="password"
        fieldName="confirm password"
        labelText="Re-enter Password"
        value={inputStateConfirmPassword.value}
        onChange={inputStateConfirmPassword.onChange}
        onBlur={inputStateConfirmPassword.onBlur}
        errorMessage={inputStateConfirmPassword.errorMessage}
      />
      <ButtonView>
        <Button
          type="button"
          text="Next"
          onClick={handleSignInWithNewPassword}
          loading={resetPasswordLoading}
          disabled={
            !inputStateNewPassword.hasValidValue ||
            !inputStateConfirmPassword.hasValidValue ||
            inputStateConfirmPassword.value !== inputStateNewPassword.value
          }
        />
      </ButtonView>
    </>
  )
}

export default ResetPassword
