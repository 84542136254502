import React, { ComponentProps, ReactElement } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"

const StyledTabs = styled.ul`
  max-width: 1080px;
  list-style: none;
  margin: 0 auto;
  padding: 0;
`

const StyledTab = styled.li<{ selected: boolean }>`
  display: inline-block;
  padding: 1em;
  border-bottom: ${(props) =>
    props.selected ? "2px solid black" : "2px solid transparent"};
  color: ${(props) => props.theme.typography};
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
`

export const Tab = ({
  to,
  label,
  selected = false,
}: {
  to: string
  label: string
  selected?: boolean
  defaultTab?: boolean // Used by Tabs component
}) => {
  return (
    <Link to={to}>
      <StyledTab selected={selected}>{label}</StyledTab>
    </Link>
  )
}

const Tabs = ({
  children,
}: {
  children: ReactElement<ComponentProps<typeof Tab>>[]
}) => {
  const { pathname } = useLocation()

  let selected: number
  React.Children.forEach(children, (child, index) => {
    if (child.props.to === pathname) {
      selected = index
    }
  })

  return (
    <StyledTabs>
      {React.Children.map(children, (child, index) => {
        if (selected === index || (!selected && child.props.defaultTab)) {
          return React.cloneElement(child, { selected: true })
        }
        return child
      })}
    </StyledTabs>
  )
}
export default Tabs
