import React from "react"
import styled from "styled-components"
import {
  transactions_node_Account_transactions_items_DepositTransaction_depositStatus,
  transactions_node_Account_transactions_items_OrderTransaction_orderStatus,
  transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus,
} from "../../../../@types/graphql"
import AttributeList from "../../../../components/AttributeList"
import { formatTimeDate } from "../../../../helpers/formatters"
import { numberFormat } from "../../../../helpers/numberFormatter"

export const ONBOARDING_STALLED = "onboarding-stalled"

const TransactionCard = styled.div`
  background: ${({ theme }) => theme.typographyInverse};
  border: ${({ theme }) => `1px solid ${theme.unfocused}`};
  margin: 10px auto;
  padding: 10px 50px;

  > h3 {
    font-size: ${({ theme }) => theme.headingFontSize};
    margin: 8px 0px;
  }
`

const StatusSection = styled.div`
  display: flex;
  flex-direction: row;

  > ul {
    width: 50%;
    margin-bottom: 8px;
  }
`

interface Transaction {
  type: string
  id: string
  date: string
  status: string | null
  amount: string
  details?: Record<string, string | undefined>
}

const Transaction = ({
  type,
  id,
  date,
  status,
  amount,
  details,
}: Transaction) => {
  return (
    <TransactionCard data-testid="transaction">
      <h3>{type}</h3>
      <StatusSection>
        <AttributeList orientation="horizontal" compressed>
          <li>
            <h4>Transaction ID</h4>
            <p>{id}</p>
          </li>
          <li>
            <h4>Transaction Date</h4>
            <p>{formatTimeDate(date, undefined, true)}</p>
          </li>
          <li>
            <h4>Status</h4>
            <p>{status}</p>
          </li>
          <li>
            <h4>Amount</h4>
            <p>{numberFormat(parseFloat(amount), true)}</p>
          </li>
        </AttributeList>
        <AttributeList orientation="horizontal" compressed>
          {/* For right side content, pass it as an object as a prop and iterate over key values to make <li>s */}
          {details &&
            Object.entries(details).map(([k, v]) => (
              <li key={k}>
                <h4>{k}</h4>
                <p>
                  {v?.split(";").map((l, index) => (
                    <span key={index}>
                      {l}
                      {index === 0 && v?.split(";").length > 1 && <br />}
                      {index > 0 && <br />}
                    </span>
                  ))}
                </p>
              </li>
            ))}
        </AttributeList>
      </StatusSection>
    </TransactionCard>
  )
}

export const OrderTransaction = ({
  status,
}: {
  status: transactions_node_Account_transactions_items_OrderTransaction_orderStatus
}) => {
  const securityName = ["FIB3", "FIB4", "FIB5"].includes(
    status.order.security.symbol || ""
  )
    ? `${status.order.security.name} Series R`
    : status.order.security.name

  return (
    <Transaction
      id={status.order.id}
      type={status.order.side === "Buy" ? "Funds invested" : "Funds sold"}
      status={status.value}
      date={status.startedAt}
      amount={status.order.value}
      details={
        status.order.side === "Buy"
          ? {
              "Security purchased": securityName,
              "Units purchased": status.order.quantity,
              "Purchase price": status.order.price,
            }
          : {
              "Security sold": securityName,
              "Units sold": status.order.quantity,
              "Sale price": status.order.price,
            }
      }
    />
  )
}

export const WithdrawalTransaction = ({
  status,
}: {
  status: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus
}) => (
  <Transaction
    id={status.withdrawal.id}
    type="Withdrawal"
    date={status.startedAt}
    status={status.value}
    amount={status.withdrawal.value.amount}
    details={{
      "Fees/Taxes": numberFormat(
        status.withdrawal.taxes.reduce(
          (value, tax) => value + parseFloat(tax.value.amount),
          0
        )
      ),
      "Payment reference": status.withdrawal.externalId || "-",
      Recipient: status.withdrawal.bankAccount?.accountNumber || "-",
    }}
  />
)

export const DepositTransaction = ({
  status,
}: {
  status: transactions_node_Account_transactions_items_DepositTransaction_depositStatus
}) => (
  <Transaction
    id={status.deposit.id}
    type="Deposit"
    date={status.startedAt}
    status={status.value}
    amount={status.deposit.value.amount}
    details={{
      "Payment reference": status.deposit.externalId || "-",
      "Source of funds": status.deposit.sourceOfFunds || "-",
    }}
  />
)

export default Transaction
