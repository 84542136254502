/* eslint-disable no-console */
import { gql, useMutation, useQuery } from "@apollo/client"
import React, { FC, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { getAdminsData, sendAdminInvitation } from "../../@types/graphql"
import Button from "../../components/Button"
import Loader from "../../components/Loader"
import Table, { Row } from "../../components/Table"
import { formatTimeDate } from "../../helpers/formatters"
import useFeatureConfig from "../../hooks/useFeatureConfig"
import { Paragraph, Wrapper } from "../Dashboard/styles"
import InviteAdminModal from "./InviteAdminModal"
import {
  DISABLE_PERSON,
  ENABLE_PERSON,
  SEND_ADMIN_INVITATION,
} from "./mutations"

export const DEFAULT_HEADINGS = [
  "Email",
  "Role",
  "Created on",
  "MFA state",
  "Last login date",
  "Last IP address",
  "Actions",
]

const ButtonView = styled.div`
  padding-left: 1em;
  width: 20%;
`

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ADMIN_PAGE_QUERY = gql`
  query getAdminsData {
    tenant {
      people(includeRoles: [ReadOnlyAdmin, Admin, SuperAdmin]) {
        items {
          id
          emailAddress
          role
          createdAt
          mfaEnabled
          mfaMethod
          disabledAt
          signIns {
            items {
              createdAt
              ipAddress
              userAgent
            }
          }
        }
      }
    }
  }
`

const AdminPage: FC = () => {
  const history = useHistory()
  const [hideIPAddress] = useFeatureConfig(["HideIPAddress"])
  const [showInviteAdminModal, setShowInviteAdminModal] = useState<boolean>(
    false
  )

  const { loading: dataLoading, error, data } = useQuery<getAdminsData>(
    ADMIN_PAGE_QUERY
  )
  const [
    sendAdminInvitationMutation,
    { loading: sendingLoading },
  ] = useMutation<sendAdminInvitation>(SEND_ADMIN_INVITATION)

  const [enablePerson] = useMutation(ENABLE_PERSON)
  const [disablePerson] = useMutation(DISABLE_PERSON)

  if (dataLoading || sendingLoading) {
    return <Loader />
  }

  if (error || !data) {
    return <div>Error</div>
  }

  const headings = hideIPAddress
    ? DEFAULT_HEADINGS.filter((h) => h !== "Last IP address")
    : DEFAULT_HEADINGS

  const rows = data?.tenant?.people?.items.map(
    ({
      id: userId,
      emailAddress,
      role,
      createdAt,
      mfaMethod,
      mfaEnabled,
      signIns,
      disabledAt,
    }) => {
      const mfaState = mfaEnabled ? `${mfaMethod} Enabled` : "Disabled"
      const lastSignIn = signIns.items[0]
      const lastLoginDate = lastSignIn?.createdAt || ""
      const lastIpAddress = lastSignIn?.ipAddress || ""

      const enableButton = (
        <Button
          type="button"
          text="Enable"
          mainAction={false}
          onClick={() =>
            enablePerson({
              variables: {
                personId: userId,
              },
            }).catch((e) => console.log(e))
          }
          loading={false}
        />
      )
      const disableButton = (
        <Button
          type="button"
          text="Disable"
          mainAction={false}
          onClick={() =>
            disablePerson({
              variables: {
                personId: userId,
              },
            }).catch((err) => console.log(err))
          }
          loading={false}
        />
      )
      const resendInviteButton = (
        <Button
          type="button"
          text="Resend Invite"
          mainAction={false}
          onClick={() =>
            sendAdminInvitationMutation({
              variables: {
                personId: userId,
              },
            }).catch((err) => console.log(err))
          }
          loading={false}
        />
      )
      const actionButtons = () => {
        if (!mfaEnabled && !disabledAt) return resendInviteButton
        if (disabledAt) return enableButton
        return disableButton
      }

      const values = hideIPAddress
        ? [
            emailAddress,
            role,
            formatTimeDate(createdAt),
            mfaState,
            lastLoginDate && formatTimeDate(lastLoginDate),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            actionButtons() as any,
          ]
        : [
            emailAddress,
            role,
            formatTimeDate(createdAt),
            mfaState,
            lastLoginDate && formatTimeDate(lastLoginDate),
            lastIpAddress,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            actionButtons() as any,
          ]

      return <Row key={userId} values={values} />
    }
  )

  return (
    <>
      {showInviteAdminModal && (
        <InviteAdminModal
          onClose={() => {
            setShowInviteAdminModal(false)
          }}
        />
      )}
      <Wrapper data-testid="adminUsers">
        <TopArea>
          <Paragraph>Middle Office administration</Paragraph>
          <ButtonView>
            <Button
              type="button"
              text="Login history"
              mainAction={false}
              onClick={() => history.push("/logins-history")}
              loading={false}
            />
          </ButtonView>
          <ButtonView>
            <Button
              type="button"
              text="Market Insights"
              mainAction={false}
              onClick={() => history.push("/market-insights")}
              loading={false}
            />
          </ButtonView>
          <ButtonView>
            <Button
              type="button"
              text="Fund Factsheets"
              mainAction={false}
              onClick={() => history.push("/fund-factsheets")}
              loading={false}
            />
          </ButtonView>
          <ButtonView>
            <Button
              type="button"
              text="Top 10 Share Holdings"
              mainAction={false}
              onClick={() => history.push("/top10-shareholdings")}
              loading={false}
            />
          </ButtonView>
          <ButtonView>
            <Button
              type="button"
              text="Sustainability"
              mainAction={false}
              onClick={() => history.push("/sustainability")}
              loading={false}
            />
          </ButtonView>
          <ButtonView>
            <Button
              type="button"
              text="Invite new user"
              mainAction={false}
              onClick={() => setShowInviteAdminModal(true)}
              loading={false}
            />
          </ButtonView>
        </TopArea>
        <Table columnNames={headings} isEmpty={rows?.length === 0}>
          {rows}
        </Table>
      </Wrapper>
    </>
  )
}

export default AdminPage
