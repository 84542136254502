import React, { FC } from "react"
import { useQuery } from "@apollo/client"
import styled from "styled-components"

import Loader from "../../components/Loader"
import { GET_PERSON_NAME_QUERY } from "./query"

import { Paragraph } from "./styles"
import { getPersonName } from "../../@types/graphql"

const WelcomeText = styled.div`
  font-size: ${(props) => props.theme.titleFontSize};
  padding-bottom: 1em;
`
const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8em;
`

const DashboardPage: FC = () => {
  const { loading, error, data } = useQuery<getPersonName>(
    GET_PERSON_NAME_QUERY
  )

  if (loading) return <Loader />

  if (error) {
    return <div>Error</div>
  }

  const user = data?.person?.name.first

  return (
    <HeaderContent data-testid="dashboardPage">
      <WelcomeText>Welcome {user}</WelcomeText>
      <Paragraph>Start by searching for a client with their</Paragraph>
      <Paragraph>name, plan number or email address.</Paragraph>
    </HeaderContent>
  )
}

export default DashboardPage
