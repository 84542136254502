import React from "react"
import styled from "styled-components"

import AttributeList from "../../../../components/AttributeList"
import { formatTimeDate, formatUserAgent } from "../../../../helpers/formatters"
import useFeatureConfig from "../../../../hooks/useFeatureConfig"

interface AgreementData {
  type: string
  date?: string
  device?: string
  ip?: string
  signed?: boolean
}

const AgreementCard = styled.div`
  background: ${({ theme }) => theme.typographyInverse};
  border: ${({ theme }) => `1px solid ${theme.unfocused}`};
  margin: 10px auto;
  padding: 10px 50px;

  > h3 {
    font-size: ${({ theme }) => theme.headingFontSize};
    margin: 8px 0px;
  }
`

const StatusSection = styled.div`
  display: flex;
  flex-direction: row;

  > ul {
    width: 100%;
    margin-bottom: 8px;
  }
`

const StatusSectionHeader = styled.h3`
  margin-bottom: 10px;
`

const Agreement = ({ type, date, device, ip, signed }: AgreementData) => {
  const [hideIPAddress] = useFeatureConfig(["HideIPAddress"])
  return (
    <AgreementCard data-testid="agreement">
      <StatusSectionHeader>{type}</StatusSectionHeader>
      <StatusSection>
        <AttributeList orientation="horizontal">
          <li>
            <h4>Date</h4>
            <p>{date ? formatTimeDate(date) : "-"}</p>
          </li>
          <li>
            <h4>Device</h4>
            <p>{device ? formatUserAgent(device) : "-"}</p>
          </li>
          {!hideIPAddress && (
            <li>
              <h4>IP Address</h4>
              <p>{ip || "-"}</p>
            </li>
          )}
          <li>
            <h4>Status</h4>
            <p>{signed ? "Signed" : "Unsigned"}</p>
          </li>
        </AttributeList>
      </StatusSection>
    </AgreementCard>
  )
}

export default Agreement
