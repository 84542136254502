import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useHistory, useParams } from "react-router"
import { accounts } from "../../../@types/graphql"

import Loader from "../../../components/Loader"
import Table, { Row } from "../../../components/Table"
import { numberFormat } from "../../../helpers/numberFormatter"
import useFeatureConfig from "../../../hooks/useFeatureConfig"
import Container from "../../../components/Container"

export const AccountsQuery = gql`
  query accounts($id: String!) {
    node(id: $id) {
      id
      ... on Person {
        accounts {
          items {
            account {
              id
              externalId
              type
              status
              backOfficeAccount {
                backOfficeId
              }
              value(currency: "EUR")
              portfolio {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const types: {
  [key: string]: string
} = {
  GbPension: "Pension",
  GbIsa: "ISA",
  GbPersonal: "Investment",
  IePersonal: "Investment",
  GbJuniorIsa: "Junior ISA",
}

const accountType = (apiType: string): string => {
  return types[apiType] || ""
}

const Accounts = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [showExternalIdAsAccountId] = useFeatureConfig([
    "ExternalIdAsAccountId",
  ])
  const tableHeadings = [
    showExternalIdAsAccountId ? "External ID" : "Account ID",
    "Plan ID",
    "Type",
    "Currency",
    "AUM",
    "Status",
    "Portfolio",
  ]

  const { loading, error, data } = useQuery<accounts>(AccountsQuery, {
    variables: { id },
  })

  if (loading) {
    return <Loader />
  }

  if (loading || !data) {
    return null
  }

  if (error) {
    return <div>Error</div>
  }

  if (data.node.__typename !== "Person") {
    return <div>Person error</div>
  }

  const accountList = (data?.node?.accounts?.items || []).map(
    (entry) => entry.account
  )

  const goToAccountPage = (accountId: string) => {
    history.push(`/account/${accountId}`)
  }

  return (
    <Container>
      <Table
        columnNames={tableHeadings}
        isEmpty={accountList.length === 0}
        noResultMessage="No accounts associated found"
      >
        {accountList.map((account) => (
          <Row
            key={account.id}
            onClick={() => goToAccountPage(account.id)}
            values={[
              showExternalIdAsAccountId
                ? account.externalId || "-"
                : account.id,
              account.backOfficeAccount?.backOfficeId || "-",
              accountType(account.type),
              "EUR",
              numberFormat(parseFloat(account.value), false),
              account.status,
              account.portfolio?.name || "-",
            ]}
          />
        ))}
      </Table>
    </Container>
  )
}

export default Accounts
