import React from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"

import {
  searchPeople,
  searchPeople_tenant_people_items_accounts,
} from "../../@types/graphql"
import Table, { Row } from "../../components/Table"
import { Wrapper } from "../Dashboard/styles"
import { formatTimeDate } from "../../helpers/formatters"

export const headings = [
  "First name",
  "Last name",
  "Email",
  "Date of birth",
  "Status",
  "Source",
  "Corp code",
  "Registration date",
]

export const SearchPageQuery = gql`
  query searchPeople($query: String!) {
    tenant {
      people(query: $query) {
        items {
          id
          role
          name {
            first
            last
          }
          adminTags
          accessCode
          dateOfBirth {
            dateOfBirth
          }
          accounts {
            items {
              account {
                status
              }
            }
          }
          emailAddress
          createdAt
        }
      }
    }
  }
`

const SearchPage = () => {
  const history = useHistory()
  const querySearch = new URLSearchParams(useLocation().search).get("query")

  const { loading, error, data } = useQuery<searchPeople>(SearchPageQuery, {
    variables: { query: querySearch },
  })

  if (loading || error) {
    return null
  }

  if (!data?.tenant) {
    return <div>No results</div>
  }

  const goToUserPage = (id: string) => {
    history.push(`/person/${id}`)
  }

  const getOnboardingStatus = (
    adminTags: string[],
    accounts: searchPeople_tenant_people_items_accounts
  ): string => {
    const hasAccount = accounts?.items.length > 0

    if (!adminTags.includes("onboarding-complete")) {
      return "Incomplete"
    }
    return adminTags.includes("onboarding-complete") && hasAccount
      ? accounts.items[0].account.status
      : "-"
  }

  const rows = data?.tenant?.people.items.map(
    ({
      id,
      name,
      emailAddress,
      dateOfBirth,
      accounts,
      accessCode,
      adminTags,
      createdAt,
    }) => {
      return (
        <Row
          key={id}
          onClick={() => goToUserPage(id)}
          values={[
            name.first || "-",
            name.last || "-",
            emailAddress,
            formatTimeDate(dateOfBirth.dateOfBirth, false),
            getOnboardingStatus(adminTags, accounts),
            adminTags.includes("ptsb-user") === true ? "PTSB" : "IL",
            accessCode || "-",
            formatTimeDate(createdAt),
          ]}
        />
      )
    }
  )

  return (
    <Wrapper data-testid="searchPage">
      <Table
        columnNames={headings}
        isEmpty={rows?.length === 0}
        noResultMessage="No search results"
      >
        {rows}
      </Table>
    </Wrapper>
  )
}

export default SearchPage
