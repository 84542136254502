import { useQuery } from "@apollo/client"
import React, { FC, useState } from "react"
import styled from "styled-components"
import {
  getFundFactSheet,
  getFundFactSheet_getFundFactSheet,
} from "../../@types/graphql"
import Table, { Row } from "../../components/Table"
import Button from "../../components/Button"
import { Paragraph, Wrapper } from "../Dashboard/styles"
import strings from "../../components/localization/strings"
import { FETCH_FUND_FACT } from "./queries"
import AddNewFundFactModal from "./AddNewFundFact"
import DeleteFundFactModal from "./DeleteFundFact"
import UpdateFundFactModal from "./UpdateFundFact"

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonView = styled.div`
  padding-left: 1em;
  width: 20%;
`
const FundFactSheet: FC = () => {
  const headers = [
    "Client Fund",
    "Asset Class",
    "Subcategory",
    "Percentage",
    " ",
    "Actions",
  ]
  const { data, refetch } = useQuery<
    getFundFactSheet,
    getFundFactSheet_getFundFactSheet
  >(FETCH_FUND_FACT)

  const fundFactData = data?.getFundFactSheet
  const [showAddFundFact, setShowAddFundFact] = useState<boolean>(false)
  const [showUpdateFundFact, setShowUpdateFundFact] = useState<boolean>(false)
  const [showDeleteFundfact, setShowDeleteFundFact] = useState<boolean>(false)
  const [id, setId] = useState<string>(" ")
  const [clientFundValue, setClientFundValue] = useState<string>("")
  const [assetClassValue, setAssetClassValue] = useState<string>(" ")
  const [subcategoryValue, setSubcategoryValue] = useState<string>(" ")
  const [percentageValue, setPercentageValue] = useState<number>(0)
  const [, setReload] = useState(false)

  const factSheetRows = fundFactData?.map(
    ({ id: fundId, client_fund, asset_class, subcategory, percentage }) => {
      const deleteButton = (
        <Button
          type="button"
          text="Delete"
          mainAction={false}
          onClick={() => {
            setId(fundId)
            setShowDeleteFundFact(true)
          }}
          loading={false}
        />
      )
      const updateButton = (
        <Button
          type="button"
          text="Update"
          mainAction={false}
          onClick={() => {
            setId(fundId)
            if (client_fund !== null) setClientFundValue(client_fund)
            setAssetClassValue(asset_class)
            setSubcategoryValue(subcategory)
            setPercentageValue(percentage)
            setShowUpdateFundFact(!showUpdateFundFact)
          }}
          loading={false}
        />
      )

      const DeleteFactSheetButton = () => {
        return deleteButton
      }
      const UpdateFactSheetButton = () => {
        return updateButton
      }

      const fundValues = [
        client_fund,
        asset_class,
        subcategory,
        percentage !== 0 ? [percentage?.toString(), " %"] : null,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        UpdateFactSheetButton() as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        DeleteFactSheetButton() as any,
      ]
      return <Row key={fundId} values={fundValues} />
    }
  )

  return (
    <>
      {showAddFundFact && (
        <AddNewFundFactModal
          onClose={() => {
            setShowAddFundFact(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
        />
      )}
      {showUpdateFundFact && (
        <UpdateFundFactModal
          onClose={() => {
            setShowUpdateFundFact(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          fundId={id}
          clientFund={clientFundValue}
          assetClass={assetClassValue}
          subCategory={subcategoryValue}
          percentage={percentageValue}
        />
      )}
      {showDeleteFundfact && (
        <DeleteFundFactModal
          onClose={() => {
            setShowDeleteFundFact(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          fundId={id}
        />
      )}
      <Wrapper>
        <TopArea>
          <Paragraph>{strings.FUND_FACTSHEET}</Paragraph>
          <ButtonView>
            <Button
              type="button"
              text={strings.ADD_FACTSHEET}
              mainAction={false}
              onClick={() => setShowAddFundFact(true)}
              loading={false}
            />
          </ButtonView>
        </TopArea>
        <TopArea>
          <Table columnNames={headers} isEmpty={factSheetRows?.length === 0}>
            {factSheetRows}
          </Table>
        </TopArea>
      </Wrapper>
    </>
  )
}

export default FundFactSheet
