const tenant = process.env.REACT_APP_TENANT === "irishlife" ? "IL" : "WS"

const url = process.env.REACT_APP_API_BASE_URL || "https://api.i18t.net"

export async function refreshToken(): Promise<string | null> {
  const httpResponse = await fetch(`${url}/refresh`, {
    headers: { "X-Tenant": tenant, "X-Application": `${tenant}-admin` },
    credentials: "include",
  })

  const { userToken } = await httpResponse.json()
  return userToken || null
}
