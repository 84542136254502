import { gql, useMutation } from "@apollo/client"
import React from "react"
import {
  TenantRole,
  setAdminRole,
  sendAdminInvitation,
  createAdminAccount,
} from "../../../@types/graphql"
import Button from "../../../components/Button"
import Title from "../../../components/Title"
import { SEND_ADMIN_INVITATION } from "../mutations"
import { ButtonView } from "./styles"

export const rolesMap: {
  [key: string]: string
} = {
  ReadOnlyAdmin: "Agent",
  Admin: "Admin",
}

export const CREATE_ADMIN_PERSON = gql`
  mutation createAdminAccount($email: String!, $accessCode: String!) {
    createPerson(
      input: {
        emailAddress: $email
        sendVerificationEmail: false
        accessCode: $accessCode
      }
    ) {
      id
      version
    }
  }
`

export const SET_ADMIN_ROLE = gql`
  mutation setAdminRole(
    $personId: String!
    $version: Int!
    $role: TenantRole!
  ) {
    setPersonRole(
      input: { personId: $personId, version: $version, role: $role }
    ) {
      id
      version
    }
  }
`

const InviteConfirm = ({
  email,
  role,
  onCancel,
  onSuccess,
  onError,
  onExisting,
}: {
  email: string
  role: TenantRole
  onCancel: () => void
  onSuccess: () => void
  onError: (error: string) => void
  onExisting: () => void
}) => {
  const [
    createAdminAccountMutation,
    { loading: creatingAccount },
  ] = useMutation<createAdminAccount>(CREATE_ADMIN_PERSON)

  const [setAdminRoleMutation, { loading: settingAdminRole }] = useMutation<
    setAdminRole
  >(SET_ADMIN_ROLE)

  const [
    sendAdminInvitationMutation,
    { loading: sendingInvitation },
  ] = useMutation<sendAdminInvitation>(SEND_ADMIN_INVITATION)

  const handleSubmit = async (): Promise<void> => {
    const emailAddressValue = email.trim()
    const { data: createPersonData } = await createAdminAccountMutation({
      variables: {
        email: emailAddressValue,
        accessCode: "Corporate1",
      },
    }).catch((error) => {
      if (error.message === "Conflict") {
        onExisting()
      } else {
        onError(error.message)
      }
      return { data: null }
    })

    if (!createPersonData) {
      return
    }

    const personId = createPersonData?.createPerson.id
    const version = createPersonData?.createPerson.version

    const { data: setAdminRoleData } = await setAdminRoleMutation({
      variables: {
        personId,
        version,
        role,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })

    if (!setAdminRoleData) {
      return
    }

    const { data: sendAdminInvitationData } = await sendAdminInvitationMutation(
      {
        variables: {
          personId,
        },
      }
    ).catch((error) => {
      onError(error.message)
      return { data: null }
    })

    if (!sendAdminInvitationData) {
      return
    }

    onSuccess()
  }

  return (
    <>
      <Title>Confirm user</Title>
      <p style={{ marginTop: 0 }}>
        Email
        <br />
        {email}
      </p>
      <p>
        Role
        <br />
        {rolesMap[role]}
      </p>
      <p>
        Are you sure you want to grant this user access to the Middle Office?
      </p>
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={creatingAccount || settingAdminRole || sendingInvitation}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={creatingAccount || settingAdminRole || sendingInvitation}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default InviteConfirm
