import React, { FormEventHandler } from "react"
import styled from "styled-components"
import InputField from "../../components/InputField"
import Title from "../../components/Title"
import strings from "../../components/localization/strings"
import useInputState, { notEmpty } from "../../hooks/useInputState"
import Button from "../../components/Button"

const ButtonView = styled.div`
  margin-top: 1.5em;
`

const AddRewardForm = ({
  username,
  onSubmit,
}: {
  username: string
  onSubmit: (amount: string) => void
}) => {
  const {
    value: amount,
    hasValidValue: amountIsValid,
    errorMessage: amountErrorMessage,
    onChange: onChangeDescription,
    onBlur: onBlurDescription,
  } = useInputState("", [notEmpty("This field is required")])

  const isEnabled = !amountIsValid
  const handleSubmit: FormEventHandler = (ev) => {
    ev.preventDefault()
    onBlurDescription()

    if (amountIsValid) {
      onSubmit(amount.trim())
    }
  }
  return (
    <>
      <Title>{strings.REWARD_POINTS}</Title>
      <form onSubmit={handleSubmit}>
        <InputField
          type="text"
          fieldName="text"
          labelText={strings.USERNAME}
          value={username}
          onChange={() => {}}
          onBlur={() => {}}
          disabled
        />

        <InputField
          type="number"
          fieldName="text"
          labelText={strings.AMOUNT}
          value={amount}
          onChange={onChangeDescription}
          onBlur={onBlurDescription}
          errorMessage={amountErrorMessage}
          placeholder="Enter Amount"
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={isEnabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default AddRewardForm
