import React, { ReactNode } from "react"
import styled from "styled-components"
import Button from "../Button"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #20200020;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled.div`
  position: relative;
  max-width: 33%;
  padding: 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
`

const ConfirmationDialog = ({
  title,
  children,
  open,
  setOpen,
  onConfirm,
}: {
  title: string
  children?: ReactNode
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: () => void
}) => {
  return (
    <>
      {open && (
        <Overlay>
          <Modal data-testid="confirmationDialog">
            <p>{title}</p>
            <ButtonWrapper>
              <Button
                type="button"
                text="No"
                mainAction={false}
                onClick={() => setOpen(false)}
                loading={false}
              />
              <Button
                type="button"
                text="Yes"
                mainAction={false}
                onClick={() => {
                  setOpen(false)
                  onConfirm()
                }}
                loading={false}
              />
            </ButtonWrapper>
            {children}
          </Modal>
        </Overlay>
      )}
    </>
  )
}

export default ConfirmationDialog
