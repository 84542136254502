import styled from "styled-components"

export const AssessmentCard = styled.div`
  background: ${({ theme }) => theme.typographyInverse};
  border: ${({ theme }) => `1px solid ${theme.unfocused}`};
  margin: 20px auto;
  padding: 30px 50px;

  > h3 {
    font-size: ${({ theme }) => theme.headingFontSize};
    margin: 8px 0px;
  }
`

export const StatusSection = styled.div`
  display: flex;
  flex-direction: row;

  > ul {
    width: 50%;
  }
  padding-bottom: 10px;
`

export const ContentSection = styled.div`
  padding: 16px 0px;
  border-top: ${({ theme }) => `1px solid ${theme.unfocused}`};
  font-size: ${({ theme }) => theme.fontSize};

  > h4 {
    font-size: ${({ theme }) => theme.headingFontSize};
    color: ${({ theme }) => `${theme.disabled}`};
    margin: 4px 0px;
  }

  p {
    line-height: 16px;
    margin: 8px 0px;
    font-family: ${({ theme }) => theme.font};
    width: 100%;
  }
`
