import React, { FC } from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
`

const NotFound: FC = () => {
  return (
    <Container>
      <h1>Error 404</h1>
      <p>Page Not Found</p>
    </Container>
  )
}

export default NotFound
