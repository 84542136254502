import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { gql, useQuery } from "@apollo/client"

import {
  agreements,
  agreements_node_Account_personOwners,
  agreements_node_Account_personOwners_agreements,
} from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import Agreement from "./agreement"

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 30px auto;
`

export const AGREEMENTS_QUERY = gql`
  query agreements($id: String!) {
    node(id: $id) {
      ... on Account {
        id
        personOwners {
          hasUnsignedAgreements
          agreements {
            name
            signed
            signature {
              ipAddress
              userAgent
              createdAt
            }
          }
        }
      }
    }
  }
`

const Agreements = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading, error } = useQuery<agreements>(AGREEMENTS_QUERY, {
    variables: { id },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>An Error Occurred. Please reload the page.</div>
  }

  if (!data || data.node.__typename !== "Account") {
    return <div>Account not found</div>
  }

  const { personOwners } = data.node

  const concatenatedAgreements = personOwners.reduce(
    (
      acc: Array<agreements_node_Account_personOwners_agreements>,
      item: agreements_node_Account_personOwners
    ) => {
      return [...acc, ...item.agreements]
    },
    []
  )

  return (
    <Wrapper data-testid="agreements">
      {concatenatedAgreements && concatenatedAgreements.length > 0 ? (
        concatenatedAgreements.map((agreement) => (
          <Agreement
            key={`${agreement.name}-${
              agreement?.signature?.createdAt || "unsigned"
            }`}
            type={agreement.name}
            date={agreement?.signature?.createdAt}
            device={agreement?.signature?.userAgent}
            ip={agreement?.signature?.ipAddress}
            signed={agreement?.signed}
          />
        ))
      ) : (
        <div>No agreements found.</div>
      )}
    </Wrapper>
  )
}

export default Agreements
