import { refreshToken } from "../restClient"
import { DecodedDeviceToken, DecodedUserToken, decodeToken } from "./tokenUtils"

export type AuthStatus = "Anonymous" | "UntrustedDevice" | "Authenticated"

const AdminRoles = ["SuperAdmin", "Admin", "ReadOnlyAdmin"]

let refreshTokenInterval: number | null = null

let subscriptions: ((newStatus: AuthStatus) => void)[] = []

/**
 * Returns the serialised userToken as string or null if it is not set.
 *
 * Please notice that because the token might be expired or invalid, this need to use getCurrentStatus to ensure it is valid.
 */
export function getUserToken(): string | null {
  return localStorage.getItem("userToken")
}

/**
 * Returns the serialised deviceToken as string or null if it is not set.
 *
 * Please notice that because the token might be expired or invalid, this need to use getCurrentStatus to ensure it is valid.
 */
export function getDeviceToken(): string | null {
  return localStorage.getItem("deviceToken")
}

function calculateAuthStatus(): AuthStatus {
  let status: AuthStatus = "Anonymous"

  const decodedUserToken = decodeToken(getUserToken()) as DecodedUserToken
  const decodedDeviceToken = decodeToken(getDeviceToken()) as DecodedDeviceToken

  if (!decodedUserToken) {
    status = "Anonymous"
  } else if (decodedUserToken.exp * 1000 < Date.now()) {
    status = "Anonymous"
  } else if (!decodedUserToken.mfa || decodedUserToken.mfa === "SMS") {
    status = "Anonymous"
  } else if (!decodedDeviceToken) {
    status = "UntrustedDevice"
  } else if (decodedDeviceToken.exp < Date.now() / 1000) {
    status = "UntrustedDevice"
  } else if (decodedDeviceToken.sub !== decodedUserToken.sub) {
    status = "UntrustedDevice"
  } else if (
    decodedDeviceToken.sid &&
    decodedDeviceToken.sid !== decodedUserToken.sid
  ) {
    status = "UntrustedDevice"
  } else if (!AdminRoles.includes(decodedUserToken.role)) {
    status = "Anonymous"
  } else {
    status = "Authenticated"
  }

  return status
}

function notifySubscriptors(newStatus: AuthStatus) {
  subscriptions.forEach((cb) => cb(newStatus))
}

/**
 * Returns current auth status.
 */
export function getCurrentStatus() {
  return calculateAuthStatus()
}

/**
 * Allows a component to listen to status changes.
 *
 * @param callback (newStatus: AuthStatus):void => {}
 */
export function subscribeToStatus(
  callback: (newStatus: AuthStatus) => void
): void {
  subscriptions = [...subscriptions, callback]
}

/**
 * Clean up existing subscription.
 *
 * @param callback (newStatus: AuthStatus):void => {}
 */
export function unsubscribeToStatus(
  callback: (newStatus: AuthStatus) => void
): void {
  subscriptions = subscriptions.filter((cb) => cb !== callback)
}

async function ensureTokenIsFresh() {
  const decodedUserToken = decodeToken(getUserToken()) as DecodedUserToken
  if (!decodedUserToken) {
    return
  }

  if (decodedUserToken.exp * 1000 < Date.now()) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    clearUserToken()
  } else if (decodedUserToken.exp * 1000 - 60000 < Date.now()) {
    const newUserToken = await refreshToken()
    if (newUserToken) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setUserToken(newUserToken)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      clearUserToken()
    }
  }
}

/**
 * Allows to set new user token after successful login.
 */
export function setUserToken(token: string): void {
  localStorage.setItem("userToken", token)
  const newStatus = calculateAuthStatus()
  notifySubscriptors(newStatus)
}

/**
 * Allows to set new user token after successful login.
 */
export function clearUserToken(): void {
  localStorage.removeItem("userToken")
  const newStatus = calculateAuthStatus()
  notifySubscriptors(newStatus)
}

/**
 * Allows to set new user token and device token after successful login.
 *
 * Please notice that, after request a device token, the API will return a new userToken
 * regardless the old one is still valid. You need to provide to this method the new one.
 */
export function setDeviceToken(userToken: string, deviceToken: string): void {
  localStorage.setItem("userToken", userToken)
  localStorage.setItem("deviceToken", deviceToken)
  const newStatus = calculateAuthStatus()
  notifySubscriptors(newStatus)
}

/**
 * Initialise the refresh token time out.
 */
export async function initAuth() {
  // Ensure current status is updated
  await ensureTokenIsFresh()

  if (refreshTokenInterval) {
    clearInterval(refreshTokenInterval)
  }

  refreshTokenInterval = setInterval(async () => {
    ensureTokenIsFresh()
  }, 2000)
}
