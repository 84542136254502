import { useQuery } from "@apollo/client"
import React, { FC, useState } from "react"
import styled from "styled-components"
import {
  getMarketingInsight_getMarketingInsight,
  getMarketingInsight,
} from "../../@types/graphql"
import Table, { Row } from "../../components/Table"
import Button from "../../components/Button"
import { Paragraph, Wrapper } from "../Dashboard/styles"
import { FETCH_MARKETING_INSIGHTS } from "./query"
import AddNewInsightModal from "./AddNewInsight"
import strings from "../../components/localization/strings"
import DeleteMarketModal from "./DeleteInsight"
import UpdateInsightModal from "./UpdateInsight"

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonView = styled.div`
  padding-left: 1em;
  width: 20%;
`
const MarketInsights: FC = () => {
  const headers = ["Title", "Subtitle", "Description", "", "Action"]
  const { data, refetch } = useQuery<
    getMarketingInsight,
    getMarketingInsight_getMarketingInsight
  >(FETCH_MARKETING_INSIGHTS)

  const marketData = data?.getMarketingInsight
  const [showAddMarketInsight, setShowAddMarketInsight] = useState<boolean>(
    false
  )
  const [showDeleteMarketInsight, setShowDeleteMarketInsight] = useState<
    boolean
  >(false)
  const [showUpdateMarketInsight, setShowUpdateMarketInsight] = useState<
    boolean
  >(false)
  const [id, setId] = useState<string>(" ")
  const [titleValue, setTitleValue] = useState<string>(" ")
  const [subtitleValue, setSubtitleValue] = useState<string>(" ")
  const [descriptionValue, setDescriptionValue] = useState<string>(" ")
  const [, setReload] = useState(false)

  const insightRows = marketData?.map(
    ({ id: marketId, title, subtitle, description }) => {
      const updateButton = (
        <Button
          type="button"
          text="Update"
          mainAction={false}
          onClick={async () => {
            setId(marketId)
            if (title !== null) setTitleValue(title)
            if (subtitle !== null) setSubtitleValue(subtitle)
            if (description !== null) setDescriptionValue(description)
            setShowUpdateMarketInsight(true)
          }}
          loading={false}
        />
      )

      const deleteButton = (
        <Button
          type="button"
          text="Delete"
          mainAction={false}
          onClick={async () => {
            setId(marketId)
            setShowDeleteMarketInsight(true)
          }}
          loading={false}
        />
      )

      const updateInsightButton = () => {
        return updateButton
      }

      const deleteInsightButton = () => {
        return deleteButton
      }

      const insightValues = [
        title,
        subtitle,
        description,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateInsightButton() as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        deleteInsightButton() as any,
      ]
      return <Row key={marketId} values={insightValues} />
    }
  )
  return (
    <>
      {showAddMarketInsight && (
        <AddNewInsightModal
          onClose={() => {
            setShowAddMarketInsight(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
        />
      )}
      {showUpdateMarketInsight && (
        <UpdateInsightModal
          onClose={() => {
            setShowUpdateMarketInsight(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          marketId={id}
          title={titleValue}
          subtitle={subtitleValue}
          description={descriptionValue}
        />
      )}
      {showDeleteMarketInsight && (
        <DeleteMarketModal
          onClose={() => {
            setShowDeleteMarketInsight(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          marketId={id}
        />
      )}
      <Wrapper>
        <TopArea>
          <Paragraph>{strings.MARKET_INSIGHT}</Paragraph>
          <ButtonView>
            <Button
              type="button"
              text={strings.ADD_INSIGHT}
              mainAction={false}
              onClick={() => setShowAddMarketInsight(true)}
              loading={false}
            />
          </ButtonView>
        </TopArea>
        <TopArea>
          <Table columnNames={headers} isEmpty={insightRows?.length === 0}>
            {insightRows}
          </Table>
        </TopArea>
      </Wrapper>
    </>
  )
}

export default MarketInsights
