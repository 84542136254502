import { gql, useMutation } from "@apollo/client"
import React, { ReactElement, useState } from "react"
import { verifyMfaSecret, verifyMfaSecretVariables } from "../../@types/graphql"

import Button from "../../components/Button"
import InputField from "../../components/InputField"
import useInputState, { matchRegex, notEmpty } from "../../hooks/useInputState"
import { ButtonView, ErrorMessage, Paragraph, TitleText } from "./styles"

export const VERIFY_MFA_SECRET_MUTATION = gql`
  mutation verifyMfaSecret($input: VerifyMfaSecretInput!) {
    verifyMfaSecret(input: $input) {
      person {
        id
      }
    }
  }
`

const EnterCode = ({ onNext }: { onNext: () => void }): ReactElement => {
  const [errorMessage, setErrorMessage] = useState("")

  const inputStateMfaToken = useInputState("", [
    notEmpty("Code is required"),
    matchRegex("Invalid code", /^\s*\d{6}\s*$/),
  ])

  const [verifyMfaSecretMutation, { loading: verifyMfaLoading }] = useMutation<
    verifyMfaSecret,
    verifyMfaSecretVariables
  >(VERIFY_MFA_SECRET_MUTATION)

  const handleVerifyMfa = async (): Promise<void> => {
    await verifyMfaSecretMutation({
      variables: {
        input: {
          mfaToken: inputStateMfaToken.value.trim(),
        },
      },
    })
      .then(() => {
        setErrorMessage("")
        onNext()
      })
      .catch((mutationError) => {
        if (mutationError.graphQLErrors[0].message === "Invalid MFA Token") {
          setErrorMessage("The code is invalid")
        } else {
          setErrorMessage("Something went wrong")
        }
      })
  }

  return (
    <>
      <TitleText>Enter your authentication code</TitleText>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Paragraph>
        Your authenticator app should be displaying an authenticator code for
        your account. Enter the code below to finish setting up two factor
        authentication.
      </Paragraph>
      <InputField
        type="text"
        fieldName="MFA token"
        labelText="Authentication code"
        placeholder="Your 6-digit code"
        value={inputStateMfaToken.value}
        onChange={inputStateMfaToken.onChange}
        onBlur={inputStateMfaToken.onBlur}
        errorMessage={inputStateMfaToken.errorMessage}
      />
      <ButtonView>
        <Button
          type="button"
          text="Next"
          onClick={handleVerifyMfa}
          loading={verifyMfaLoading}
          disabled={!inputStateMfaToken.hasValidValue}
        />
      </ButtonView>
    </>
  )
}

export default EnterCode
