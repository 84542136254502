import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import { createMarketInsights } from "../../../@types/graphql"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import { CREATE_MARKET_INSIGHT } from "../query"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmNewInsight = ({
  title,
  subtitle,
  description,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  title: string
  subtitle: string
  description: string
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [
    createMarketInsightMutation,
    { loading: creatingMarketInsight },
  ] = useMutation<createMarketInsights>(CREATE_MARKET_INSIGHT)

  const handleSubmit = async (): Promise<void> => {
    const titleValue = title.trim()
    const subtitleValue = subtitle.trim()
    const descriptionValue = description.trim()
    const {
      data: creatingMarketInsightData,
    } = await createMarketInsightMutation({
      variables: {
        title: titleValue,
        subtitle: subtitleValue,
        description: descriptionValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })

    if (!creatingMarketInsightData) {
      return
    }

    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_INSIGHT}</Title>
      <Paragraph style={{ marginTop: 0 }}>
        {strings.CONFIRM_TITLE}
        <br />
        {title}
      </Paragraph>
      <Paragraph>
        {strings.CONFRIM_SUBTITLE}
        <br />
        {subtitle}
      </Paragraph>
      <Paragraph>
        {strings.CONFIRM_DESCRIPTION}
        <br />
        {description}
      </Paragraph>
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={creatingMarketInsight}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={creatingMarketInsight}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmNewInsight
