/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyMfaSecret
// ====================================================

export interface verifyMfaSecret_verifyMfaSecret_person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface verifyMfaSecret_verifyMfaSecret {
  __typename: "VerifyMfaSecretOutput";
  /**
   * The updated person
   */
  person: verifyMfaSecret_verifyMfaSecret_person;
}

export interface verifyMfaSecret {
  /**
   * Called after the first MFA token is generated in an app, to set an MFA secret on a person
   */
  verifyMfaSecret: verifyMfaSecret_verifyMfaSecret;
}

export interface verifyMfaSecretVariables {
  input: VerifyMfaSecretInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signInOnboarding
// ====================================================

export interface signInOnboarding_signInWithEmailAddress {
  __typename: "SignInOutput";
  /**
   * The user token of this response
   */
  userToken: string;
}

export interface signInOnboarding {
  /**
   * Authenticates a person using email address and password, and returns a user token if successful
   */
  signInWithEmailAddress: signInOnboarding_signInWithEmailAddress;
}

export interface signInOnboardingVariables {
  emailAddress: string;
  password: string;
  mfaToken?: string | null;
  multiSession?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword_resetPassword {
  __typename: "SuccessOutput";
  /**
   * Whether this operation was successful
   */
  success: boolean;
}

export interface resetPassword {
  /**
   * Resets the user's password to the provided value using the provided code.
   */
  resetPassword: resetPassword_resetPassword;
}

export interface resetPasswordVariables {
  input: ResetPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setupMfaGenerateMfaSecret
// ====================================================

export interface setupMfaGenerateMfaSecret_generateMfaSecret {
  __typename: "GenerateMfaSecretOutput";
  /**
   * The OTP auth URL to use with an authenticator app
   */
  otpauthUrl: string | null;
  /**
   * The QR code image data URL to use with an authenticator app
   */
  qrDataUrl: string | null;
}

export interface setupMfaGenerateMfaSecret {
  /**
   * Generates a new MFA secret for the current person, and stores it as the proposed value; sends an SMS message if method is SMS, and returns OTP auth URL and QR image URL
   */
  generateMfaSecret: setupMfaGenerateMfaSecret_generateMfaSecret;
}

export interface setupMfaGenerateMfaSecretVariables {
  input: GenerateMfaSecretInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountDetails
// ====================================================

export interface AccountDetails_node_Document {
  __typename: "Document" | "RefreshToken" | "Person" | "AdjustmentStatus" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface AccountDetails_node_Account_portfolio_securities_items_security {
  __typename: "Security";
  /**
   * The symbol of this security
   */
  symbol: string | null;
}

export interface AccountDetails_node_Account_portfolio_securities_items {
  __typename: "PortfolioSecurity";
  security: AccountDetails_node_Account_portfolio_securities_items_security;
}

export interface AccountDetails_node_Account_portfolio_securities {
  __typename: "PageOfPortfolioSecurities";
  items: AccountDetails_node_Account_portfolio_securities_items[];
}

export interface AccountDetails_node_Account_portfolio {
  __typename: "Portfolio";
  /**
   * The name of this portfolio
   */
  name: string;
  /**
   * The securities in this portfolio
   */
  securities: AccountDetails_node_Account_portfolio_securities;
}

export interface AccountDetails_node_Account_backOfficeAccount {
  __typename: "BackOfficeAccount";
  /**
   * The ID of this back-office account in the back-office
   */
  backOfficeId: string;
}

export interface AccountDetails_node_Account_withdrawals_items_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface AccountDetails_node_Account_withdrawals_items {
  __typename: "Withdrawal";
  /**
   * The value of the last status of this withdrawal
   */
  lastStatus: WithdrawalStatusValue;
  /**
   * The amount that was removed from the account balance balance, inclusive of taxes, as a positive number
   */
  value: AccountDetails_node_Account_withdrawals_items_value;
}

export interface AccountDetails_node_Account_withdrawals {
  __typename: "PageOfWithdrawals";
  items: AccountDetails_node_Account_withdrawals_items[];
}

export interface AccountDetails_node_Account_positions_items {
  __typename: "Position";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The type of this position
   */
  type: PositionType;
  /**
   * The value of this position at a moment in time
   */
  currentValue: WsDecimal | null;
}

export interface AccountDetails_node_Account_positions {
  __typename: "PageOfPositions";
  items: AccountDetails_node_Account_positions_items[];
}

export interface AccountDetails_node_Account_personOwners_person_name {
  __typename: "UnverifiedPersonName";
  /**
   * The first name of this name
   */
  first: string | null;
  /**
   * The last name of this name
   */
  last: string | null;
}

export interface AccountDetails_node_Account_personOwners_person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The admin tags on this person
   */
  adminTags: string[];
  /**
   * The token of this person while signup
   */
  token: string | null;
  /**
   * The name of this person
   */
  name: AccountDetails_node_Account_personOwners_person_name;
  /**
   * The Sustainability Preference of the person
   */
  sustainabilityPreference: string | null;
}

export interface AccountDetails_node_Account_personOwners {
  __typename: "PersonAccountOwner";
  /**
   * The person of this ownership
   */
  person: AccountDetails_node_Account_personOwners_person | null;
}

export interface AccountDetails_node_Account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The status of this account
   */
  status: AccountStatus;
  /**
   * The type of this account
   */
  type: AccountType;
  /**
   * The ID of this account in an external system
   */
  externalId: string | null;
  /**
   * The admin tags on this account
   */
  adminTags: string[];
  /**
   * The portfolio of this account
   */
  portfolio: AccountDetails_node_Account_portfolio | null;
  /**
   * The back-office account of this account
   */
  backOfficeAccount: AccountDetails_node_Account_backOfficeAccount | null;
  /**
   * The value of this position at a moment in time
   */
  value: WsDecimal;
  /**
   * The withdrawals made from this account
   */
  withdrawals: AccountDetails_node_Account_withdrawals;
  /**
   * The positions on this account
   */
  positions: AccountDetails_node_Account_positions;
  /**
   * The person owners of this account
   */
  personOwners: AccountDetails_node_Account_personOwners[];
}

export type AccountDetails_node = AccountDetails_node_Document | AccountDetails_node_Account;

export interface AccountDetails {
  /**
   * Returns a node by ID
   */
  node: AccountDetails_node;
}

export interface AccountDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: agreements
// ====================================================

export interface agreements_node_Document {
  __typename: "Document" | "RefreshToken" | "Person" | "AdjustmentStatus" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
}

export interface agreements_node_Account_personOwners_agreements_signature {
  __typename: "AgreementSignature";
  /**
   * The IP address from which this signature was collected
   */
  ipAddress: string;
  /**
   * The user agent used to create this signature
   */
  userAgent: string;
  /**
   * The date and time at which this signature was created
   */
  createdAt: WsDateTime;
}

export interface agreements_node_Account_personOwners_agreements {
  __typename: "Agreement";
  /**
   * The name of this agreement
   */
  name: string;
  /**
   * Whether this agreement has been signed
   */
  signed: boolean;
  /**
   * The signature on this agreement, or null if unsigned
   */
  signature: agreements_node_Account_personOwners_agreements_signature | null;
}

export interface agreements_node_Account_personOwners {
  __typename: "PersonAccountOwner";
  /**
   * Whether the owner has any unsigned agreements
   */
  hasUnsignedAgreements: boolean;
  /**
   * The agreements of this owner
   */
  agreements: agreements_node_Account_personOwners_agreements[];
}

export interface agreements_node_Account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The person owners of this account
   */
  personOwners: agreements_node_Account_personOwners[];
}

export type agreements_node = agreements_node_Document | agreements_node_Account;

export interface agreements {
  /**
   * Returns a node by ID
   */
  node: agreements_node;
}

export interface agreementsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: accountPage
// ====================================================

export interface accountPage_node {
  __typename: "Document" | "RefreshToken" | "Person" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface accountPage {
  /**
   * Returns a node by ID
   */
  node: accountPage_node;
}

export interface accountPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: suitability
// ====================================================

export interface suitability_node_Document {
  __typename: "Document" | "RefreshToken" | "Person" | "AdjustmentStatus" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface suitability_node_Account_portfolioAdvice_items_portfolio {
  __typename: "Portfolio";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The name of this portfolio
   */
  name: string;
}

export interface suitability_node_Account_portfolioAdvice_items {
  __typename: "PortfolioAdvice";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The inputs to this advice, as a JSON string
   */
  inputs: string;
  /**
   * The warnings of the advice.
   */
  warnings: string[];
  /**
   * The date and time at which this advice was accepted, if accepted
   */
  acceptedAt: WsDateTime | null;
  /**
   * The date and time at which this advice was last updated
   */
  lastUpdatedAt: WsDateTime;
  /**
   * The portfolio of this advice
   */
  portfolio: suitability_node_Account_portfolioAdvice_items_portfolio;
}

export interface suitability_node_Account_portfolioAdvice {
  __typename: "PageOfPortfolioAdvice";
  items: suitability_node_Account_portfolioAdvice_items[];
}

export interface suitability_node_Account_surveyResponses {
  __typename: "SurveyResponse";
  /**
   * The ID of the question that was asked
   */
  questionId: string;
  /**
   * The text of the question that was asked
   */
  questionText: string;
  /**
   * The user's response to the question
   */
  response: string;
}

export interface suitability_node_Account_personOwners_person_financialStatus_monthlyIncome {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface suitability_node_Account_personOwners_person_financialStatus_monthlyExpenses {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface suitability_node_Account_personOwners_person_financialStatus_liquidAssets {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface suitability_node_Account_personOwners_person_financialStatus_debt {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface suitability_node_Account_personOwners_person_financialStatus {
  __typename: "FinancialStatus";
  /**
   * The monthly income of this person
   */
  monthlyIncome: suitability_node_Account_personOwners_person_financialStatus_monthlyIncome | null;
  /**
   * The monthly expenses of this person
   */
  monthlyExpenses: suitability_node_Account_personOwners_person_financialStatus_monthlyExpenses | null;
  /**
   * The value of the liquid assets of this person
   */
  liquidAssets: suitability_node_Account_personOwners_person_financialStatus_liquidAssets | null;
  /**
   * The value of the debt of this person
   */
  debt: suitability_node_Account_personOwners_person_financialStatus_debt | null;
}

export interface suitability_node_Account_personOwners_person {
  __typename: "Person";
  /**
   * The admin tags on this person
   */
  adminTags: string[];
  /**
   * The financial status of this person
   */
  financialStatus: suitability_node_Account_personOwners_person_financialStatus;
}

export interface suitability_node_Account_personOwners {
  __typename: "PersonAccountOwner";
  /**
   * The person of this ownership
   */
  person: suitability_node_Account_personOwners_person | null;
}

export interface suitability_node_Account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The date and time at which this account was last updated
   */
  lastUpdatedAt: WsDateTime;
  /**
   * A page of portfolio advice for this account
   */
  portfolioAdvice: suitability_node_Account_portfolioAdvice;
  /**
   * The survey responses of this account
   */
  surveyResponses: suitability_node_Account_surveyResponses[];
  /**
   * The person owners of this account
   */
  personOwners: suitability_node_Account_personOwners[];
}

export type suitability_node = suitability_node_Document | suitability_node_Account;

export interface suitability {
  /**
   * Returns a node by ID
   */
  node: suitability_node;
}

export interface suitabilityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: transactions
// ====================================================

export interface transactions_node_Document {
  __typename: "Document" | "RefreshToken" | "Person" | "AdjustmentStatus" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
}

export interface transactions_node_Account_transactions_items_AdjustmentTransaction {
  __typename: "AdjustmentTransaction";
  /**
   * The ID of the status that is referenced by this transaction
   */
  statusId: string;
}

export interface transactions_node_Account_transactions_items_DepositTransaction_depositStatus_deposit_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
  /**
   * The currency of this money value
   */
  currency: string;
}

export interface transactions_node_Account_transactions_items_DepositTransaction_depositStatus_deposit {
  __typename: "Deposit";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The amount that was deposited
   */
  value: transactions_node_Account_transactions_items_DepositTransaction_depositStatus_deposit_value;
  /**
   * The optional source of funds of this deposit
   */
  sourceOfFunds: string | null;
  /**
   * The ID of this deposit in an external system
   */
  externalId: string | null;
}

export interface transactions_node_Account_transactions_items_DepositTransaction_depositStatus {
  __typename: "DepositStatus";
  /**
   * The status of this deposit
   */
  value: DepositStatusValue;
  /**
   * The date and time at which this status started
   */
  startedAt: WsDateTime;
  /**
   * The deposit that this status is attached to
   */
  deposit: transactions_node_Account_transactions_items_DepositTransaction_depositStatus_deposit;
}

export interface transactions_node_Account_transactions_items_DepositTransaction {
  __typename: "DepositTransaction";
  /**
   * The ID of the status that is referenced by this transaction
   */
  statusId: string;
  /**
   * The deposit status of this transaction
   */
  depositStatus: transactions_node_Account_transactions_items_DepositTransaction_depositStatus;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
  /**
   * The currency of this money value
   */
  currency: string;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_taxes_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
  /**
   * The currency of this money value
   */
  currency: string;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_taxes {
  __typename: "Tax";
  /**
   * The amount of this tax
   */
  value: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_taxes_value;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_bankAccount {
  __typename: "BankAccount";
  /**
   * The account number of this bank account
   */
  accountNumber: string;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal {
  __typename: "Withdrawal";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The amount that was removed from the account balance balance, inclusive of taxes, as a positive number
   */
  value: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_value;
  /**
   * The taxes that were withheld on this withdrawal, as a positive number
   */
  taxes: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_taxes[];
  /**
   * The ID of this bank withdrawal in an external system
   */
  externalId: string | null;
  /**
   * The bank account of this withdrawal
   */
  bankAccount: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal_bankAccount | null;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus {
  __typename: "WithdrawalStatus";
  /**
   * The value of this status
   */
  value: WithdrawalStatusValue;
  /**
   * The date and time at which this status started
   */
  startedAt: WsDateTime;
  /**
   * The withdrawal that this status is attached to
   */
  withdrawal: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus_withdrawal;
}

export interface transactions_node_Account_transactions_items_WithdrawalTransaction {
  __typename: "WithdrawalTransaction";
  /**
   * The ID of the status that is referenced by this transaction
   */
  statusId: string;
  /**
   * The withdrawal status of this transaction
   */
  withdrawalStatus: transactions_node_Account_transactions_items_WithdrawalTransaction_withdrawalStatus;
}

export interface transactions_node_Account_transactions_items_OrderTransaction_orderStatus_order_security {
  __typename: "Security";
  /**
   * The symbol of this security
   */
  symbol: string | null;
  /**
   * The name of this security
   */
  name: string;
}

export interface transactions_node_Account_transactions_items_OrderTransaction_orderStatus_order {
  __typename: "Order";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The side of this order
   */
  side: OrderSide;
  security: transactions_node_Account_transactions_items_OrderTransaction_orderStatus_order_security;
  /**
   * The quantity of security that was credited or debited
   */
  quantity: WsDecimal;
  /**
   * The value of this order in the currency of the security
   */
  value: WsDecimal;
  /**
   * The per-unit price of this order
   */
  price: WsDecimal;
}

export interface transactions_node_Account_transactions_items_OrderTransaction_orderStatus {
  __typename: "OrderStatus";
  /**
   * The value of this status
   */
  value: OrderStatusValue | null;
  /**
   * The date and time at which this status started
   */
  startedAt: WsDateTime;
  /**
   * The order that this status is attached to
   */
  order: transactions_node_Account_transactions_items_OrderTransaction_orderStatus_order;
}

export interface transactions_node_Account_transactions_items_OrderTransaction {
  __typename: "OrderTransaction";
  /**
   * The ID of the status that is referenced by this transaction
   */
  statusId: string;
  /**
   * The order status of this transaction
   */
  orderStatus: transactions_node_Account_transactions_items_OrderTransaction_orderStatus;
}

export type transactions_node_Account_transactions_items = transactions_node_Account_transactions_items_AdjustmentTransaction | transactions_node_Account_transactions_items_DepositTransaction | transactions_node_Account_transactions_items_WithdrawalTransaction | transactions_node_Account_transactions_items_OrderTransaction;

export interface transactions_node_Account_transactions {
  __typename: "PageOfTransactions";
  items: transactions_node_Account_transactions_items[];
}

export interface transactions_node_Account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * A page of transactions for this account
   */
  transactions: transactions_node_Account_transactions;
}

export type transactions_node = transactions_node_Document | transactions_node_Account;

export interface transactions {
  /**
   * Returns a node by ID
   */
  node: transactions_node;
}

export interface transactionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminsData
// ====================================================

export interface getAdminsData_tenant_people_items_signIns_items {
  __typename: "PersonSignIn";
  /**
   * The date and time at which this sign-in was created
   */
  createdAt: WsDateTime;
  /**
   * The IP address from which this sign-in occurred
   */
  ipAddress: string | null;
  /**
   * The user agent used to sign-in
   */
  userAgent: string | null;
}

export interface getAdminsData_tenant_people_items_signIns {
  __typename: "PageOfPersonSignIns";
  items: getAdminsData_tenant_people_items_signIns_items[];
}

export interface getAdminsData_tenant_people_items {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * The role of this person
   */
  role: TenantRole;
  /**
   * The date and time at which this person was created
   */
  createdAt: WsDateTime;
  /**
   * Whether MFA is enabled for this person
   */
  mfaEnabled: boolean;
  /**
   * The MFA method of this person
   */
  mfaMethod: MfaMethod | null;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
  /**
   * The sign-ins of this person
   */
  signIns: getAdminsData_tenant_people_items_signIns;
}

export interface getAdminsData_tenant_people {
  __typename: "PageOfPeople";
  items: getAdminsData_tenant_people_items[];
}

export interface getAdminsData_tenant {
  __typename: "CurrentTenant";
  /**
   * The people in this tenant
   */
  people: getAdminsData_tenant_people;
}

export interface getAdminsData {
  /**
   * Returns traits of the current tenant when a user is signed in
   */
  tenant: getAdminsData_tenant | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAdminAccount
// ====================================================

export interface createAdminAccount_createPerson {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this person
   */
  version: number;
}

export interface createAdminAccount {
  /**
   * Creates a person
   */
  createPerson: createAdminAccount_createPerson;
}

export interface createAdminAccountVariables {
  email: string;
  accessCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setAdminRole
// ====================================================

export interface setAdminRole_setPersonRole {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this person
   */
  version: number;
}

export interface setAdminRole {
  /**
   * Updates the role on the provided person
   */
  setPersonRole: setAdminRole_setPersonRole;
}

export interface setAdminRoleVariables {
  personId: string;
  version: number;
  role: TenantRole;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchByEmail
// ====================================================

export interface searchByEmail_tenant_people_items {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The role of this person
   */
  role: TenantRole;
}

export interface searchByEmail_tenant_people {
  __typename: "PageOfPeople";
  items: searchByEmail_tenant_people_items[];
}

export interface searchByEmail_tenant {
  __typename: "CurrentTenant";
  /**
   * The people in this tenant
   */
  people: searchByEmail_tenant_people;
}

export interface searchByEmail {
  /**
   * Returns traits of the current tenant when a user is signed in
   */
  tenant: searchByEmail_tenant | null;
}

export interface searchByEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enablePerson
// ====================================================

export interface enablePerson_enablePerson {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
}

export interface enablePerson {
  /**
   * Enables a person
   */
  enablePerson: enablePerson_enablePerson;
}

export interface enablePersonVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disablePerson
// ====================================================

export interface disablePerson_disablePerson {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
}

export interface disablePerson {
  /**
   * Disables a person
   */
  disablePerson: disablePerson_disablePerson;
}

export interface disablePersonVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendAdminInvitation
// ====================================================

export interface sendAdminInvitation_sendAdminInvitationToPerson {
  __typename: "SuccessOutput";
  /**
   * Whether this operation was successful
   */
  success: boolean;
}

export interface sendAdminInvitation {
  /**
   * Sends an admin invitation email to the provided person 
   */
  sendAdminInvitationToPerson: sendAdminInvitation_sendAdminInvitationToPerson;
}

export interface sendAdminInvitationVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonName
// ====================================================

export interface getPersonName_person_name {
  __typename: "UnverifiedPersonName";
  /**
   * The first name of this name
   */
  first: string | null;
}

export interface getPersonName_person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The name of this person
   */
  name: getPersonName_person_name;
}

export interface getPersonName {
  /**
   * Returns the signed in person if a person is signed in
   */
  person: getPersonName_person | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFundFact
// ====================================================

export interface createFundFact_createFundFact {
  __typename: "FundFactsheet";
  /**
   * Client Fund Value for the Factsheet
   */
  client_fund: string | null;
  /**
   * Asset Class Value for the Factsheet
   */
  asset_class: string;
  /**
   * Subcategory Value for the Factsheet
   */
  subcategory: string;
  /**
   * Percentage Value for the Factsheet
   */
  percentage: number;
  /**
   * Is it the Main Category for the Factsheet
   */
  main_category: boolean;
}

export interface createFundFact {
  /**
   * Inserts fact sheet details in fund_factsheets table
   */
  createFundFact: createFundFact_createFundFact;
}

export interface createFundFactVariables {
  client_fund: string;
  asset_class: string;
  subcategory: string;
  percentage: number;
  main_category: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCountFundFactsheets
// ====================================================

export interface getCountFundFactsheets_getCountFundFactsheets {
  __typename: "FundFactsheet";
  /**
   * rowCount Value for the Factsheet
   */
  rowCount: number;
}

export interface getCountFundFactsheets {
  /**
   * Checks unique fact sheet values in fund_factsheets table
   */
  getCountFundFactsheets: getCountFundFactsheets_getCountFundFactsheets;
}

export interface getCountFundFactsheetsVariables {
  client_fund: string;
  asset_class: string;
  subcategory: string;
  main_category: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFundFactSheet
// ====================================================

export interface getFundFactSheet_getFundFactSheet {
  __typename: "FundFactsheet";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Client Fund Value for the Factsheet
   */
  client_fund: string | null;
  /**
   * Asset Class Value for the Factsheet
   */
  asset_class: string;
  /**
   * Subcategory Value for the Factsheet
   */
  subcategory: string;
  /**
   * Percentage Value for the Factsheet
   */
  percentage: number;
}

export interface getFundFactSheet {
  /**
   * Get Fund Factsheet data
   */
  getFundFactSheet: getFundFactSheet_getFundFactSheet[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFundFactSheet
// ====================================================

export interface updateFundFactSheet_updateFundFactSheet {
  __typename: "FundFactsheet";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Client Fund Value for the Factsheet
   */
  client_fund: string | null;
  /**
   * Asset Class Value for the Factsheet
   */
  asset_class: string;
  /**
   * Subcategory Value for the Factsheet
   */
  subcategory: string;
  /**
   * Percentage Value for the Factsheet
   */
  percentage: number;
  /**
   * Is it the Main Category for the Factsheet
   */
  main_category: boolean;
}

export interface updateFundFactSheet {
  /**
   * Update Fund Factsheet Row
   */
  updateFundFactSheet: updateFundFactSheet_updateFundFactSheet;
}

export interface updateFundFactSheetVariables {
  id: string;
  subcategory: string;
  percentage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFundFact
// ====================================================

export interface deleteFundFact_deleteFundFact {
  __typename: "FundFactsheet";
  /**
   * Client Fund Value for the Factsheet
   */
  client_fund: string | null;
}

export interface deleteFundFact {
  /**
   * Delete Fund Factsheet Row
   */
  deleteFundFact: deleteFundFact_deleteFundFact;
}

export interface deleteFundFactVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDistinctClientFundValues
// ====================================================

export interface getDistinctClientFundValues_getDistinctClientFundValues {
  __typename: "FundFactsheet";
  /**
   * Client Fund Value for the Factsheet
   */
  client_fund: string | null;
}

export interface getDistinctClientFundValues {
  /**
   * Get Distinct Client Fund Values
   */
  getDistinctClientFundValues: getDistinctClientFundValues_getDistinctClientFundValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDistinctAssetClassValues
// ====================================================

export interface getDistinctAssetClassValues_getDistinctAssetClassValues {
  __typename: "FundFactsheet";
  /**
   * Asset Class Value for the Factsheet
   */
  asset_class: string;
}

export interface getDistinctAssetClassValues {
  /**
   * Get Distinct Asset Class Values
   */
  getDistinctAssetClassValues: getDistinctAssetClassValues_getDistinctAssetClassValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDistinctSubcategoryValues
// ====================================================

export interface getDistinctSubcategoryValues_getDistinctSubcategoryValues {
  __typename: "FundFactsheet";
  /**
   * Subcategory Value for the Factsheet
   */
  subcategory: string;
}

export interface getDistinctSubcategoryValues {
  /**
   * Get Distinct Subcategory Values
   */
  getDistinctSubcategoryValues: getDistinctSubcategoryValues_getDistinctSubcategoryValues[];
}

export interface getDistinctSubcategoryValuesVariables {
  asset_class: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signInWithMFA
// ====================================================

export interface signInWithMFA_signInWithEmailAddress {
  __typename: "SignInOutput";
  /**
   * The user token of this response
   */
  userToken: string;
  /**
   * The device token of this response - null unless person has MFA enabled and MFA token was provided
   */
  deviceToken: string | null;
}

export interface signInWithMFA {
  /**
   * Authenticates a person using email address and password, and returns a user token if successful
   */
  signInWithEmailAddress: signInWithMFA_signInWithEmailAddress;
}

export interface signInWithMFAVariables {
  email: string;
  password: string;
  mfaToken?: string | null;
  multiSession?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signIn
// ====================================================

export interface signIn_signInWithEmailAddress {
  __typename: "SignInOutput";
  /**
   * The user token of this response
   */
  userToken: string;
}

export interface signIn {
  /**
   * Authenticates a person using email address and password, and returns a user token if successful
   */
  signInWithEmailAddress: signIn_signInWithEmailAddress;
}

export interface signInVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMarketInsights
// ====================================================

export interface createMarketInsights_createMarketInsights {
  __typename: "MarketInsight";
  /**
   * Title of the market insight
   */
  title: string | null;
  /**
   * Subtitle of the market insight
   */
  subtitle: string | null;
  /**
   * description of the market insight
   */
  description: string;
}

export interface createMarketInsights {
  /**
   * Inserts marketing insight details in marketing_insight table
   */
  createMarketInsights: createMarketInsights_createMarketInsights;
}

export interface createMarketInsightsVariables {
  title: string;
  subtitle: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMarketingInsight
// ====================================================

export interface getMarketingInsight_getMarketingInsight {
  __typename: "MarketInsight";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Title of the market insight
   */
  title: string | null;
  /**
   * Subtitle of the market insight
   */
  subtitle: string | null;
  /**
   * description of the market insight
   */
  description: string;
}

export interface getMarketingInsight {
  /**
   * Get marketing insight data
   */
  getMarketingInsight: getMarketingInsight_getMarketingInsight[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMarketingInsight
// ====================================================

export interface updateMarketingInsight_updateMarketingInsight {
  __typename: "MarketInsight";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Title of the market insight
   */
  title: string | null;
  /**
   * Subtitle of the market insight
   */
  subtitle: string | null;
  /**
   * description of the market insight
   */
  description: string;
}

export interface updateMarketingInsight {
  /**
   * Update Marketing insights
   */
  updateMarketingInsight: updateMarketingInsight_updateMarketingInsight;
}

export interface updateMarketingInsightVariables {
  id: string;
  title: string;
  subtitle: string;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMarketingInsight
// ====================================================

export interface deleteMarketingInsight_deleteMarketingInsight {
  __typename: "MarketInsight";
  /**
   * Title of the market insight
   */
  title: string | null;
}

export interface deleteMarketingInsight {
  /**
   * Delete Marketing insights
   */
  deleteMarketingInsight: deleteMarketingInsight_deleteMarketingInsight;
}

export interface deleteMarketingInsightVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: accounts
// ====================================================

export interface accounts_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface accounts_node_Person_accounts_items_account_backOfficeAccount {
  __typename: "BackOfficeAccount";
  /**
   * The ID of this back-office account in the back-office
   */
  backOfficeId: string;
}

export interface accounts_node_Person_accounts_items_account_portfolio {
  __typename: "Portfolio";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The name of this portfolio
   */
  name: string;
}

export interface accounts_node_Person_accounts_items_account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The ID of this account in an external system
   */
  externalId: string | null;
  /**
   * The type of this account
   */
  type: AccountType;
  /**
   * The status of this account
   */
  status: AccountStatus;
  /**
   * The back-office account of this account
   */
  backOfficeAccount: accounts_node_Person_accounts_items_account_backOfficeAccount | null;
  /**
   * The value of this position at a moment in time
   */
  value: WsDecimal;
  /**
   * The portfolio of this account
   */
  portfolio: accounts_node_Person_accounts_items_account_portfolio | null;
}

export interface accounts_node_Person_accounts_items {
  __typename: "PersonAccountOwner";
  account: accounts_node_Person_accounts_items_account;
}

export interface accounts_node_Person_accounts {
  __typename: "PageOfPersonAccountOwners";
  items: accounts_node_Person_accounts_items[];
}

export interface accounts_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The accounts that this person is an owner on
   */
  accounts: accounts_node_Person_accounts;
}

export type accounts_node = accounts_node_Document | accounts_node_Person;

export interface accounts {
  /**
   * Returns a node by ID
   */
  node: accounts_node;
}

export interface accountsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: bankAccounts
// ====================================================

export interface bankAccounts_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface bankAccounts_node_Person_bankAccounts_items {
  __typename: "BankAccount";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The account number of this bank account
   */
  accountNumber: string;
  /**
   * The name of this bank account's holder
   */
  holderName: string | null;
  /**
   * The date and time at which this bank account was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
  /**
   * The date and time at which this bank account was created
   */
  createdAt: WsDateTime;
}

export interface bankAccounts_node_Person_bankAccounts {
  __typename: "PageOfBankAccounts";
  items: bankAccounts_node_Person_bankAccounts_items[];
}

export interface bankAccounts_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The bank accounts owned by this person
   */
  bankAccounts: bankAccounts_node_Person_bankAccounts;
}

export type bankAccounts_node = bankAccounts_node_Document | bankAccounts_node_Person;

export interface bankAccounts {
  /**
   * Returns a node by ID
   */
  node: bankAccounts_node;
}

export interface bankAccountsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: communicationHistory
// ====================================================

export interface communicationHistory_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
}

export interface communicationHistory_node_Person_communications_items {
  __typename: "Communication";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The method used for this communication
   */
  method: CommunicationMethod;
  /**
   * The content of this communication
   */
  content: string;
  /**
   * The date and time at which this communication was sent
   */
  sentAt: WsDateTime | null;
  /**
   * The date and time at which this communication was delivered, if delivered
   */
  deliveredAt: WsDateTime | null;
  /**
   * The date and time at which this communication was rejected, if rejected
   */
  rejectedAt: WsDateTime | null;
  /**
   * If this communication was rejected, the cause of rejection
   */
  rejectionCause: string | null;
}

export interface communicationHistory_node_Person_communications {
  __typename: "PageOfCommunications";
  items: communicationHistory_node_Person_communications_items[];
}

export interface communicationHistory_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The messages sent to this person
   */
  communications: communicationHistory_node_Person_communications;
}

export type communicationHistory_node = communicationHistory_node_Document | communicationHistory_node_Person;

export interface communicationHistory {
  /**
   * Returns a node by ID
   */
  node: communicationHistory_node;
}

export interface communicationHistoryVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: communicationPreferences
// ====================================================

export interface communicationPreferences_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
}

export interface communicationPreferences_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The tags on this person
   */
  tags: string[];
}

export type communicationPreferences_node = communicationPreferences_node_Document | communicationPreferences_node_Person;

export interface communicationPreferences {
  /**
   * Returns a node by ID
   */
  node: communicationPreferences_node;
}

export interface communicationPreferencesVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonDetails
// ====================================================

export interface getPersonDetails_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface getPersonDetails_node_Person_name {
  __typename: "UnverifiedPersonName";
  /**
   * The first name of this name
   */
  first: string | null;
  /**
   * The last name of this name
   */
  last: string | null;
}

export interface getPersonDetails_node_Person_dateOfBirth {
  __typename: "UnverifiedPersonDateOfBirth";
  /**
   * The value of this date of birth
   */
  dateOfBirth: WsDate | null;
}

export interface getPersonDetails_node_Person_financialStatus_monthlyIncome {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface getPersonDetails_node_Person_financialStatus_monthlyExpenses {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface getPersonDetails_node_Person_financialStatus_liquidAssets {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface getPersonDetails_node_Person_financialStatus_debt {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface getPersonDetails_node_Person_financialStatus {
  __typename: "FinancialStatus";
  /**
   * The monthly income of this person
   */
  monthlyIncome: getPersonDetails_node_Person_financialStatus_monthlyIncome | null;
  /**
   * The monthly expenses of this person
   */
  monthlyExpenses: getPersonDetails_node_Person_financialStatus_monthlyExpenses | null;
  /**
   * The value of the liquid assets of this person
   */
  liquidAssets: getPersonDetails_node_Person_financialStatus_liquidAssets | null;
  /**
   * The value of the debt of this person
   */
  debt: getPersonDetails_node_Person_financialStatus_debt | null;
}

export interface getPersonDetails_node_Person_taxIdentificationNumbers_numbers {
  __typename: "TaxIdentificationNumber";
  /**
   * The type of this TIN
   */
  type: TaxIdentificationType;
  /**
   * The number of this TIN
   */
  number: string;
}

export interface getPersonDetails_node_Person_taxIdentificationNumbers {
  __typename: "UnverifiedTaxIdentificationNumbers";
  /**
   * The tax identification numbers of this person
   */
  numbers: getPersonDetails_node_Person_taxIdentificationNumbers_numbers[];
}

export interface getPersonDetails_node_Person_residentialAddress {
  __typename: "UnverifiedAddress";
  /**
   * The lines of this address
   */
  lines: string[] | null;
  /**
   * The region of this address
   */
  region: string | null;
  /**
   * The country of this address
   */
  country: string | null;
}

export interface getPersonDetails_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The ID of this person in an external system
   */
  externalId: string | null;
  /**
   * The admin tags on this person
   */
  adminTags: string[];
  /**
   * The name of this person
   */
  name: getPersonDetails_node_Person_name;
  /**
   * The titles of this person
   */
  titles: PersonTitle[];
  /**
   * The date of birth of this person
   */
  dateOfBirth: getPersonDetails_node_Person_dateOfBirth;
  /**
   * The gender of this person
   */
  gender: Gender | null;
  /**
   * The country where this person was born
   */
  countryOfBirth: string | null;
  /**
   * The occupation of this person
   */
  occupation: string | null;
  /**
   * The access code of this person
   */
  accessCode: string | null;
  /**
   * The date and time at which this person was created
   */
  createdAt: WsDateTime;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * Whether this person's email address has been verified
   */
  emailAddressVerified: string;
  /**
   * The mobile phone number of this person
   */
  mobilePhoneNumber: string | null;
  /**
   * The financial status of this person
   */
  financialStatus: getPersonDetails_node_Person_financialStatus;
  /**
   * The tax identification numbers of this person
   */
  taxIdentificationNumbers: getPersonDetails_node_Person_taxIdentificationNumbers;
  /**
   * The residential address of this person
   */
  residentialAddress: getPersonDetails_node_Person_residentialAddress;
}

export type getPersonDetails_node = getPersonDetails_node_Document | getPersonDetails_node_Person;

export interface getPersonDetails {
  /**
   * Returns a node by ID
   */
  node: getPersonDetails_node;
}

export interface getPersonDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonDocuments
// ====================================================

export interface getPersonDocuments_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface getPersonDocuments_node_Person_accounts_items_account_backOfficeAccount {
  __typename: "BackOfficeAccount";
  /**
   * The ID of this back-office account in the back-office
   */
  backOfficeId: string;
  /**
   * The status of this account
   */
  status: AccountStatus;
}

export interface getPersonDocuments_node_Person_accounts_items_account {
  __typename: "Account";
  /**
   * The back-office account of this account
   */
  backOfficeAccount: getPersonDocuments_node_Person_accounts_items_account_backOfficeAccount | null;
}

export interface getPersonDocuments_node_Person_accounts_items {
  __typename: "PersonAccountOwner";
  account: getPersonDocuments_node_Person_accounts_items_account;
}

export interface getPersonDocuments_node_Person_accounts {
  __typename: "PageOfPersonAccountOwners";
  items: getPersonDocuments_node_Person_accounts_items[];
}

export interface getPersonDocuments_node_Person_documents_items {
  __typename: "Document";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The optional original filename of this file
   */
  filename: string | null;
  /**
   * The content-type of this document
   */
  contentType: string;
  /**
   * The tags on this document
   */
  tags: string[];
  /**
   * A URL where this document can be downloaded
   */
  url: string;
  /**
   * The date and time at which this document was created
   */
  createdAt: WsDateTime;
  /**
   * The ID of the user who created this document
   */
  createdBy: string;
}

export interface getPersonDocuments_node_Person_documents {
  __typename: "PageOfDocuments";
  items: getPersonDocuments_node_Person_documents_items[];
}

export interface getPersonDocuments_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The date and time at which this person was created
   */
  createdAt: WsDateTime;
  /**
   * The accounts that this person is an owner on
   */
  accounts: getPersonDocuments_node_Person_accounts;
  /**
   * The documents owned by this person
   */
  documents: getPersonDocuments_node_Person_documents;
}

export type getPersonDocuments_node = getPersonDocuments_node_Document | getPersonDocuments_node_Person;

export interface getPersonDocuments {
  /**
   * Returns a node by ID
   */
  node: getPersonDocuments_node;
}

export interface getPersonDocumentsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonIdVerifications
// ====================================================

export interface getPersonIdVerifications_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface getPersonIdVerifications_node_Person_identityVerifications_items {
  __typename: "IdentityVerification";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The authority of this identity verification
   */
  authority: IdentityVerificationAuthority;
  /**
   * The ID of this identity verification in an external system
   */
  externalId: string | null;
  /**
   * The ID of this identity verification in the back-office system
   */
  backOfficeId: string | null;
  /**
   * The date and time at which this identity verification was rejected - null if not rejected
   */
  rejectedAt: WsDateTime | null;
  /**
   * The cause of this identity verification's rejection - null if not rejected
   */
  rejectionCause: string | null;
  /**
   * The date and time at which this identity verification was created
   */
  createdAt: WsDateTime;
  /**
   * The date and time at which this identity verification was sent - null if not sent
   */
  sentAt: WsDateTime | null;
}

export interface getPersonIdVerifications_node_Person_identityVerifications {
  __typename: "PageOfIdentityVerifications";
  items: getPersonIdVerifications_node_Person_identityVerifications_items[];
}

export interface getPersonIdVerifications_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The identity verifications for this person
   */
  identityVerifications: getPersonIdVerifications_node_Person_identityVerifications;
}

export type getPersonIdVerifications_node = getPersonIdVerifications_node_Document | getPersonIdVerifications_node_Person;

export interface getPersonIdVerifications {
  /**
   * Returns a node by ID
   */
  node: getPersonIdVerifications_node;
}

export interface getPersonIdVerificationsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: personDetails
// ====================================================

export interface personDetails_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface personDetails_node_Person_name {
  __typename: "UnverifiedPersonName";
  /**
   * The first name of this name
   */
  first: string | null;
  /**
   * The last name of this name
   */
  last: string | null;
}

export interface personDetails_node_Person_dateOfBirth {
  __typename: "UnverifiedPersonDateOfBirth";
  /**
   * The value of this date of birth
   */
  dateOfBirth: WsDate | null;
}

export interface personDetails_node_Person_taxIdentificationNumbers_numbers {
  __typename: "TaxIdentificationNumber";
  /**
   * The number of this TIN
   */
  number: string;
}

export interface personDetails_node_Person_taxIdentificationNumbers {
  __typename: "UnverifiedTaxIdentificationNumbers";
  /**
   * The tax identification numbers of this person
   */
  numbers: personDetails_node_Person_taxIdentificationNumbers_numbers[];
}

export interface personDetails_node_Person_residentialAddress {
  __typename: "UnverifiedAddress";
  /**
   * The street number of this address
   */
  streetNumber: string | null;
  /**
   * The street name of this address
   */
  streetName: string | null;
  /**
   * The city of this address
   */
  city: string | null;
  /**
   * The post code of this address
   */
  postCode: string | null;
}

export interface personDetails_node_Person_mailingAddress {
  __typename: "Address";
  /**
   * The street number of this address
   */
  streetNumber: string | null;
  /**
   * The street name of this address
   */
  streetName: string | null;
  /**
   * The city of this address
   */
  city: string | null;
  /**
   * The post code of this address
   */
  postCode: string | null;
}

export interface personDetails_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The name of this person
   */
  name: personDetails_node_Person_name;
  /**
   * The titles of this person
   */
  titles: PersonTitle[];
  /**
   * The admin tags on this person
   */
  adminTags: string[];
  /**
   * The date of birth of this person
   */
  dateOfBirth: personDetails_node_Person_dateOfBirth;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * The token of this person while signup
   */
  token: string | null;
  /**
   * The role of this person
   */
  role: TenantRole;
  /**
   * The gender of this person
   */
  gender: Gender | null;
  /**
   * The occupation of this person
   */
  occupation: string | null;
  /**
   * The mobile phone number of this person
   */
  mobilePhoneNumber: string | null;
  /**
   * Whether MFA is enabled for this person
   */
  mfaEnabled: boolean;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
  /**
   * The date and time at which this person was created
   */
  createdAt: WsDateTime;
  /**
   * The access code of this person
   */
  accessCode: string | null;
  /**
   * The tax identification numbers of this person
   */
  taxIdentificationNumbers: personDetails_node_Person_taxIdentificationNumbers;
  /**
   * The residential address of this person
   */
  residentialAddress: personDetails_node_Person_residentialAddress;
  /**
   * The mailing address of this person
   */
  mailingAddress: personDetails_node_Person_mailingAddress | null;
}

export type personDetails_node = personDetails_node_Person;

export interface personDetails {
  /**
   * Returns a node by ID
   */
  node: personDetails_node;
}

export interface personDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSubscriptionByPersonId
// ====================================================

export interface getSubscriptionByPersonId_getSubscriptionByPersonId {
  __typename: "Subscriptionstripe";
  /**
   * the status of the subscription
   */
  status: boolean;
}

export interface getSubscriptionByPersonId {
  /**
   * search and return subscription details by using person id
   */
  getSubscriptionByPersonId: getSubscriptionByPersonId_getSubscriptionByPersonId | null;
}

export interface getSubscriptionByPersonIdVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: actionsAtSecurityTab
// ====================================================

export interface actionsAtSecurityTab_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface actionsAtSecurityTab_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this person
   */
  version: number;
  /**
   * Whether MFA is enabled for this person
   */
  mfaEnabled: boolean;
  /**
   * The MFA method of this person
   */
  mfaMethod: MfaMethod | null;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
}

export type actionsAtSecurityTab_node = actionsAtSecurityTab_node_Document | actionsAtSecurityTab_node_Person;

export interface actionsAtSecurityTab {
  /**
   * Returns a node by ID
   */
  node: actionsAtSecurityTab_node;
}

export interface actionsAtSecurityTabVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableAccount
// ====================================================

export interface enableAccount_enablePerson {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
}

export interface enableAccount {
  /**
   * Enables a person
   */
  enablePerson: enableAccount_enablePerson;
}

export interface enableAccountVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableAccount
// ====================================================

export interface disableAccount_disablePerson {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The date and time at which this person was disabled, if disabled
   */
  disabledAt: WsDateTime | null;
}

export interface disableAccount {
  /**
   * Disables a person
   */
  disablePerson: disableAccount_disablePerson;
}

export interface disableAccountVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearMFA
// ====================================================

export interface clearMFA_clearMfaSecret_person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this person
   */
  version: number;
  /**
   * The MFA method of this person
   */
  mfaMethod: MfaMethod | null;
  /**
   * Whether MFA is enabled for this person
   */
  mfaEnabled: boolean;
}

export interface clearMFA_clearMfaSecret {
  __typename: "ClearMfaSecretOutput";
  /**
   * The updated person
   */
  person: clearMFA_clearMfaSecret_person;
}

export interface clearMFA {
  /**
   * Disables MFA for a person
   */
  clearMfaSecret: clearMFA_clearMfaSecret;
}

export interface clearMFAVariables {
  personId: string;
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: forgetDevices
// ====================================================

export interface forgetDevices_forgetAllDevices {
  __typename: "SuccessOutput";
  /**
   * Whether this operation was successful
   */
  success: boolean;
}

export interface forgetDevices {
  /**
   * Forgets all devices - existing trusted devices will need to be re-trusted on next sign-in
   */
  forgetAllDevices: forgetDevices_forgetAllDevices;
}

export interface forgetDevicesVariables {
  personId: string;
  version: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: loginHistoryAtSecurityTab
// ====================================================

export interface loginHistoryAtSecurityTab_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface loginHistoryAtSecurityTab_node_Person_signIns_items {
  __typename: "PersonSignIn";
  /**
   * The date and time at which this sign-in was created
   */
  createdAt: WsDateTime;
  /**
   * The IP address from which this sign-in occurred
   */
  ipAddress: string | null;
  /**
   * The user agent used to sign-in
   */
  userAgent: string | null;
}

export interface loginHistoryAtSecurityTab_node_Person_signIns {
  __typename: "PageOfPersonSignIns";
  items: loginHistoryAtSecurityTab_node_Person_signIns_items[];
}

export interface loginHistoryAtSecurityTab_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The sign-ins of this person
   */
  signIns: loginHistoryAtSecurityTab_node_Person_signIns;
}

export type loginHistoryAtSecurityTab_node = loginHistoryAtSecurityTab_node_Document | loginHistoryAtSecurityTab_node_Person;

export interface loginHistoryAtSecurityTab {
  /**
   * Returns a node by ID
   */
  node: loginHistoryAtSecurityTab_node;
}

export interface loginHistoryAtSecurityTabVariables {
  personId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendDepositMutation
// ====================================================

export interface SendDepositMutation_sendDeposit {
  __typename: "DepositStatus";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface SendDepositMutation {
  /**
   * Sets a deposit as sent
   */
  sendDeposit: SendDepositMutation_sendDeposit;
}

export interface SendDepositMutationVariables {
  depositId: string;
  version: number;
  accountPositionVersion: number;
  sentAt: WsDateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopUpAmountDataQuery
// ====================================================

export interface TopUpAmountDataQuery_node_Document {
  __typename: "Document" | "RefreshToken" | "AdjustmentStatus" | "Account" | "BackOfficeAccount" | "PortfolioAdvice" | "Adjustment" | "BankAccount" | "Communication" | "Deposit" | "DepositStatus" | "Feature" | "Group" | "IdentityVerification" | "Order" | "OrderStatus" | "Portfolio" | "PortfolioPercentage" | "Position" | "PositionSnapshot" | "Security" | "Service" | "Withdrawal" | "WithdrawalStatus" | "Notification" | "AddressLookUp" | "Brokerage" | "EmailLoginAttempt" | "FundFactsheet" | "Goals" | "LoginAttempt" | "MarketInsight" | "SellerCode" | "SharesHolding" | "SubscriptionPrice" | "Subscriptionstripe" | "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
}

export interface TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits_items_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits_items {
  __typename: "Deposit";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this deposit
   */
  version: number;
  /**
   * The amount that was deposited
   */
  value: TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits_items_value;
  /**
   * The optional source of funds of this deposit
   */
  sourceOfFunds: string | null;
  /**
   * The Payment Intent ID created by stripe which is to be mapped with this deposit
   */
  paymentIntentId: string | null;
}

export interface TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits {
  __typename: "PageOfDeposits";
  items: TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits_items[];
}

export interface TopUpAmountDataQuery_node_Person_accounts_items_account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The ID of this account in an external system
   */
  externalId: string | null;
  /**
   * The deposits made to this account
   */
  pendingDeposits: TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits;
}

export interface TopUpAmountDataQuery_node_Person_accounts_items {
  __typename: "PersonAccountOwner";
  account: TopUpAmountDataQuery_node_Person_accounts_items_account;
}

export interface TopUpAmountDataQuery_node_Person_accounts {
  __typename: "PageOfPersonAccountOwners";
  items: TopUpAmountDataQuery_node_Person_accounts_items[];
}

export interface TopUpAmountDataQuery_node_Person {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * The Customer ID created by stripe which is to be mapped with this person
   */
  customerId: string | null;
  /**
   * The accounts that this person is an owner on
   */
  accounts: TopUpAmountDataQuery_node_Person_accounts;
}

export type TopUpAmountDataQuery_node = TopUpAmountDataQuery_node_Person;

export interface TopUpAmountDataQuery {
  /**
   * Returns a node by ID
   */
  node: TopUpAmountDataQuery_node;
}

export interface TopUpAmountDataQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDepositMutation
// ====================================================

export interface CreateDepositMutation_registerCardDeposit_account_pendingDeposits_items_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface CreateDepositMutation_registerCardDeposit_account_pendingDeposits_items {
  __typename: "Deposit";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this deposit
   */
  version: number;
  /**
   * The amount that was deposited
   */
  value: CreateDepositMutation_registerCardDeposit_account_pendingDeposits_items_value;
  /**
   * The optional source of funds of this deposit
   */
  sourceOfFunds: string | null;
  /**
   * The tags on this deposit
   */
  tags: string[];
}

export interface CreateDepositMutation_registerCardDeposit_account_pendingDeposits {
  __typename: "PageOfDeposits";
  items: CreateDepositMutation_registerCardDeposit_account_pendingDeposits_items[];
}

export interface CreateDepositMutation_registerCardDeposit_account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The deposits made to this account
   */
  pendingDeposits: CreateDepositMutation_registerCardDeposit_account_pendingDeposits;
}

export interface CreateDepositMutation_registerCardDeposit {
  __typename: "Deposit";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The account that this deposit is paid to
   */
  account: CreateDepositMutation_registerCardDeposit_account;
}

export interface CreateDepositMutation {
  /**
   * Registers a card payment as a pending deposit
   */
  registerCardDeposit: CreateDepositMutation_registerCardDeposit;
}

export interface CreateDepositMutationVariables {
  accountId: string;
  amount: WsDecimal;
  sourceOfFunds?: string | null;
  tags?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDepositSourceOfFundsMutation
// ====================================================

export interface UpdateDepositSourceOfFundsMutation_updatePendingDeposit_value {
  __typename: "Money";
  /**
   * The amount of this money value
   */
  amount: WsDecimal;
}

export interface UpdateDepositSourceOfFundsMutation_updatePendingDeposit_account {
  __typename: "Account";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The position version of this account - incremented each time an operation affects the positions of this account
   */
  positionVersion: number;
}

export interface UpdateDepositSourceOfFundsMutation_updatePendingDeposit {
  __typename: "Deposit";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The version of this deposit
   */
  version: number;
  /**
   * The amount that was deposited
   */
  value: UpdateDepositSourceOfFundsMutation_updatePendingDeposit_value;
  /**
   * The account that this deposit is paid to
   */
  account: UpdateDepositSourceOfFundsMutation_updatePendingDeposit_account;
  /**
   * The optional source of funds of this deposit
   */
  sourceOfFunds: string | null;
}

export interface UpdateDepositSourceOfFundsMutation {
  /**
   * Updates the details of a pending deposit
   */
  updatePendingDeposit: UpdateDepositSourceOfFundsMutation_updatePendingDeposit;
}

export interface UpdateDepositSourceOfFundsMutationVariables {
  depositId: string;
  depositVersion: number;
  sourceOfFunds: string;
  paymentIntentId: string;
  externalIdStripe: string;
  amount: WsDecimal;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchPeople
// ====================================================

export interface searchPeople_tenant_people_items_name {
  __typename: "UnverifiedPersonName";
  /**
   * The first name of this name
   */
  first: string | null;
  /**
   * The last name of this name
   */
  last: string | null;
}

export interface searchPeople_tenant_people_items_dateOfBirth {
  __typename: "UnverifiedPersonDateOfBirth";
  /**
   * The value of this date of birth
   */
  dateOfBirth: WsDate | null;
}

export interface searchPeople_tenant_people_items_accounts_items_account {
  __typename: "Account";
  /**
   * The status of this account
   */
  status: AccountStatus;
}

export interface searchPeople_tenant_people_items_accounts_items {
  __typename: "PersonAccountOwner";
  account: searchPeople_tenant_people_items_accounts_items_account;
}

export interface searchPeople_tenant_people_items_accounts {
  __typename: "PageOfPersonAccountOwners";
  items: searchPeople_tenant_people_items_accounts_items[];
}

export interface searchPeople_tenant_people_items {
  __typename: "Person";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * The role of this person
   */
  role: TenantRole;
  /**
   * The name of this person
   */
  name: searchPeople_tenant_people_items_name;
  /**
   * The admin tags on this person
   */
  adminTags: string[];
  /**
   * The access code of this person
   */
  accessCode: string | null;
  /**
   * The date of birth of this person
   */
  dateOfBirth: searchPeople_tenant_people_items_dateOfBirth;
  /**
   * The accounts that this person is an owner on
   */
  accounts: searchPeople_tenant_people_items_accounts;
  /**
   * The email address of this person
   */
  emailAddress: string;
  /**
   * The date and time at which this person was created
   */
  createdAt: WsDateTime;
}

export interface searchPeople_tenant_people {
  __typename: "PageOfPeople";
  items: searchPeople_tenant_people_items[];
}

export interface searchPeople_tenant {
  __typename: "CurrentTenant";
  /**
   * The people in this tenant
   */
  people: searchPeople_tenant_people;
}

export interface searchPeople {
  /**
   * Returns traits of the current tenant when a user is signed in
   */
  tenant: searchPeople_tenant | null;
}

export interface searchPeopleVariables {
  query: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSustainability
// ====================================================

export interface createSustainability_createSustainability {
  __typename: "Sustainability";
  /**
   * Client Fund for the Sustainability Values
   */
  client_fund: string;
  /**
   * Fund Value for the Sustainability Type
   */
  fund: number | null;
  /**
   * Sustainability Type for the Sustainability Values
   */
  sustainability_type: string;
  /**
   * Market Benchmark Value for the Sustainability Type
   */
  market_benchmark: number | null;
}

export interface createSustainability {
  /**
   * Inserts Sustainability Values in sustainability table
   */
  createSustainability: createSustainability_createSustainability;
}

export interface createSustainabilityVariables {
  client_fund: string;
  sustainability_type: string;
  fund: number;
  market_benchmark: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSustainability
// ====================================================

export interface getSustainability_getSustainability {
  __typename: "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Client Fund for the Sustainability Values
   */
  client_fund: string;
  /**
   * Sustainability Type for the Sustainability Values
   */
  sustainability_type: string;
  /**
   * Fund Value for the Sustainability Type
   */
  fund: number | null;
  /**
   * Market Benchmark Value for the Sustainability Type
   */
  market_benchmark: number | null;
}

export interface getSustainability {
  /**
   * Get Sustainability Table details
   */
  getSustainability: getSustainability_getSustainability[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCountSustainability
// ====================================================

export interface getCountSustainability_getCountSustainability {
  __typename: "Sustainability";
  /**
   * rowCount Value for the Sustainability Values
   */
  rowCount: number;
}

export interface getCountSustainability {
  /**
   * Checks Unique Sustainability details in sustainability table
   */
  getCountSustainability: getCountSustainability_getCountSustainability;
}

export interface getCountSustainabilityVariables {
  client_fund: string;
  sustainability_type: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSustainability
// ====================================================

export interface deleteSustainability_deleteSustainability {
  __typename: "Sustainability";
  /**
   * Fund Value for the Sustainability Type
   */
  fund: number | null;
}

export interface deleteSustainability {
  /**
   * Delete Sustainability Row
   */
  deleteSustainability: deleteSustainability_deleteSustainability;
}

export interface deleteSustainabilityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSustainability
// ====================================================

export interface updateSustainability_updateSustainability {
  __typename: "Sustainability";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Client Fund for the Sustainability Values
   */
  client_fund: string;
  /**
   * Sustainability Type for the Sustainability Values
   */
  sustainability_type: string;
  /**
   * Fund Value for the Sustainability Type
   */
  fund: number | null;
  /**
   * Market Benchmark Value for the Sustainability Type
   */
  market_benchmark: number | null;
}

export interface updateSustainability {
  /**
   * Update Sustainability Row
   */
  updateSustainability: updateSustainability_updateSustainability;
}

export interface updateSustainabilityVariables {
  id: string;
  fund: number;
  market_benchmark: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDistinctClientFundSustainabilityValues
// ====================================================

export interface getDistinctClientFundSustainabilityValues_getDistinctClientFundSustainabilityValues {
  __typename: "Sustainability";
  /**
   * Client Fund for the Sustainability Values
   */
  client_fund: string;
}

export interface getDistinctClientFundSustainabilityValues {
  /**
   * Get Distinct Client Fund Values
   */
  getDistinctClientFundSustainabilityValues: getDistinctClientFundSustainabilityValues_getDistinctClientFundSustainabilityValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createShareHoldings
// ====================================================

export interface createShareHoldings_createShareHoldings {
  __typename: "SharesHolding";
  /**
   * Client Fund Value for the Share Holding
   */
  clientfund: string;
  /**
   * Asset Class Value for the Share Holding
   */
  title: string;
  /**
   * Percentage Value for the Share Holding
   */
  percentage: number | null;
}

export interface createShareHoldings {
  /**
   * Inserts values in share_holdings table
   */
  createShareHoldings: createShareHoldings_createShareHoldings;
}

export interface createShareHoldingsVariables {
  clientfund: string;
  title: string;
  percentage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getShareHoldings
// ====================================================

export interface getShareHoldings_getShareHoldings {
  __typename: "SharesHolding";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Client Fund Value for the Share Holding
   */
  clientfund: string;
  /**
   * Asset Class Value for the Share Holding
   */
  title: string;
  /**
   * Percentage Value for the Share Holding
   */
  percentage: number | null;
}

export interface getShareHoldings {
  /**
   * Get Share Holdings data
   */
  getShareHoldings: getShareHoldings_getShareHoldings[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCountShareHoldings
// ====================================================

export interface getCountShareHoldings_getCountShareHoldings {
  __typename: "SharesHolding";
  /**
   * rowCount Value for the Share Holding
   */
  rowCount: number;
}

export interface getCountShareHoldings {
  /**
   * Checks unique Share Holding Table Values
   */
  getCountShareHoldings: getCountShareHoldings_getCountShareHoldings;
}

export interface getCountShareHoldingsVariables {
  clientfund: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteShareHolding
// ====================================================

export interface deleteShareHolding_deleteShareHolding {
  __typename: "SharesHolding";
  /**
   * Percentage Value for the Share Holding
   */
  percentage: number | null;
}

export interface deleteShareHolding {
  /**
   * Delete Fund SharesHolding Row
   */
  deleteShareHolding: deleteShareHolding_deleteShareHolding;
}

export interface deleteShareHoldingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateShareHolding
// ====================================================

export interface updateShareHolding_updateShareHolding {
  __typename: "SharesHolding";
  /**
   * The unique ID of this object
   */
  id: string;
  /**
   * Client Fund Value for the Share Holding
   */
  clientfund: string;
  /**
   * Asset Class Value for the Share Holding
   */
  title: string;
  /**
   * Percentage Value for the Share Holding
   */
  percentage: number | null;
}

export interface updateShareHolding {
  /**
   * Update SharesHolding Row
   */
  updateShareHolding: updateShareHolding_updateShareHolding;
}

export interface updateShareHoldingVariables {
  id: string;
  title: string;
  percentage: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDistinctClientFunds
// ====================================================

export interface getDistinctClientFunds_getDistinctClientFunds {
  __typename: "SharesHolding";
  /**
   * Client Fund Value for the Share Holding
   */
  clientfund: string;
}

export interface getDistinctClientFunds {
  /**
   * Get Distinct Client Fund Values
   */
  getDistinctClientFunds: getDistinctClientFunds_getDistinctClientFunds[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatus {
  Cancelled = "Cancelled",
  Closed = "Closed",
  Open = "Open",
  Pending = "Pending",
  Rejected = "Rejected",
  Suspended = "Suspended",
}

export enum AccountType {
  GbIsa = "GbIsa",
  GbJuniorIsa = "GbJuniorIsa",
  GbPension = "GbPension",
  GbPersonal = "GbPersonal",
  IePersonal = "IePersonal",
}

export enum CommunicationMethod {
  Email = "Email",
  Push = "Push",
  Sms = "Sms",
}

export enum DepositStatusValue {
  Accepted = "Accepted",
  Cancelled = "Cancelled",
  Pending = "Pending",
  Rejected = "Rejected",
  Sent = "Sent",
}

export enum Gender {
  Female = "Female",
  Male = "Male",
  Other = "Other",
  Unspecified = "Unspecified",
}

export enum IdentityVerificationAuthority {
  Paycasso = "Paycasso",
}

export enum MfaMethod {
  App = "App",
  SMS = "SMS",
}

export enum OrderSide {
  Buy = "Buy",
  Sell = "Sell",
}

export enum OrderStatusValue {
  Accepted = "Accepted",
  Cancelled = "Cancelled",
  Rejected = "Rejected",
}

export enum PersonTitle {
  Dr = "Dr",
  Miss = "Miss",
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
}

export enum PositionType {
  AvailableToWithdraw = "AvailableToWithdraw",
  BookValue = "BookValue",
  GrossDeposit = "GrossDeposit",
  NetDeposit = "NetDeposit",
  SettledQuantity = "SettledQuantity",
  UnsettledQuantity = "UnsettledQuantity",
}

export enum TaxIdentificationType {
  GbNin = "GbNin",
  IePpsn = "IePpsn",
}

export enum TenantRole {
  Admin = "Admin",
  ReadOnlyAdmin = "ReadOnlyAdmin",
  SuperAdmin = "SuperAdmin",
  User = "User",
}

export enum WithdrawalStatusValue {
  Accepted = "Accepted",
  Cancelled = "Cancelled",
  Pending = "Pending",
  Rejected = "Rejected",
  Sent = "Sent",
}

export interface GenerateMfaSecretInput {
  version?: number | null;
  method: MfaMethod;
}

export interface ResetPasswordInput {
  emailAddress: string;
  code: string;
  password: string;
  mfaToken?: string | null;
}

export interface VerifyMfaSecretInput {
  version?: number | null;
  mfaToken: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
