import { ChangeEvent, useState } from "react"

// Validators
export const notEmpty = (errorMessage: string) => (value: string) =>
  !value ? errorMessage : null

export const matchRegex = (errorMessage: string, regex: RegExp) => (
  value: string
) => (!regex.test(value) ? errorMessage : null)

export const maxValue = (errorMessage: string) => (value: string) =>
  parseFloat(value) > 100 || parseFloat(value) <= 0 ? errorMessage : null

export const maxGraphValue = (errorMessage: string) => (value: string) =>
  parseFloat(value) > 200 || parseFloat(value) <= 0 ? errorMessage : null

export type Validation = (value: string) => string | null

// Hooks
const useInputState = (
  initialValue: string,
  validations: Validation[]
): {
  value: string
  hasValidValue: boolean
  errorMessage: string
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  onBlur: () => void
} => {
  const [{ value, blurred }, setState] = useState<{
    value: string
    blurred: boolean
  }>({
    value: initialValue,
    blurred: false,
  })

  const errorMessage =
    validations
      .map((validation) => validation(value))
      .find((message) => message) || ""

  return {
    value,
    hasValidValue: !errorMessage,
    errorMessage: blurred ? errorMessage : "",
    onChange: (event) => {
      const newValue = event.target.value
      setState((prev) => ({ ...prev, value: newValue }))
    },
    onBlur: () => setState((prev) => ({ ...prev, blurred: true })),
  }
}

export default useInputState
