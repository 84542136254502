import React, { FormEventHandler, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import useInputState, {
  maxGraphValue,
  notEmpty,
} from "../../../hooks/useInputState"
import InputFieldNew from "../../FundFactSheets/AddNewFundFact/InputField"
import { capitalizeFirst } from "../../FundFactSheets/AddNewFundFact/constants"
import ClientFundShares from "../../Top10ShareHolding/AddNewShareHolding/clientFundShares"
import {
  getCountSustainability,
  getCountSustainabilityVariables,
} from "../../../@types/graphql"
import { FETCH_UNIQUE_SUSTAINABILITY_DATA } from "../queries"

const ButtonView = styled.div`
  margin-top: 2.5em;
`
const ErrorMessage = styled.div`
  position: absolute;
  color: ${(props) => props.theme.error};
  font-size: 0.75em;
  margin: 0.5em 0;
`
const ClientFundView = styled.div`
  margin-bottom: 1em;
`
const AddSustainability = ({
  onSubmit,
}: {
  onSubmit: (
    clientfund: string,
    title: string,
    fund: string,
    marketBenchmark: string
  ) => void
}) => {
  const [stateClientFund, setStateClientFund] = useState<{
    clientFund: string
  }>({
    clientFund: "",
  })
  const {
    value: title,
    hasValidValue: titleIsValid,
    errorMessage: titleErrorMessage,
    onChange: onChangeTitle,
    onBlur: onBlurTitle,
  } = useInputState("", [notEmpty("This field is required")])

  const {
    value: fund,
    hasValidValue: fundIsValid,
    errorMessage: fundErrorMessage,
    onChange: onChangeFund,
    onBlur: onBlurFund,
  } = useInputState("", [maxGraphValue("Must be a valid number")])

  const {
    value: marketBenchmark,
    hasValidValue: marketBenchmarkIsValid,
    errorMessage: marketBenchmarkErrorMessage,
    onChange: onChangeMarketBenchmark,
    onBlur: onBlurMarketBenchmark,
  } = useInputState("", [maxGraphValue("Must be a valid number")])

  const getClientFund = (clientFund?: any) => {
    setStateClientFund((prev) => ({
      ...prev,
      clientFund,
    }))
  }
  const clientFundValue = stateClientFund.clientFund
  const { data, loading } = useQuery<
    getCountSustainability,
    getCountSustainabilityVariables
  >(FETCH_UNIQUE_SUSTAINABILITY_DATA, {
    variables: {
      client_fund: clientFundValue,
      sustainability_type: capitalizeFirst(title),
    },
    fetchPolicy: "network-only",
  })
  const valueCount = data?.getCountSustainability?.rowCount
  const isDisabled =
    !(
      clientFundValue &&
      titleIsValid &&
      fundIsValid &&
      marketBenchmarkIsValid
    ) ||
    !!valueCount ||
    !fund ||
    !marketBenchmark
  const handleSubmit: FormEventHandler = (ev) => {
    ev.preventDefault()
    onBlurTitle()
    onBlurFund()
    onBlurMarketBenchmark()
    if (!isDisabled) {
      onSubmit(
        clientFundValue.toUpperCase().trim(),
        capitalizeFirst(title.trim()),
        fund.trim(),
        marketBenchmark.trim()
      )
    }
  }
  return (
    <>
      <Title>{strings.ADD_SUSTAINABILITY}</Title>
      <form onSubmit={handleSubmit}>
        <ClientFundView>
          <ClientFundShares parentCallback={getClientFund} />
          {clientFundValue === undefined ? (
            <ErrorMessage>{strings.CLIENT_ERROR}</ErrorMessage>
          ) : null}
        </ClientFundView>
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Sustainability Value"
          value={title}
          onChange={onChangeTitle}
          onBlur={onBlurTitle}
          errorMessage={titleErrorMessage}
          placeholder={strings.SUSTAINABILITY_PLACEHOLDER}
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Fund Value"
          value={fund}
          onChange={onChangeFund}
          onBlur={onBlurMarketBenchmark}
          errorMessage={fundErrorMessage}
          placeholder={strings.FUND_PLACEHOLDER}
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Market Benchmark Value"
          value={marketBenchmark}
          onChange={onChangeMarketBenchmark}
          onBlur={onBlurMarketBenchmark}
          errorMessage={marketBenchmarkErrorMessage}
          placeholder={strings.MARKET_BENCHMARK_PLACEHOLDER}
        />
        {valueCount ? (
          <ErrorMessage>{strings.DUPLICATION_ERROR}</ErrorMessage>
        ) : null}
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={loading}
            disabled={isDisabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default AddSustainability
