const strings = {
  /* MARKET INSIGHT */
  MARKET_INSIGHT: "Market Insights",
  ADD_INSIGHT: "Add New Market Insight",
  UPDATE_INSIGHT: "Update Market Insight",
  CONFIRM_UPDATED_INSIGHT: "Confirm Updated Market Insight",
  CONFIRM_INSIGHT: "New Market Insight ",
  DELETE_INSIGHT: "Delete Market Insight",
  CONFIRM_TITLE: "Title:",
  CONFRIM_SUBTITLE: "Subtitle:",
  CONFIRM_DESCRIPTION: "Description:",

  /** FUND FACT SHEET */
  FUND_FACTSHEET: "Fund Factsheets",
  ADD_FACTSHEET: "Add New Factsheet Data",
  CONFIRM_FACTSHEET: "New Factsheet Data",
  CLIENT_FUND: "Client Fund",
  ASSET_CLASS: "Asset Class",
  SUBCATEGORY: "Subcategory",
  PERCENTAGE: "Percentage",
  PERCENTAGE_PLACEHOLDER: "Enter Percentage",
  DELETE_FACTSHEET: "Delete Factsheet Data",
  DELETE_STATEMENT: "Are you sure you want to delete this row",
  EDIT_FACTSHEET: "Edit Factsheet Data",
  CLIENT_ERROR: "Client Fund is required",
  ASSET_ERROR: "Asset Class is required",
  DUPLICATION_ERROR: "This Row already exists",

  /** TOP 10 SHARE HOLDINGS */
  SHARE_HOLDINGS: "Share Holdings",
  ADD_SHARE: "Add New Share",
  EDIT_SHARE: "Edit Share Holdings Data",
  DELETE_SHARE: "Delete Share Holdings Data",
  CONFIRM_SHARE: "New Share",
  TITLE_PLACEHOLDER: "Enter Title",

  // REWARDS
  REWARDS: "Rewards",
  AMOUNT: "Amount",
  REWARD_POINTS: "Reward Points",
  CONFIRM_USERNAME: "Username",
  TOP_UP_SUCCESS_MSG: "Top up done successfully!",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  USERNAME: "Username",

  // SUSTAINABILITY
  SUSTAINABILITY: "Sustainability",
  ADD_SUSTAINABILITY: "Add New Sustainability Data",
  EDIT_SUSTAINABILITY: "Edit Sustainability Data",
  DELETE_SUSTAINABILITY: "Delete Sustainability Data",
  CONFIRM_SUSTAINABILITY: "New Sustainability Data",
  SUSTAINABILITY_PLACEHOLDER: "Enter Sustainability Type",
  FUND_PLACEHOLDER: "Enter Fund Value",
  SUSTAINABILITY_TYPE: "Sustainability Type",
  FUND: "Fund",
  MARKET_BENCHMARK: "Market Benchmark",
  MARKET_BENCHMARK_PLACEHOLDER: "Enter Market Benchmark Value",
}

export default strings
