import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import { CREATE_SUSTAINABILITY } from "../queries"
import { createSustainabilityVariables } from "../../../@types/graphql"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmSustainabilityData = ({
  clientFund,
  title,
  fund,
  marketBenchmark,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  clientFund: string
  title: string
  fund: string
  marketBenchmark: string
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [
    createSustainabilityMutation,
    { loading: creatingSustainabilty },
  ] = useMutation<createSustainabilityVariables>(CREATE_SUSTAINABILITY)

  const handleSubmit = async (): Promise<void> => {
    const clientFundValue = clientFund?.trim()
    const titleValue = title?.trim()
    const fundValue = fund === "" ? "0" : parseFloat(fund.trim())
    const marketBenchmarkValue =
      marketBenchmark === "" ? "0" : parseFloat(marketBenchmark.trim())
    const {
      data: creatingSustainabilityData,
    } = await createSustainabilityMutation({
      variables: {
        client_fund: clientFundValue,
        sustainability_type: titleValue,
        fund: fundValue,
        market_benchmark: marketBenchmarkValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!creatingSustainabilityData) {
      return
    }

    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_SUSTAINABILITY}</Title>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.CLIENT_FUND}
        <br />
        {clientFund}
      </Paragraph>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.SUSTAINABILITY_TYPE}
        <br />
        {title}
      </Paragraph>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.PERCENTAGE}
        <br />
        {fund}
      </Paragraph>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.PERCENTAGE}
        <br />
        {marketBenchmark}
      </Paragraph>

      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={creatingSustainabilty}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={creatingSustainabilty}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmSustainabilityData
