import { useEffect, useState } from "react"
import {
  getCurrentFeatureStatus,
  subscribeToFeature,
  unsubscribeToFeature,
} from "../feature-config"

export default function useFeatureConfig(features: string[]): boolean[] {
  const [featureStatus, setFeatureStatus] = useState<boolean[]>(
    getCurrentFeatureStatus(features)
  )

  // Subscribe to any change in the auth status. For example, when the session expired.
  useEffect(() => {
    function handleStatusChange() {
      setFeatureStatus(getCurrentFeatureStatus(features))
    }

    subscribeToFeature(handleStatusChange)
    return () => unsubscribeToFeature(handleStatusChange)
  }, [features])

  return featureStatus
}
