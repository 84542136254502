import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import CreatableSelect from "react-select/creatable"
import styled from "styled-components"
import {
  getDistinctClientFundValues,
  getDistinctClientFundValues_getDistinctClientFundValues,
} from "../../../../@types/graphql"
import { GET_CLIENT_FUND_DROPDOWN } from "../../queries"
import { createOption, Option } from "../constants"
import strings from "../../../../components/localization/strings"

const LabelView = styled.div`
  margin: 0.3em;
`

const ClientFundDropDown = ({
  parentCallback,
}: {
  parentCallback: (clientFund: string) => void
}) => {
  let clientFundRows: any
  let clientFundValue: any
  const [value, setValue] = useState<Option | null>()
  const [isLoading, setIsLoading] = useState(false)
  const { data: dataClientFund, refetch } = useQuery<
    getDistinctClientFundValues,
    getDistinctClientFundValues_getDistinctClientFundValues
  >(GET_CLIENT_FUND_DROPDOWN)
  const clientFundData = dataClientFund?.getDistinctClientFundValues
  const clientFundFromDB = clientFundData?.map(({ client_fund }) => {
    clientFundRows = client_fund
    const clientFundRowValues = createOption(clientFundRows)
    return clientFundRowValues
  })

  const [, setClientFundOptions] = useState(clientFundFromDB)
  // eslint-disable-next-line prefer-const
  clientFundValue = value === undefined ? "" : value?.value

  /* eslint-disable */
  useEffect(() => {
    ;(async (): Promise<void> => {
      if (clientFundData) {
        parentCallback(clientFundValue)
        refetch()
      }
    })()
  }, [clientFundValue, clientFundData, refetch])

  const handleCreateClientFund = (inputValue: string) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setClientFundOptions((prev) => [
        ...(prev !== undefined ? prev : []),
        newOption,
      ])
      setValue(newOption)
    }, 1000)
    return value
  }

  return (
    <>
      <LabelView>{strings.CLIENT_FUND}</LabelView>
      <CreatableSelect
        placeholder="Enter Client Fund"
        isDisabled={isLoading}
        isLoading={isLoading}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#888888",
          }),
        }}
        onChange={(newValue) => setValue(newValue)}
        onCreateOption={handleCreateClientFund}
        isClearable
        options={clientFundFromDB}
        value={value}
      />
    </>
  )
}
export default ClientFundDropDown
