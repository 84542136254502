import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import CreatableSelect from "react-select/creatable"
import styled from "styled-components"
import {
  getDistinctAssetClassValues,
  getDistinctAssetClassValues_getDistinctAssetClassValues,
} from "../../../../@types/graphql"
import { GET_ASSET_CLASS_DROPDOWN } from "../../queries"
import { createOption, Option } from "../constants"
import strings from "../../../../components/localization/strings"

const LabelView = styled.div`
  margin: 0.3em;
`

const AssetClassDropDown = ({
  parentCallback,
}: {
  parentCallback: (assetClass: string) => void
}) => {
  let assetClassRows: any
  let assetClassValue: any
  const [value, setValue] = useState<Option | null>()
  const [isLoading, setIsLoading] = useState(false)
  const { data: dataAssetClass, refetch } = useQuery<
    getDistinctAssetClassValues,
    getDistinctAssetClassValues_getDistinctAssetClassValues
  >(GET_ASSET_CLASS_DROPDOWN)
  const assetClassData = dataAssetClass?.getDistinctAssetClassValues
  const assetClassFromDB = assetClassData?.map(({ asset_class }) => {
    assetClassRows = asset_class
    const assetClassRowValues = createOption(assetClassRows)
    return assetClassRowValues
  })

  const [, setAssetClassOptions] = useState(assetClassFromDB)
  // eslint-disable-next-line prefer-const
  assetClassValue = value === undefined ? "" : value?.value

  /* eslint-disable */
  useEffect(() => {
    ;(async (): Promise<void> => {
      if (dataAssetClass?.getDistinctAssetClassValues) {
        parentCallback(assetClassValue)
        refetch()
      }
    })()
  }, [assetClassValue, dataAssetClass, refetch])
  const handleCreateAssetClass = (inputValue: string) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setAssetClassOptions((prev) => [
        ...(prev !== undefined ? prev : []),
        newOption,
      ])
      setValue(newOption)
    }, 1000)
    return value
  }

  return (
    <>
      <br />
      <LabelView>{strings.ASSET_CLASS}</LabelView>
      <CreatableSelect
        placeholder="Enter Asset Class"
        isDisabled={isLoading}
        isLoading={isLoading}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#888888",
          }),
        }}
        onChange={(newValue) => setValue(newValue)}
        onCreateOption={handleCreateAssetClass}
        isClearable
        options={assetClassFromDB}
        value={value}
      />
    </>
  )
}
export default AssetClassDropDown
