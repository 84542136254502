import { useQuery } from "@apollo/client"
import React, { FC, useState } from "react"
import styled from "styled-components"
import {
  getSustainability,
  getSustainability_getSustainability,
} from "../../@types/graphql"
import Table, { Row } from "../../components/Table"
import Button from "../../components/Button"
import { Paragraph, Wrapper } from "../Dashboard/styles"
import strings from "../../components/localization/strings"
import { FETCH_SUSTAINABILITY_DATA } from "./queries"
import AddNewSustainabilityModal from "./AddNewSustainabilityData"
import DeleteSustainabilityModal from "./DeleteSustainabilityData"
import UpdateSustainabilityModal from "./UpdateSustainabilityData"

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonView = styled.div`
  padding-left: 1em;
  width: 20%;
`
const SustainabilityScreen: FC = () => {
  const headers = [
    "Client Fund",
    "Title",
    "Fund",
    "Market Benchmark",
    "",
    "Action",
  ]
  const { data, refetch } = useQuery<
    getSustainability,
    getSustainability_getSustainability
  >(FETCH_SUSTAINABILITY_DATA)

  const sustainabilityData = data?.getSustainability
  const [showAddSustainability, setShowAddSustainability] = useState<boolean>(
    false
  )
  const [showUpdateSustainability, setShowUpdateSustainability] = useState<
    boolean
  >(false)
  const [showDeleteSustainability, setShowDeleteSustainability] = useState<
    boolean
  >(false)
  const [id, setId] = useState<string>(" ")
  const [clientFundValue, setClientFundValue] = useState<string>("")
  const [titleValue, setTitleValue] = useState<string>(" ")
  const [fundValue, setFundValue] = useState<number>(0)
  const [marketBenchmarkValue, setMarketBenchmarkValue] = useState<number>(0)
  const [, setReload] = useState(false)

  const sustainabilityRows = sustainabilityData?.map(
    ({
      id: shareId,
      client_fund,
      sustainability_type,
      fund,
      market_benchmark,
    }) => {
      const deleteButton = (
        <Button
          type="button"
          text="Delete"
          mainAction={false}
          onClick={() => {
            setId(shareId)
            setShowDeleteSustainability(true)
          }}
          loading={false}
        />
      )

      const updateButton = (
        <Button
          type="button"
          text="Update"
          mainAction={false}
          onClick={() => {
            setId(shareId)
            setClientFundValue(client_fund)
            setTitleValue(sustainability_type)
            if (fund !== null) setFundValue(fund)
            if (market_benchmark !== null)
              setMarketBenchmarkValue(market_benchmark)
            setShowUpdateSustainability(!showUpdateSustainability)
          }}
          loading={false}
        />
      )

      const DeleteSustainabilityButton = () => {
        return deleteButton
      }

      const UpdateSustainabilityButton = () => {
        return updateButton
      }
      const sustainabilityValues = [
        client_fund,
        sustainability_type,
        fund !== 0 ? [fund?.toString(), " %"] : null,
        market_benchmark !== 0 ? [market_benchmark?.toString(), " %"] : null,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        UpdateSustainabilityButton() as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        DeleteSustainabilityButton() as any,
      ]
      return <Row key={shareId} values={sustainabilityValues} />
    }
  )
  return (
    <>
      {showAddSustainability && (
        <AddNewSustainabilityModal
          onClose={() => {
            setShowAddSustainability(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
        />
      )}
      {showUpdateSustainability && (
        <UpdateSustainabilityModal
          onClose={() => {
            setShowUpdateSustainability(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          sustainabilityId={id}
          clientFund={clientFundValue}
          title={titleValue}
          fund={fundValue}
          marketBenchmark={marketBenchmarkValue}
        />
      )}
      {showDeleteSustainability && (
        <DeleteSustainabilityModal
          onClose={() => {
            setShowDeleteSustainability(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          sustainabilityId={id}
        />
      )}
      <Wrapper>
        <TopArea>
          <Paragraph>{strings.SUSTAINABILITY}</Paragraph>
          <ButtonView>
            <Button
              type="button"
              text={strings.ADD_SUSTAINABILITY}
              mainAction={false}
              onClick={() => setShowAddSustainability(true)}
              loading={false}
            />
          </ButtonView>
        </TopArea>
        <TopArea>
          <Table
            columnNames={headers}
            isEmpty={sustainabilityRows?.length === 0}
          >
            {sustainabilityRows}
          </Table>
        </TopArea>
      </Wrapper>
    </>
  )
}

export default SustainabilityScreen
