import React, { FormEventHandler, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import useInputState, { maxValue, notEmpty } from "../../../hooks/useInputState"
import InputFieldNew from "../../FundFactSheets/AddNewFundFact/InputField"
import { capitalizeFirst } from "../../FundFactSheets/AddNewFundFact/constants"
import ClientFundShares from "./clientFundShares"
import {
  getCountShareHoldings,
  getCountShareHoldingsVariables,
} from "../../../@types/graphql"
import { FETCH_UNIQUE_SHARES } from "../queries"

const ButtonView = styled.div`
  margin-top: 2.5em;
`
const ErrorMessage = styled.div`
  position: absolute;
  color: ${(props) => props.theme.error};
  font-size: 0.75em;
  margin: 0.5em 0;
`
const ClientFundView = styled.div`
  margin-bottom: 1em;
`

const AddShareForm = ({
  onSubmit,
}: {
  onSubmit: (clientfund: string, title: string, percentage: string) => void
}) => {
  const [stateClientFund, setStateClientFund] = useState<{
    clientFund: string
  }>({
    clientFund: "",
  })
  const {
    value: title,
    hasValidValue: titleIsValid,
    errorMessage: titleErrorMessage,
    onChange: onChangeTitle,
    onBlur: onBlurTitle,
  } = useInputState("", [notEmpty("This field is required")])

  const {
    value: percentage,
    hasValidValue: percentageIsValid,
    errorMessage: percentage_ErrorMessage,
    onChange: onChangePercentage,
    onBlur: onBlurPercentage,
  } = useInputState("", [maxValue("Must be a valid number")])

  const getClientFund = (clientFund?: any) => {
    setStateClientFund((prev) => ({
      ...prev,
      clientFund,
    }))
  }
  const clientFundValue = stateClientFund.clientFund
  const { data, loading } = useQuery<
    getCountShareHoldings,
    getCountShareHoldingsVariables
  >(FETCH_UNIQUE_SHARES, {
    variables: {
      clientfund: clientFundValue,
      title: capitalizeFirst(title),
    },
    fetchPolicy: "network-only",
  })

  const valueCount = data?.getCountShareHoldings?.rowCount
  const isDisabled =
    !(clientFundValue && titleIsValid && percentageIsValid) ||
    !!valueCount ||
    !percentage
  const handleSubmit: FormEventHandler = (ev) => {
    ev.preventDefault()
    onBlurTitle()
    onBlurPercentage()
    if (!isDisabled) {
      onSubmit(
        clientFundValue.toUpperCase().trim(),
        capitalizeFirst(title.trim()),
        percentage.trim()
      )
    }
  }
  return (
    <>
      <Title>{strings.ADD_SHARE}</Title>
      <form onSubmit={handleSubmit}>
        <ClientFundView>
          <ClientFundShares parentCallback={getClientFund} />
          {clientFundValue === undefined ? (
            <ErrorMessage>{strings.CLIENT_ERROR}</ErrorMessage>
          ) : null}
        </ClientFundView>
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Title"
          value={title}
          onChange={onChangeTitle}
          onBlur={onBlurTitle}
          errorMessage={titleErrorMessage}
          placeholder={strings.TITLE_PLACEHOLDER}
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Percentage"
          value={percentage}
          onChange={onChangePercentage}
          onBlur={onBlurPercentage}
          errorMessage={percentage_ErrorMessage}
          placeholder={strings.PERCENTAGE_PLACEHOLDER}
        />
        {valueCount ? (
          <ErrorMessage>{strings.DUPLICATION_ERROR}</ErrorMessage>
        ) : null}
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={loading}
            disabled={isDisabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default AddShareForm
