import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const GET_PERSON_ID_VERIFICATIONS = gql`
  query getPersonIdVerifications($id: String!) {
    node(id: $id) {
      id
      __typename
      ... on Person {
        id
        identityVerifications {
          items {
            id
            authority
            externalId
            backOfficeId
            rejectedAt
            rejectionCause
            createdAt
            sentAt
          }
        }
      }
    }
  }
`
