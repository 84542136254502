import React, { ReactElement } from "react"
import { StyledPillTag, Wrapper } from "./styles"

const Tag = ({
  label,
  icon,
  type = "default",
}: {
  label: string
  icon?: ReactElement
  type?: "default" | "accepted" | "outlined"
}) => {
  return (
    <StyledPillTag type={type} labelText={label}>
      {icon ? (
        <Wrapper>
          {label} {icon}
        </Wrapper>
      ) : (
        label
      )}
    </StyledPillTag>
  )
}

export default Tag
