import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const TOP_UP_AMOUNT_DATA_QUERY = gql`
  query TopUpAmountDataQuery($id: String!) {
    node(id: $id) {
      id
      __typename
      ... on Person {
        id
        emailAddress
        customerId
        accounts {
          items {
            account {
              id
              externalId
              pendingDeposits: deposits(includeLastStatuses: [Pending]) {
                items {
                  id
                  version
                  value {
                    amount
                  }
                  sourceOfFunds
                  paymentIntentId
                }
              }
            }
          }
        }
      }
    }
  }
`
