import React, { FormEventHandler, useState } from "react"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { ButtonView } from "../../Admin/InviteAdminModal/styles"
import Button from "../../../components/Button"
import useInputState, { maxValue, notEmpty } from "../../../hooks/useInputState"
import InputFieldNew from "../../FundFactSheets/AddNewFundFact/InputField"
import { capitalizeFirst } from "../../FundFactSheets/AddNewFundFact/constants"

const UpdateShareHolding = ({
  clientFund,
  title,
  percentage,
  onSubmit,
}: {
  onSubmit: (titleEdit: string, percentageEdit: number) => void
  fundId: string
  clientFund: string
  title: string
  percentage: number
}) => {
  const [stateClientFund] = useState<{
    clientFund: string
  }>({
    clientFund,
  })

  const [stateTitle] = useState<{
    title: string
  }>({
    title,
  })

  const [statePercentage] = useState<{
    percentage: number
  }>({
    percentage,
  })
  const clientFundValue = stateClientFund?.clientFund
  const titleValue = stateTitle.title
  const percentageValue =
    statePercentage?.percentage === null ? 0 : statePercentage?.percentage
  const titleField = useInputState(titleValue, [notEmpty("Title is required")])
  const percentageField = useInputState(percentageValue.toString(), [
    maxValue("Must be a valid number"),
  ])
  const validTitle = titleField?.hasValidValue
  const validPercentage = percentageField?.hasValidValue
  const isDisabled = !(validTitle && validPercentage)
  const handleSubmit: FormEventHandler = async (ev): Promise<void> => {
    ev.preventDefault()
    onSubmit(
      capitalizeFirst(titleField?.value),
      parseFloat(percentageField?.value)
    )
  }

  return (
    <>
      <Title>{strings.EDIT_SHARE}</Title>
      <form onSubmit={handleSubmit}>
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Client Fund"
          value={clientFundValue}
          onChange={() => {}}
          onBlur={() => {}}
          errorMessage=""
          disabled
          placeholder=""
        />
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Title"
          value={titleField?.value}
          onChange={titleField?.onChange}
          onBlur={titleField?.onBlur}
          errorMessage={titleField?.errorMessage}
          placeholder=""
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Percentage"
          value={percentageField?.value}
          onChange={percentageField?.onChange}
          onBlur={percentageField?.onBlur}
          errorMessage={percentageField?.errorMessage}
          placeholder=""
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={isDisabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default UpdateShareHolding
