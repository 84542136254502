import React from "react"
import styled from "styled-components"
import { MdDone } from "react-icons/md"
import AttributeList from "../../../../components/AttributeList"
import { AssessmentCard, ContentSection, StatusSection } from "../styles"
import Tag from "../../../../components/Tag"

export const warningsMap: Record<string, string> = {
  savings: "You may not have enough emergency money.",
  "outgo-exceeds-income": "You do not have disposable income.",
  "inconsistent-risk-tolerance":
    "Your answers about investment losses are inconsistent.",
  "negative-net-worth-and-income":
    "Your combination of your income and outgoings, assets and debt suggest to us that now is not the right time for you to invest.",
  "outgo-high-percentage-of-income":
    "You currently spend more than 75% of your income.",
  "negative-net-worth":
    "You do not have enough disposable income or assets to cover debt repayments.",
  "neutral-assets-and-debt": "You do not have any cash or investments.",
  "inconsistent-funds-and-assets":
    "Your answers about cash assets are inconsistent.",
}

const StyledTag = styled.div`
  > span {
    padding: 0.5em 0.7em 0.6em;
  }
`

interface Assessment {
  status?: string | null
  lastUpdatedAt?: string | null
  recommendedPortfolio?: string | null
  warnings?: Array<string> | null
  monthlysum?: string | null
  lumpsum?: string | null
  assessmentId?: string | null
  surveyResponses?: Array<{
    questionId: string
    questionText: string
    response: string
  }> | null
}

const Assessment = ({
  status,
  lastUpdatedAt,
  recommendedPortfolio,
  warnings,
  monthlysum,
  lumpsum,
  assessmentId,
  surveyResponses,
}: Assessment) => {
  const adviceStatus = (
    <StyledTag>
      {status === "Accepted" ? (
        <Tag type="accepted" label={status} icon={<MdDone size="18" />} />
      ) : (
        <Tag label={status || ""} />
      )}
    </StyledTag>
  )

  return (
    <AssessmentCard>
      <h3>Assessment: {assessmentId || "-"}</h3>
      <StatusSection>
        <AttributeList orientation="horizontal">
          <li>
            <h4>Last updated</h4>
            <p>{lastUpdatedAt || "-"}</p>
          </li>
          <li>
            <h4>Status</h4>
            {adviceStatus}
          </li>
        </AttributeList>
        <AttributeList orientation="horizontal">
          <li>
            <h4>Recommended Portfolio</h4>
            <p>{recommendedPortfolio || "-"}</p>
          </li>
        </AttributeList>
      </StatusSection>
      <ContentSection>
        <h4>Warnings</h4>
        {warnings && warnings.length > 0 ? (
          warnings.map((warning) => <p key={warning}>{warningsMap[warning]}</p>)
        ) : (
          <p>None.</p>
        )}
      </ContentSection>
      <ContentSection>
        <h4>The Recommended investment amounts</h4>
        <AttributeList orientation="horizontal">
          <li>
            <h4>Monthly</h4>
            <p>{monthlysum}</p>
            <h4>Lump Sum</h4>
            <p>{lumpsum}</p>
          </li>
        </AttributeList>
      </ContentSection>
      <ContentSection>
        <h4>Responses</h4>
        {surveyResponses && surveyResponses.length > 0 ? (
          <AttributeList orientation="horizontal" wide>
            {surveyResponses?.map((item) => {
              const splitAnswer = item.response.split(";")
              return (
                <li key={item.questionId}>
                  <h4>{item.questionText}</h4>
                  <p>
                    {splitAnswer.map((response, index) => (
                      <span key={response}>
                        {response}
                        {splitAnswer.length > 1 &&
                          index !== splitAnswer.length - 1 && <br />}
                      </span>
                    ))}
                  </p>
                </li>
              )
            })}
          </AttributeList>
        ) : (
          <p>None.</p>
        )}
      </ContentSection>
    </AssessmentCard>
  )
}

export default Assessment
