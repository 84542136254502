import React, { useEffect, useState } from "react"

import CorporateLogo from "../../components/CorporateLogo"
import SignInForm from "./SignInForm"
import MFAForm from "./MFAForm"

import { LogoBox, Page } from "./styles"
import {
  getCurrentStatus,
  subscribeToStatus,
  unsubscribeToStatus,
} from "../../auth"

function calculateCurrentStep() {
  if (getCurrentStatus() === "UntrustedDevice") {
    return "MFA"
  }
  return "SignIn"
}

const LoginPage = () => {
  const [step, setStep] = useState<"SignIn" | "MFA">("SignIn")
  const [emailAddress, setEmailAddress] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    function handleStatusChange() {
      setStep(calculateCurrentStep())
    }
    subscribeToStatus(handleStatusChange)
    return () => unsubscribeToStatus(handleStatusChange)
  }, [])

  return (
    <Page>
      <LogoBox>
        <CorporateLogo />
      </LogoBox>
      {step === "SignIn" || !emailAddress || !password ? (
        <SignInForm
          emailAddress={emailAddress}
          password={password}
          setEmailAddress={setEmailAddress}
          setPassword={setPassword}
        />
      ) : (
        <MFAForm emailAddress={emailAddress} password={password} />
      )}
    </Page>
  )
}

export default LoginPage
