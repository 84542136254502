import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import { createFundFact } from "../../../@types/graphql"
import { CREATE_FUND_FACT } from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmFundFact = ({
  clientFund,
  assetClass,
  subCategory,
  percentage,
  mainCategory,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  clientFund: string
  assetClass: string
  subCategory: string | null
  percentage: string
  mainCategory: boolean
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [createFundFactMutation, { loading: creatingFundFact }] = useMutation<
    createFundFact
  >(CREATE_FUND_FACT)

  const handleSubmit = async (): Promise<void> => {
    const clientFundValue = clientFund?.trim()
    const assetClassValue = assetClass?.trim()
    const subCategoryValue = subCategory?.trim()
    const percentageValue =
      percentage === "" ? "0" : parseFloat(percentage.trim())
    const mainCategoryValue = mainCategory
    const { data: creatingFundFactData } = await createFundFactMutation({
      variables: {
        client_fund: clientFundValue,
        asset_class: assetClassValue,
        subcategory: subCategoryValue,
        percentage: percentageValue,
        main_category: mainCategoryValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!creatingFundFactData) {
      return
    }

    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_FACTSHEET}</Title>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.CLIENT_FUND}
        <br />
        {clientFund}
      </Paragraph>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.ASSET_CLASS}
        <br />
        {assetClass}
      </Paragraph>
      {subCategory && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.SUBCATEGORY}
          <br />
          {subCategory}
        </Paragraph>
      )}
      {percentage !== "0" && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.PERCENTAGE}
          <br />
          {percentage}
        </Paragraph>
      )}
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={creatingFundFact}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={creatingFundFact}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmFundFact
