import { gql } from "@apollo/client"

export const SEND_DEPOSIT_MUTATION = gql`
  mutation SendDepositMutation(
    $depositId: String!
    $version: Int!
    $accountPositionVersion: Int!
    $sentAt: DateTime!
  ) {
    sendDeposit(
      input: {
        depositId: $depositId
        version: $version
        accountPositionVersion: $accountPositionVersion
        sentAt: $sentAt
      }
    ) {
      id
    }
  }
`
