import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import CreatableSelect from "react-select/creatable"
import styled from "styled-components"
import {
  createOption,
  Option,
} from "../../FundFactSheets/AddNewFundFact/constants"
import { GET_CLIENT_FUND_SHARES } from "../queries"
import {
  getDistinctClientFunds,
  getDistinctClientFunds_getDistinctClientFunds,
} from "../../../@types/graphql"
import strings from "../../../components/localization/strings"

const LabelView = styled.div`
  margin: 0.3em;
`

const ClientFundShares = ({
  parentCallback,
}: {
  parentCallback: (clientFund: string) => void
}) => {
  let clientFundRows: any
  let clientFundValue: any
  const [value, setValue] = useState<Option | null>()
  const [isLoading, setIsLoading] = useState(false)
  const { data: dataClientFund, refetch } = useQuery<
    getDistinctClientFunds,
    getDistinctClientFunds_getDistinctClientFunds
  >(GET_CLIENT_FUND_SHARES)
  const clientFundData = dataClientFund?.getDistinctClientFunds
  const clientFund = clientFundData?.map(({ clientfund }) => {
    clientFundRows = clientfund
    const clientFundRowValues = createOption(clientFundRows)
    return clientFundRowValues
  })

  const [, setClientFundOptions] = useState(clientFund)
  // eslint-disable-next-line prefer-const
  clientFundValue = value === undefined ? "" : value?.value

  /* eslint-disable */
  useEffect(() => {
    ;(async (): Promise<void> => {
      if (clientFundData) {
        parentCallback(clientFundValue)
        refetch()
      }
    })()
  }, [clientFundValue, clientFundData, refetch])

  const handleCreateClientFund = (inputValue: string) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setClientFundOptions((prev) => [
        ...(prev !== undefined ? prev : []),
        newOption,
      ])
      setValue(newOption)
    }, 1000)
    return value
  }

  return (
    <>
      <LabelView>{strings.CLIENT_FUND}</LabelView>
      <CreatableSelect
        placeholder="Enter Client Fund"
        isDisabled={isLoading}
        isLoading={isLoading}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#888888",
          }),
        }}
        onChange={(newValue) => setValue(newValue)}
        onCreateOption={handleCreateClientFund}
        isClearable
        options={clientFund}
        value={value}
      />
    </>
  )
}
export default ClientFundShares
