export const createOption = (label: string) => ({
  label,
  value: label.trim(),
})
export interface Option {
  label?: string
  value?: string
}

export enum AddFundFact {
  Form,
  Confirm,
  Error,
}

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
