import React from "react"
import { Route, Switch, useParams } from "react-router"

import Tabs, { Tab } from "../../components/Tabs"
import Accounts from "./Accounts"
import BankAccounts from "./BankAccounts"
import Communications from "./Communications"
import Details from "./Details"
import Documents from "./Documents"
import IdVerifications from "./IdVerifications"
import PersonProfile from "./PersonProfile"
import Security from "./Security"

const PersonPage = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <div data-testid="personPage">
      <PersonProfile />
      <Tabs>
        <Tab to={`/person/${id}/accounts`} label="Accounts" defaultTab />
        <Tab to={`/person/${id}/details`} label="Details" />
        <Tab to={`/person/${id}/documents`} label="Documents" />
        <Tab to={`/person/${id}/communications`} label="Communications" />
        <Tab to={`/person/${id}/security`} label="Security" />
        <Tab to={`/person/${id}/bank-accounts`} label="Bank accounts" />
        <Tab to={`/person/${id}/id-verifications`} label="Id verifications" />
      </Tabs>
      <div>
        <Switch>
          <Route path="/person/:id/accounts" component={Accounts} />
          <Route path="/person/:id/details" component={Details} />
          <Route path="/person/:id/documents" component={Documents} />
          <Route path="/person/:id/communications" component={Communications} />
          <Route path="/person/:id/security" component={Security} />
          <Route path="/person/:id/bank-accounts" component={BankAccounts} />
          <Route
            path="/person/:id/id-verifications"
            component={IdVerifications}
          />
          <Route path="/person/:id" component={Accounts} />
        </Switch>
      </div>
    </div>
  )
}

export default PersonPage
