import cryptoJS from "crypto-js"
import * as env from "../env"

export const encryptString = (textToBeEncypted: string): string => {
  const encryptedText = cryptoJS.AES.encrypt(
    textToBeEncypted,
    env.encryptionKey()
  ).toString()
  return encryptedText
}
export const decryptString = (textToBeDecrypted: string): string => {
  const bytes = cryptoJS.AES.decrypt(
    textToBeDecrypted,
    env.encryptionKey().toString()
  )
  const decryptedText = bytes.toString(cryptoJS.enc.Utf8)
  return decryptedText
}

// Created seperate function to encrypt- decrypt JWT token as the way of encrypting and decrypting is different in case of jSON values.
export const encryptToken = (textToBeEncypted: string): string => {
  const encryptedText = cryptoJS.AES.encrypt(
    JSON.stringify(textToBeEncypted),
    env.encryptionKey()
  ).toString()
  const encData = cryptoJS.enc.Base64.stringify(
    cryptoJS.enc.Utf8.parse(encryptedText)
  )
  return encData
}
export const decryptToken = (textToBeDecrypted: string): string => {
  const decData = cryptoJS.enc.Base64.parse(textToBeDecrypted).toString(
    cryptoJS.enc.Utf8
  )
  const decryptedText = cryptoJS.AES.decrypt(decData, env.encryptionKey())
    .toString(cryptoJS.enc.Utf8)
    .replace(/["]/g, "")
  return decryptedText
}
