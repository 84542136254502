import styled from "styled-components"

export const StyledPillTag = styled.span<{
  type: "default" | "accepted" | "outlined"
  labelText?: string | undefined
}>`
  display: inline-block;
  background-color: ${({ type, labelText }) => {
    if (type === "accepted") return "#6ca06c"
    if (type === "outlined") return "none"
    if (labelText === "PTSB") return "#ffc87c"
    if (labelText === "Pay Schedule ON") return "#3ab563"
    return "#d9d9d9"
  }};
  color: ${({ type, theme }) =>
    type === "accepted" ? "white" : theme.typography};
  border: ${({ type, theme }) =>
    type === "outlined" ? `1px solid ${theme.primaryAction}` : "none"};
  border-radius: 1em;
  white-space: nowrap;
  font-size: 0.9em;
  padding: 5px 10px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin-bottom: 15px;

  &::empty {
    display: none;
  }
`
