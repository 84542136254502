import { DefaultTheme } from "styled-components"

import logoImg from "../resources/irishlife-logo.svg"

export const irishlife: DefaultTheme = {
  primary: "#5261A6",
  primaryLight: "#2096F3",
  primaryDark: "#475496",
  secondary: "#2E909D",
  background: "#E8E8E8",
  primaryAction: "#2E909D",
  secondaryAction: "black",
  error: "#F54A4A",
  typography: "#333333",
  typographyInverse: "#FFFFFF",
  font: "Lato, sans-serif",
  fontBold: "Lato-bold",
  fontSize: "14px",
  titleFontSize: "20px",
  headingFontSize: "16px",
  shadow: "0 4px 8px -5px #AAA",
  logo: logoImg,
  unfocused: "#D4D4D4",
  disabled: "#666666",
}

export default irishlife
