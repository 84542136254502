export default [
  { id: "IE", option: "Ireland" },
  { id: "AF", option: "Afghanistan" },
  { id: "AL", option: "Albania" },
  { id: "DZ", option: "Algeria" },
  { id: "AD", option: "Andorra" },
  { id: "AO", option: "Angola" },
  { id: "AI", option: "Anguilla" },
  { id: "AG", option: "Antigua and Barbuda" },
  { id: "AR", option: "Argentina" },
  { id: "AM", option: "Armenia" },
  { id: "SH-AC", option: "Ascension Island" },
  { id: "AU", option: "Australia" },
  { id: "AT", option: "Austria" },
  { id: "AZ", option: "Azerbaijan" },
  { id: "BS", option: "Bahamas" },
  { id: "BH", option: "Bahrain" },
  { id: "BD", option: "Bangladesh" },
  { id: "BB", option: "Barbados" },
  { id: "BY", option: "Belarus" },
  { id: "BE", option: "Belgium" },
  { id: "BZ", option: "Belize" },
  { id: "BJ", option: "Benin" },
  { id: "BM", option: "Bermuda" },
  { id: "BT", option: "Bhutan" },
  { id: "BO", option: "Bolivia" },
  { id: "BA", option: "Bosnia and Herzegovina" },
  { id: "BW", option: "Botswana" },
  { id: "BR", option: "Brazil" },
  { id: "BN", option: "Brunei Darussalam" },
  { id: "BG", option: "Bulgaria" },
  { id: "BF", option: "Burkina Faso" },
  { id: "BI", option: "Burundi" },
  { id: "CM", option: "Cameroon" },
  { id: "CA", option: "Canada" },
  { id: "CV", option: "Cape Verde" },
  { id: "KY", option: "Cayman Islands" },
  { id: "CF", option: "Central African Republic" },
  { id: "TD", option: "Chad" },
  { id: "RU-CE", option: "Chechnya" },
  { id: "CL", option: "Chile" },
  { id: "CN", option: "China" },
  { id: "CO", option: "Colombia" },
  { id: "KM", option: "Comoros" },
  { id: "CG", option: "Congo" },
  { id: "CR", option: "Costa Rica" },
  { id: "HR", option: "Croatia" },
  { id: "CU", option: "Cuba" },
  { id: "CY", option: "Cyprus" },
  { id: "CZ", option: "Czech Republic" },
  { id: "CD", option: "Congo democratic republic" },
  { id: "DK", option: "Denmark" },
  { id: "DJ", option: "Djibouti" },
  { id: "DM", option: "Dominica" },
  { id: "DO", option: "Dominican Republic" },
  { id: "EC", option: "Ecuador" },
  { id: "EG", option: "Egypt" },
  { id: "IO", option: "Eire" },
  { id: "SV", option: "El Salvador" },
  { id: "GQ", option: "Equatorial Guinea" },
  { id: "ER", option: "Eritrea" },
  { id: "EE", option: "Estonia" },
  { id: "ET", option: "Ethiopia" },
  { id: "FO", option: "Faeroe Islands" },
  { id: "FK", option: "Falkland Islands" },
  { id: "FJ", option: "Fiji" },
  { id: "FI", option: "Finland" },
  { id: "FR", option: "France" },
  { id: "GF", option: "French Guiana" },
  { id: "PF", option: "French Polynesia" },
  { id: "GA", option: "Gabon" },
  { id: "GM", option: "Gambia" },
  { id: "GE", option: "Georgia" },
  { id: "DE", option: "Germany" },
  { id: "GH", option: "Ghana" },
  { id: "GI", option: "Gibraltar" },
  { id: "GR", option: "Greece" },
  { id: "GL", option: "Greenland" },
  { id: "GD", option: "Grenada" },
  { id: "GP", option: "Guadeloupe" },
  { id: "GT", option: "Guatemala" },
  { id: "GN", option: "Guinea" },
  { id: "GW", option: "Guinea Bissau" },
  { id: "GY", option: "Guyana" },
  { id: "HT", option: "Haiti" },
  { id: "HN", option: "Honduras" },
  { id: "HK", option: "Hong Kong" },
  { id: "HU", option: "Hungary" },
  { id: "IS", option: "Iceland" },
  { id: "IN", option: "India" },
  { id: "ID", option: "Indonesia" },
  { id: "IR", option: "Iran" },
  { id: "IQ", option: "Iraq" },
  { id: "IL", option: "Israel" },
  { id: "IT", option: "Italy" },
  { id: "CI", option: "Ivory Coast" },
  { id: "JM", option: "Jamaica" },
  { id: "JP", option: "Japan" },
  { id: "JO", option: "Jordan" },
  { id: "KH", option: "Cambodia" },
  { id: "KZ", option: "Kazakhstan" },
  { id: "KE", option: "Kenya" },
  { id: "KI", option: "Kiribati" },
  { id: "RS-KM", option: "Kosovo" },
  { id: "KW", option: "Kuwait" },
  { id: "KG", option: "Kyrgyzstan" },
  { id: "LA", option: "Laos" },
  { id: "LV", option: "Latvia" },
  { id: "LB", option: "Lebanon" },
  { id: "LS", option: "Lesotho" },
  { id: "LR", option: "Liberia" },
  { id: "LY", option: "Libya" },
  { id: "LI", option: "Liechtenstein" },
  { id: "LT", option: "Lithuania" },
  { id: "LU", option: "Luxembourg" },
  { id: "MO", option: "Macao" },
  { id: "MK", option: "Macedonia" },
  { id: "MG", option: "Madagascar" },
  { id: "PT-30", option: "Madeira" },
  { id: "MW", option: "Malawi" },
  { id: "MY", option: "Malaysia" },
  { id: "MV", option: "Maldives" },
  { id: "ML", option: "Mali" },
  { id: "MT", option: "Malta" },
  { id: "MH", option: "Marshall Islands" },
  { id: "MQ", option: "Martinique" },
  { id: "MR", option: "Mauritania" },
  { id: "MU", option: "Mauritius" },
  { id: "MX", option: "Mexico" },
  { id: "FM", option: "Micronesia" },
  { id: "MD", option: "Moldova" },
  { id: "MC", option: "Monaco" },
  { id: "MN", option: "Mongolia" },
  { id: "MS", option: "Montserrat" },
  { id: "MA", option: "Morocco" },
  { id: "MZ", option: "Mozambique" },
  { id: "MM", option: "Myanmar" },
  { id: "NA", option: "Namibia" },
  { id: "NR", option: "Nauru" },
  { id: "NP", option: "Nepal" },
  { id: "NL", option: "Netherlands" },
  { id: "NC", option: "New Caledonia" },
  { id: "NZ", option: "New Zealand" },
  { id: "NI", option: "Nicaragua" },
  { id: "NE", option: "Niger" },
  { id: "NG", option: "Nigeria" },
  { id: "KP", option: "North Korea" },
  { id: "GB-NIR", option: "Northern Ireland" },
  { id: "MP", option: "Northern Marianas" },
  { id: "NO", option: "Norway" },
  { id: "OM", option: "Oman" },
  { id: "PK", option: "Pakistan" },
  { id: "PW", option: "Palau" },
  { id: "PS", option: "Palestine" },
  { id: "PA", option: "Panama" },
  { id: "PG", option: "Papua New Guinea" },
  { id: "PY", option: "Paraguay" },
  { id: "PE", option: "Peru" },
  { id: "PH", option: "Philippines" },
  { id: "PL", option: "Poland" },
  { id: "PT", option: "Portugal" },
  { id: "US-PR", option: "Puerto Rico" },
  { id: "QA", option: "Qatar" },
  { id: "RE", option: "Reunion" },
  { id: "RO", option: "Romania" },
  { id: "RU", option: "Russia" },
  { id: "RW", option: "Rwanda" },
  { id: "WS", option: "Samoa" },
  { id: "SM", option: "San Marino" },
  { id: "ST", option: "Sao Tome and Principe" },
  { id: "SA", option: "Saudi Arabia" },
  { id: "GB-SCT", option: "Scotland" },
  { id: "SN", option: "Senegal" },
  { id: "RS", option: "Serbia and Montenegro" },
  { id: "SC", option: "Seychelles" },
  { id: "SL", option: "Sierra Leone" },
  { id: "SG", option: "Singapore" },
  { id: "SK", option: "Slovakia" },
  { id: "SI", option: "Slovenia" },
  { id: "SB", option: "Solomon Islands" },
  { id: "SO", option: "Somalia" },
  { id: "ZA", option: "South Africa" },
  { id: "KR", option: "South Korea" },
  { id: "ES", option: "Spain" },
  { id: "LK", option: "Sri Lanka" },
  { id: "SH-HL", option: "St Helena" },
  { id: "KN", option: "St Kitts and Nevis" },
  { id: "LC", option: "St Lucia" },
  { id: "MF", option: "St Martin" },
  { id: "SD", option: "Sudan" },
  { id: "SR", option: "Suriname" },
  { id: "SZ", option: "Swaziland" },
  { id: "SE", option: "Sweden" },
  { id: "CH", option: "Switzerland" },
  { id: "SY", option: "Syria" },
  { id: "TW", option: "Taiwan" },
  { id: "TJ", option: "Tajikistan" },
  { id: "TZ", option: "Tanzania" },
  { id: "TH", option: "Thailand" },
  { id: "CN-54", option: "Tibet" },
  { id: "TG", option: "Togo" },
  { id: "TO", option: "Tonga" },
  { id: "TT", option: "Trinidad and Tobago" },
  { id: "TN", option: "Tunisia" },
  { id: "TR", option: "Turkey" },
  { id: "TM", option: "Turkmenistan" },
  { id: "TC", option: "Turks and Caicos Islands" },
  { id: "TV", option: "Tuvalu" },
  { id: "UG", option: "Uganda" },
  { id: "UA", option: "Ukraine" },
  { id: "AE", option: "United Arab Emirates" },
  { id: "GB", option: "United Kingdom" },
  { id: "UY", option: "Uruguay" },
  { id: "US", option: "USA" },
  { id: "UZ", option: "Uzbekistan" },
  { id: "VU", option: "Vanuatu" },
  { id: "VE", option: "Venezuela" },
  { id: "VN", option: "Vietnam" },
  { id: "VI", option: "Virgin Islands" },
  { id: "US-UM", option: "Wake Island" },
  { id: "GB-WLS", option: "Wales" },
  { id: "EH", option: "Western Sahara" },
  { id: "YE", option: "Yemen" },
  { id: "ZM", option: "Zambia" },
  { id: "ZW", option: "Zimbabwe" },
] as { id: string; option: string }[]
