import React, { FormEventHandler } from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import InputField from "../../../components/InputField"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import useInputState, { notEmpty } from "../../../hooks/useInputState"

const ButtonView = styled.div`
  margin-top: 1.5em;
`

const AddInsightForm = ({
  onSubmit,
}: {
  onSubmit: (title: string, subtitle: string, description: string) => void
}) => {
  const {
    value: title,
    hasValidValue: titleIsValid,
    errorMessage: titleErrorMessage,
    onChange: onChangeTitle,
    onBlur: onBlurTitle,
  } = useInputState("", [notEmpty("This field is required")])

  const {
    value: subtitle,
    hasValidValue: subtitleIsValid,
    errorMessage: subtitleErrorMessage,
    onChange: onChangeSubtitle,
    onBlur: onBlurSubtitle,
  } = useInputState("", [notEmpty("This field is required")])

  const {
    value: description,
    hasValidValue: descriptionIsValid,
    errorMessage: descriptionErrorMessage,
    onChange: onChangeDescription,
    onBlur: onBlurDescription,
  } = useInputState("", [notEmpty("This field is required")])

  const isEnabled = !titleIsValid || !subtitleIsValid || !descriptionIsValid

  const handleSubmit: FormEventHandler = (ev) => {
    ev.preventDefault()
    onBlurTitle()
    onBlurSubtitle()
    onBlurDescription()
    if (titleIsValid && subtitleIsValid && descriptionIsValid) {
      onSubmit(title.trim(), subtitle.trim(), description.trim())
    }
  }
  return (
    <>
      <Title>{strings.ADD_INSIGHT}</Title>
      <form onSubmit={handleSubmit}>
        <InputField
          type="text"
          fieldName="text"
          labelText="Title"
          value={title}
          onChange={onChangeTitle}
          onBlur={onBlurTitle}
          errorMessage={titleErrorMessage}
          placeholder="Enter Title"
        />
        <InputField
          type="text"
          fieldName="text"
          labelText="Subtitle"
          value={subtitle}
          onChange={onChangeSubtitle}
          onBlur={onBlurSubtitle}
          errorMessage={subtitleErrorMessage}
          placeholder="Enter Subtitle"
        />
        <InputField
          type="text"
          fieldName="text"
          labelText="Description"
          value={description}
          onChange={onChangeDescription}
          onBlur={onBlurDescription}
          errorMessage={descriptionErrorMessage}
          placeholder="Enter Description"
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={isEnabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default AddInsightForm
