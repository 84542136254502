import React from "react"
import { Route, Switch, useParams } from "react-router"
import styled from "styled-components"

import { gql, useQuery } from "@apollo/client"

import { accountPage } from "../../@types/graphql"
import Loader from "../../components/Loader"
import Tabs, { Tab } from "../../components/Tabs"
import AccountProfile from "./AccountProfile"
import Agreements from "./Agreements"
import Suitability from "./Suitability"
import Transactions from "./Transactions"

const TabContentContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 0;
`

export const query = gql`
  query accountPage($id: String!) {
    node(id: $id) {
      id
      __typename
    }
  }
`

const AccountPage = () => {
  const { id } = useParams<{ id: string }>()

  const { loading, error, data } = useQuery<accountPage>(query, {
    variables: { id },
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data || data.node.__typename !== "Account") {
    return <div>Account not found</div>
  }

  return (
    <div data-testid="personPage">
      <AccountProfile />
      <Tabs>
        <Tab to={`/account/${id}/suitability`} label="Suitability" defaultTab />
        <Tab to={`/account/${id}/transactions`} label="Transactions" />
        <Tab to={`/account/${id}/agreements`} label="Agreements" />
      </Tabs>
      <TabContentContainer>
        <Switch>
          <Route path="/account/:id/suitability" component={Suitability} />
          <Route path="/account/:id/transactions" component={Transactions} />
          <Route path="/account/:id/agreements" component={Agreements} />
          <Route path="/account/:id" component={Suitability} />
        </Switch>
      </TabContentContainer>
    </div>
  )
}

export default AccountPage
