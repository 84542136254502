import React, { useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router"
import { useQuery } from "@apollo/client"

import UserField from "../../../components/UserField"
import {
  capitalizeWord,
  formatPhoneNumber,
  formatTag,
  formatTimeDate,
} from "../../../helpers/formatters"
import { detailsQuery, GET_SUBSCRIPTIONS_BY_PERSON_ID_QUERY } from "./query"
import {
  getSubscriptionByPersonId,
  getSubscriptionByPersonIdVariables,
  personDetails,
} from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import Tag from "../../../components/Tag"
import { TagsContainer } from "../../../components/Tag/styles"
import Container from "../../../components/Container"
import Title from "../../../components/Title"
import Button from "../../../components/Button"
import AddRewardModal from "../../Rewards"
import strings from "../../../components/localization/strings"

const MainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 15px 0;
`

const UserId = styled.div`
  padding-bottom: 10px;
`

const SecondaryInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 45px;
    margin-bottom: 15px;
  }
`
const ButtonView = styled.div`
  padding-left: 1em;
  width: 20%;
`
const PersonProfile = () => {
  const { id } = useParams<{ id: string }>()
  const [showInputField, setShowInputField] = useState<boolean>(false)
  const { loading, error, data } = useQuery<personDetails>(detailsQuery, {
    variables: { id },
  })

  const { data: subcriptionPersonId } = useQuery<
    getSubscriptionByPersonId,
    getSubscriptionByPersonIdVariables
  >(GET_SUBSCRIPTIONS_BY_PERSON_ID_QUERY, {
    variables: { personId: id },
  })

  const loggedInUser = localStorage.getItem("adminId")

  let subscriptionTag = "Pay-Schedule-OFF"

  let brokerageTag

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  if (data.node.__typename !== "Person") {
    return <div>User not found</div>
  }

  if (subcriptionPersonId?.getSubscriptionByPersonId?.status) {
    subscriptionTag = "Pay-Schedule-ON"
  }

  if (!data.node.adminTags.includes("ptsb-user") && data.node.token) {
    brokerageTag = "Brokerage"
  }

  let { adminTags } = data.node

  if (brokerageTag) {
    adminTags = [...adminTags, subscriptionTag, brokerageTag]
  } else {
    adminTags = [...adminTags, subscriptionTag]
  }

  const isPtsbUser = data.node.adminTags.includes("ptsb-user")

  const ptsbSellerCode =
    isPtsbUser && data.node.token ? data.node.token : "PTS1"

  const d2cSellerCode =
    !isPtsbUser && data.node.token ? data.node.token : "4899"

  const seller = isPtsbUser ? ptsbSellerCode : d2cSellerCode

  const renderTags = () => {
    if (adminTags.length > 0) {
      return (
        <TagsContainer>
          {adminTags.map((tag: string) => (
            <Tag key={tag} label={formatTag(tag)} />
          ))}
        </TagsContainer>
      )
    }
    return null
  }

  const { first, last } = data.node.name
  const {
    id: personId,
    titles,
    emailAddress,
    mobilePhoneNumber,
    createdAt,
  } = data.node

  return (
    <Container data-testid="PersonProfile">
      <MainInfoWrapper>
        <Title>
          {(titles || []).join(" ")} {capitalizeWord(first)}{" "}
          {capitalizeWord(last)}
        </Title>
        <UserId>{personId}</UserId>
        <p>Seller- {seller}</p>
      </MainInfoWrapper>
      {renderTags()}
      <SecondaryInfoWrapper>
        <UserField fieldName="Email" value={emailAddress || ""} />
        <UserField
          fieldName="Mobile phone"
          value={formatPhoneNumber(mobilePhoneNumber)}
        />
        <UserField
          fieldName="Registration date"
          value={formatTimeDate(createdAt)}
        />
        {showInputField && (
          <AddRewardModal
            onClose={() => {
              setShowInputField(false)
            }}
            parent2Callback={() => {
              setShowInputField(false)
            }}
          />
        )}
        {(loggedInUser === "layal.irish@gmail.com" ||
          loggedInUser === "archatestuser1+mo@gmail.com") && (
          <ButtonView>
            <Button
              type="button"
              text={strings.REWARDS}
              mainAction={false}
              onClick={() => setShowInputField(true)}
              loading={false}
            />
          </ButtonView>
        )}
      </SecondaryInfoWrapper>
    </Container>
  )
}

export default PersonProfile
