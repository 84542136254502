import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const GET_PERSON_DOCUMENTS = gql`
  query getPersonDocuments($id: String!) {
    node(id: $id) {
      id
      __typename
      ... on Person {
        id
        createdAt
        accounts {
          items {
            account {
              backOfficeAccount {
                backOfficeId
                status
              }
            }
          }
        }
        documents {
          items {
            id
            filename
            contentType
            tags
            url
            createdAt
            createdBy
          }
        }
      }
    }
  }
`
