export interface DecodedUserToken {
  tenant: string
  email: string
  mfa?: "App" | "SMS" | null
  role: string
  iat: number
  exp: number
  iss: string
  sub: string
  sid?: string
  drc?: number
}

export interface DecodedDeviceToken {
  drc: number
  sid?: string
  iat: number
  exp: number
  sub: string
}

export function decodeToken(
  token: string | null
): DecodedUserToken | DecodedDeviceToken | null {
  if (!token) {
    return null
  }

  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}
