import React, { ReactNode, useState } from "react"
import { CgClose } from "react-icons/cg"
import styled from "styled-components"
import ConfirmSustainabilityData from "./confirmSustainabiltyData"
import AddSustainability from "./addSustainabilityData"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #20200020;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBox = styled.div`
  position: relative;
  padding: 50px;
  max-width: 30%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 15px;
`

enum AddSustainabilityData {
  Form,
  Confirm,
  Error,
}

const ModalCard = ({
  onClose,
  children,
}: {
  onClose: () => void
  children: ReactNode
}) => {
  return (
    <ModalBox
      data-testid="modalCard"
      onClick={(ev) => {
        ev.stopPropagation()
      }}
    >
      <CloseButton onClick={() => onClose()}>
        <CgClose size={24} />
      </CloseButton>
      {children}
    </ModalBox>
  )
}

const AddNewSustainabilityModal = ({
  onClose,
  parent2Callback,
}: {
  onClose: () => void
  parent2Callback: () => void
}) => {
  const [step, setStep] = useState<AddSustainabilityData>(
    AddSustainabilityData.Form
  )
  const [data, setData] = useState<{
    clientFund: string
    title: string
    fund: string
    marketBenchmark: string
    error: string
  }>({
    clientFund: "",
    title: "",
    fund: "",
    marketBenchmark: "",
    error: "",
  })

  return (
    <Overlay onClick={() => setStep(AddSustainabilityData.Form)}>
      <ModalCard onClose={onClose}>
        {step === AddSustainabilityData.Form && (
          <AddSustainability
            onSubmit={(clientFund, title, fund, marketBenchmark) => {
              setData((prev) => ({
                ...prev,
                clientFund,
                title,
                fund,
                marketBenchmark,
              }))
              setStep(AddSustainabilityData.Confirm)
            }}
          />
        )}
        {step === AddSustainabilityData.Confirm && (
          <ConfirmSustainabilityData
            clientFund={data.clientFund}
            title={data.title}
            fund={data.fund}
            marketBenchmark={data.marketBenchmark}
            onCancel={() => setStep(AddSustainabilityData.Form)}
            onError={(error) => {
              setData((prev) => ({ ...prev, error }))
              setStep(AddSustainabilityData.Error)
            }}
            onSuccess={() => onClose()}
            parentCallback={() => parent2Callback()}
          />
        )}
        {step === AddSustainabilityData.Error && `API Error: ${data.error}`}
      </ModalCard>
    </Overlay>
  )
}

export default AddNewSustainabilityModal
