import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import {
  updateSustainability,
  updateSustainabilityVariables,
} from "../../../@types/graphql"
import {
  FETCH_SUSTAINABILITY_DATA,
  UPDATE_SUSTAINABILITY_DATA,
} from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmUpdateSustainability = ({
  sustainabilityId,
  clientfund,
  title,
  fund,
  marketBenchmark,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  sustainabilityId: string
  clientfund: string
  title: string
  fund: number | null
  marketBenchmark: number | null
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [
    updateSustainabilityMutation,
    { loading: updatingSustainability },
  ] = useMutation<updateSustainability, updateSustainabilityVariables>(
    UPDATE_SUSTAINABILITY_DATA,
    {
      refetchQueries: [
        {
          query: FETCH_SUSTAINABILITY_DATA,
        },
      ],
    }
  )

  const handleSubmit = async (): Promise<void> => {
    const sustainabilityIdValue = sustainabilityId?.trim()
    const fundValue = fund === null ? 0 : fund
    const marketBenchmarkValue = marketBenchmark === null ? 0 : marketBenchmark
    const { data: updatingShareData } = await updateSustainabilityMutation({
      variables: {
        id: sustainabilityIdValue,
        fund: fundValue,
        market_benchmark: marketBenchmarkValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!updatingShareData) {
      return
    }
    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_SUSTAINABILITY}</Title>
      {clientfund && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.CLIENT_FUND}
          <br />
          {clientfund}
        </Paragraph>
      )}

      {title && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.SUSTAINABILITY_TYPE}
          <br />
          {title}
        </Paragraph>
      )}
      {fund !== 0 && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.FUND}
          <br />
          {fund}
        </Paragraph>
      )}
      {marketBenchmark !== 0 && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.MARKET_BENCHMARK}
          <br />
          {marketBenchmark}
        </Paragraph>
      )}
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={updatingSustainability}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={updatingSustainability}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmUpdateSustainability
