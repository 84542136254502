import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router"
import {
  AuthStatus,
  getCurrentStatus,
  subscribeToStatus,
  unsubscribeToStatus,
} from "../auth"
import Header from "../components/Header"
import AccountPage from "./Account"
import AdminPage from "./Admin"
import DashboardPage from "./Dashboard"
import LoginPage from "./Login"
import NotFoundPage from "./NotFound"
import PersonPage from "./Person"
import SearchPage from "./SearchPage"
import AcceptInvitation from "./AcceptInvitation"
import LoginsHistory from "./LoginsHistory"
import useFeatureConfig from "../hooks/useFeatureConfig"
import MarketInsights from "./MarketInsight"
import FundFactSheet from "./FundFactSheets"
import ShareHoldings from "./Top10ShareHolding"
import SustainabilityScreen from "./Sustainability"

const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    getCurrentStatus() === "Authenticated"
  )
  const [hasAccessToAdminManagement] = useFeatureConfig(["AdminManagement"])
  useEffect(() => {
    function handleStatusChange(newStatus: AuthStatus) {
      setIsAuthenticated(newStatus === "Authenticated")
    }
    subscribeToStatus(handleStatusChange)
    return () => unsubscribeToStatus(handleStatusChange)
  }, [])
  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/accept-invitation" component={AcceptInvitation} />
        <Route path="/" component={LoginPage} />
      </Switch>
    )
  }
  return (
    <Switch>
      {/* Headless routing here */}
      <Route path="/accept-invitation" component={AcceptInvitation} />
      <Route
        component={() => {
          return (
            <>
              <Header />
              <Switch>
                {/* Here routing with header */}
                <Route path="/" exact component={DashboardPage} />
                <Route path="/person/:id" component={PersonPage} />
                <Route path="/account/:id" component={AccountPage} />
                <Route path="/search" component={SearchPage} />
                {hasAccessToAdminManagement && (
                  <Route path="/admin" component={AdminPage} />
                )}
                {hasAccessToAdminManagement && (
                  <Route path="/logins-history" component={LoginsHistory} />
                )}
                {hasAccessToAdminManagement && (
                  <Route path="/market-insights" component={MarketInsights} />
                )}
                {hasAccessToAdminManagement && (
                  <Route path="/fund-factsheets" component={FundFactSheet} />
                )}
                {hasAccessToAdminManagement && (
                  <Route
                    path="/sustainability"
                    component={SustainabilityScreen}
                  />
                )}
                {hasAccessToAdminManagement && (
                  <Route
                    path="/top10-shareholdings"
                    component={ShareHoldings}
                  />
                )}
                <Route component={NotFoundPage} />
              </Switch>
            </>
          )
        }}
      />
    </Switch>
  )
}
export default Routes
