import styled from "styled-components"

export const SectionTitle = styled.p`
  line-height: 1.3em;
  margin: 0;
  padding-bottom: 10px;
  font-size: ${(props) => props.theme.headingFontSize};
  font-weight: bolder;
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
`

export const ColumnView = styled.div`
  width: 45%;
  margin: 10px;
  padding: 10px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  align-self: flex-start;
`

export const ColumnViewWrapper = styled.div`
  width: 45%;
  padding: 10px;
`

export const InnerColumnView = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

export const StyledUserField = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`

export const UserFieldName = styled.div`
  width: 35%;
  font-size: ${(props) => props.theme.fontSize};
  color: grey;
`

export const EmptyValue = styled.div`
  font-style: italic;
  color: ${({ theme }) => theme.unfocused};
  font-size: 0.8em;
`

export const UserFieldValue = styled.div`
  font-size: ${(props) => props.theme.fontSize};
`
