import React, { FormEventHandler } from "react"
import styled from "styled-components"
import Title from "../../../components/Title"
import InputField from "../../../components/InputField"
import SelectField from "../../../components/SelectField"
import useInputState, {
  matchRegex,
  notEmpty,
} from "../../../hooks/useInputState"
import Button from "../../../components/Button"
import { TenantRole } from "../../../@types/graphql"

const ButtonView = styled.div`
  margin-top: 1.5em;
`

const InviteForm = ({
  onSubmit,
}: {
  onSubmit: (email: string, role: TenantRole) => void
}) => {
  const {
    value: email,
    hasValidValue: emailIsValid,
    errorMessage: emailErrorMessage,
    onChange: onChangeEmail,
    onBlur: onBlurEmail,
  } = useInputState("", [
    notEmpty("This field is required"),
    matchRegex("Invalid email", /^[a-z][a-z0-9.\-+']+@[a-z1-9.\-+]+$/i),
  ])

  const {
    value: role,
    hasValidValue: roleIsValid,
    errorMessage: roleErrorMessage,
    onChange: onChangeRole,
    onBlur: onBlurRole,
  } = useInputState("", [notEmpty("This field is required")])

  const handleSubmit: FormEventHandler = (ev) => {
    ev.preventDefault()
    onBlurEmail()
    onBlurRole()
    if (emailIsValid && roleIsValid) {
      onSubmit(email.trim(), role as TenantRole)
    }
  }
  return (
    <>
      <Title>Invite new user to Middle Office</Title>
      <form onSubmit={handleSubmit}>
        <InputField
          type="email"
          fieldName="email"
          labelText="Email"
          value={email}
          onChange={onChangeEmail}
          onBlur={onBlurEmail}
          errorMessage={emailErrorMessage}
          placeholder="Enter user’s email address"
        />
        <SelectField
          fieldName="role"
          labelText="Role"
          value={role}
          onChange={onChangeRole}
          onBlur={onBlurRole}
          errorMessage={roleErrorMessage}
          options={[
            { id: "", value: "Select user’s role" },
            { id: "ReadOnlyAdmin", value: "Agent" },
            { id: "Admin", value: "Admin" },
          ]}
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={!emailIsValid || !roleIsValid}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default InviteForm
