import React, { FormEvent, useState } from "react"
import styled from "styled-components"
import { Link, useHistory } from "react-router-dom"
import SearchInput from "../SearchInput"
import { clearUserToken } from "../../auth"
import useFeatureConfig from "../../hooks/useFeatureConfig"

const StyledHeader = styled.div`
  background-color: ${(props) => props.theme.primary};
  font-size: ${(props) => props.theme.fontSize};
`

const HeaderContent = styled.div`
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  align-items: center;
`

const SearchForm = styled.form`
  flex: 1 1;
  margin: 15px 15px 15px 0;
`
const SecondaryAction = styled.div`
  padding-left: 70px;
  color: ${(props) => props.theme.typographyInverse};
  a {
    color: ${(props) => props.theme.typographyInverse};
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  img {
    width: 50%;
  }
  cursor: pointer;
`

const Header = () => {
  const [searchPeopleQuery, setSearchPeopleQuery] = useState("")
  const history = useHistory()
  const [hasAccessToAdminManagement] = useFeatureConfig(["AdminManagement"])

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    history.push(`/search?query=${encodeURIComponent(searchPeopleQuery)}`)
  }

  const handleSignOut = (): void => {
    localStorage.removeItem("adminId")
    clearUserToken()
  }

  return (
    <StyledHeader data-testid="header">
      <HeaderContent>
        <ItemContainer data-testid="logo" onClick={() => history.push("/")}>
          <img
            // eslint-disable-next-line global-require
            src={require("../../resources/smart-invest.png")}
            alt="smart-invest-logo"
          />
        </ItemContainer>
        <SearchForm onSubmit={handleSubmit}>
          <SearchInput
            fieldName="search"
            onChange={(event) => setSearchPeopleQuery(event.target.value || "")}
            value={searchPeopleQuery}
          />
        </SearchForm>
        {hasAccessToAdminManagement && (
          <SecondaryAction data-testid="adminAction">
            <Link to="/admin" style={{ textDecoration: "none" }}>
              Admin
            </Link>
          </SecondaryAction>
        )}
        <SecondaryAction data-testid="loggedInUser">
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={handleSignOut}
          >
            Sign out
          </Link>
        </SecondaryAction>
      </HeaderContent>
    </StyledHeader>
  )
}

export default Header
