import React, { useState, FormEvent, FocusEvent, SetStateAction } from "react"
import { gql, useMutation } from "@apollo/client"

import InputField from "../../components/InputField"
import Button from "../../components/Button"
import validateField from "./helper"

import { LoginForm, ErrorMessage, WelcomeText, ButtonView } from "./styles"

import { clearUserToken, setUserToken } from "../../auth"

export const signInMutation = gql`
  mutation signIn($email: String!, $password: String!) {
    signInWithEmailAddress(
      input: { emailAddress: $email, password: $password }
    ) {
      userToken
    }
  }
`
const SignInForm = ({
  emailAddress,
  password,
  setEmailAddress,
  setPassword,
}: {
  emailAddress: string
  password: string
  setEmailAddress: (value: string) => void
  setPassword: (value: string) => void
}) => {
  const [errorMessages, setErrorMessages] = useState([])
  const [errorFieldMessages, setErrorFieldMessages] = useState({
    email: "",
    password: "",
  } as { [key: string]: string })
  const [submitting, setSubmitting] = useState(false)

  const [signIn] = useMutation(signInMutation)

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    validateField(event.target.name, event.target.value, setErrorFieldMessages)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const emailAddressIsEmpty = validateField(
      "email",
      emailAddress,
      setErrorFieldMessages
    )
    const passwordIsEmpty = validateField(
      "password",
      password,
      setErrorFieldMessages
    )

    if (emailAddressIsEmpty || passwordIsEmpty) {
      return Promise.resolve()
    }

    setErrorMessages([])
    setSubmitting(true)

    clearUserToken()
    return signIn({
      variables: {
        email: emailAddress.trim(),
        password: password.trim(),
      },
    })
      .then(
        ({
          data: {
            signInWithEmailAddress: { userToken },
          },
        }) => {
          setUserToken(userToken)
          localStorage.setItem("adminId", emailAddress.trim())
        }
      )
      .catch((error) => {
        setSubmitting(false)
        const messages = error.graphQLErrors.map(
          ({ message }: { message: string }) => message
        )
        if (
          messages[0] === "Invalid Credentials" ||
          messages[0] === "Not Found"
        ) {
          setErrorMessages(["Invalid email or password"] as SetStateAction<
            never[]
          >)
        } else if (messages[0] === "Validation error") {
          setErrorMessages(["Invalid email"] as SetStateAction<never[]>)
        } else {
          setErrorMessages(messages)
        }
      })
  }

  return (
    <LoginForm data-testid="signInForm" onSubmit={handleSubmit}>
      <WelcomeText>Login to Middle Office</WelcomeText>
      {errorMessages && <ErrorMessage>{errorMessages}</ErrorMessage>}
      <>
        <InputField
          type="email"
          fieldName="email"
          labelText="Email"
          value={emailAddress}
          onChange={(event) => setEmailAddress(event.target.value.trim())}
          onBlur={handleBlur}
          errorMessage={errorFieldMessages.email}
        />
        <InputField
          type="password"
          fieldName="password"
          labelText="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value.trim())}
          onBlur={handleBlur}
          errorMessage={errorFieldMessages.password}
        />
      </>
      <ButtonView>
        <Button type="submit" text="Sign in" loading={submitting} />
      </ButtonView>
    </LoginForm>
  )
}

export default SignInForm
