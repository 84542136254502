import React, { FC } from "react"

import { useQuery } from "@apollo/client"

import { getAdminsData } from "../../@types/graphql"
import Loader from "../../components/Loader"
import Table, { Row } from "../../components/Table"
import { formatTimeDate, formatUserAgent } from "../../helpers/formatters"
import useFeatureConfig from "../../hooks/useFeatureConfig"
import { ADMIN_PAGE_QUERY } from "../Admin/index"
import { Wrapper } from "../Dashboard/styles"

export const DEFAULT_HEADINGS = [
  "Email",
  "Role",
  "Login date",
  "Device",
  "IP address",
]

const LoginsHistory: FC = () => {
  const { loading, error, data } = useQuery<getAdminsData>(ADMIN_PAGE_QUERY)
  const [hideIPAddress] = useFeatureConfig(["HideIPAddress"])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  const adminUsers = data?.tenant?.people?.items

  const headings = hideIPAddress
    ? DEFAULT_HEADINGS.filter((h) => h !== "IP address")
    : DEFAULT_HEADINGS

  const rows = adminUsers?.map(({ emailAddress, role, signIns }) => {
    return signIns.items.map(({ createdAt, userAgent, ipAddress }) => {
      const rowKey = hideIPAddress
        ? `${emailAddress}, ${createdAt}, ${userAgent}`
        : `${emailAddress}, ${createdAt}, ${userAgent}, ${ipAddress}`

      const values = hideIPAddress
        ? [
            emailAddress,
            role,
            formatTimeDate(createdAt),
            formatUserAgent(userAgent),
          ]
        : [
            emailAddress,
            role,
            formatTimeDate(createdAt),
            formatUserAgent(userAgent),
            ipAddress || "",
          ]

      return <Row key={rowKey} values={values} />
    })
  })

  return (
    <Wrapper data-testid="loginsHistory">
      <Table columnNames={headings} isEmpty={rows?.length === 0}>
        {rows}
      </Table>
    </Wrapper>
  )
}

export default LoginsHistory
