import styled from "styled-components"

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  color: black;
  padding: 30px;
  border-radius: 5px;
  width: 20em;
`

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.error};
  margin-bottom: 0.5em;
`

export const Page = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.typographyInverse};
  background-color: ${(props) => props.theme.primaryDark};
  height: 100vh;
`

export const LogoBox = styled.div`
  width: 0;
  height: 0;
  position: relative;
  & img {
    height: 100px;
    transform: translate(-50%, calc(-100% - 30px));
  }
`

export const WelcomeText = styled.h3`
  font-weight: ${({ theme }): string => theme.fontBold};
  font-family: ${({ theme }): string => theme.font};
  font-size: ${({ theme }): string => theme.titleFontSize};
  color: ${({ theme }): string => theme.secondaryAction};
  margin: 0;
  padding-bottom: 0.7em;
  padding-right: 1.5em;
`

export const ButtonView = styled.div`
  align-self: center;
  padding-top: 1em;
  width: 40%;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 2.3em 1em 0;
`

export const Checkbox = styled.input`
  margin: 0 0.5em 0 0;
`

export const CheckboxText = styled.label`
  font-family: ${({ theme }): string => theme.font};
  font-size: 0.8em;
  color: ${({ theme }): string => theme.secondaryAction};
  margin: 0;
`
