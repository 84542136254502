import { useQuery } from "@apollo/client"
import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import Loader from "../../../components/Loader"
import Table, { Row } from "../../../components/Table"
import { GET_PERSON_DOCUMENTS } from "./query"
import { getPersonDocuments } from "../../../@types/graphql"
import { formatTag, formatTimeDate } from "../../../helpers/formatters"
import useFeatureConfig from "../../../hooks/useFeatureConfig"

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 30px auto;
`

export const headings = ["Document", "Type", "Date created", "Source"]

function getDocumentTypeFromTags(tags: string[]): string {
  return tags
    .filter(
      (t) => t !== "no-upload" && t !== "uploaded" && !t.startsWith("date:")
    )
    .map(formatTag)
    .join(", ")
}

const Documents = () => {
  const { id } = useParams<{ id: string }>()
  const [tagAsDocumentFilename] = useFeatureConfig(["TagAsDocumentFilename"])
  const { loading, error, data } = useQuery<getPersonDocuments>(
    GET_PERSON_DOCUMENTS,
    {
      variables: { id },
    }
  )

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  if (data.node.__typename !== "Person") {
    return <div>User not found</div>
  }

  //   Product Change Document
  // This is a once off change to manually display this document in the middle office
  const person = data?.node

  const accountStatus = person?.accounts?.items[0]?.account?.backOfficeAccount?.status.toString()

  const isOldUser = new Date(person.createdAt) < new Date("2022-02-13")

  const [...documentData] = data?.node?.documents?.items

  if (isOldUser && (accountStatus === "Open" || accountStatus === "Pending")) {
    documentData.push({
      contentType: "PDF",
      createdAt: "2022-02-14T13:00:48.355Z",
      createdBy: "person-0vuu96mZYCe",
      filename: null,
      id: "doc-0123",
      tags: ["no-upload", "Product Change Notification"],
      url:
        "https://smartinvest.irishlife.ie/pdfs/Product-Change-Notification.pdf",
      __typename: "Document",
    })
  }

  const sortedData = documentData?.slice().sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  )

  //  End Product Change Document

  const rows = sortedData.map(
    ({ id: docId, filename, tags, contentType, createdAt, url, createdBy }) => {
      const docSource = createdBy === data.node.id ? "User" : "System"
      const document = tagAsDocumentFilename
        ? getDocumentTypeFromTags(tags)
        : filename
      const formattedDocType = contentType
        .substring(contentType.indexOf("/") + 1)
        .toUpperCase()

      return (
        <Row
          key={docId}
          values={[
            document || "Unknown",
            formattedDocType,
            formatTimeDate(createdAt),
            docSource,
          ]}
          onClick={() => window.open(url, "_blank")}
        />
      )
    }
  )

  return (
    <Wrapper data-testid="documents">
      <Table columnNames={headings} isEmpty={rows.length === 0}>
        {rows}
      </Table>
    </Wrapper>
  )
}

export default Documents
