import styled from "styled-components"

export type AttributeListProps = {
  orientation?: "vertical" | "horizontal"
  wide?: boolean
  compressed?: boolean
}

const AttributeList = styled.ul<AttributeListProps>`
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: ${(props) =>
    props.orientation === "horizontal" ? "column" : "row"};

  > li {
    display: flex;
    font-size: ${({ theme }) => theme.fontSize};
    flex-direction: ${(props) =>
      props.orientation === "horizontal" ? "row" : "column"};

    > h4 {
      color: ${({ theme }) => `${theme.disabled}`};
      font-family: ${({ theme }) => theme.font};
      margin: ${(props) =>
        props.orientation === "horizontal"
          ? `${props.compressed === true ? "2px" : "8px"} 0px`
          : "2px 0px"};
      width: ${({ wide }) => (wide === true ? "500px" : "140px")};
    }

    > p,
    a {
      margin: ${(props) =>
        props.orientation === "horizontal"
          ? `${props.compressed === true ? "2px" : "8px"} 0px 0px ${
              props.wide === true ? "15px" : "0px"
            }`
          : "2px 0px"};
      font-family: ${({ theme }) => theme.font};
    }
  }
`

export default AttributeList
