import { gql, MutationTuple, useMutation } from "@apollo/client"
import {
  UpdateDepositSourceOfFundsMutation,
  UpdateDepositSourceOfFundsMutationVariables,
} from "../../../@types/graphql"

export const UPDATE_DEPOSIT_SOURCE_OF_FUNDS_MUTATION = gql`
  mutation UpdateDepositSourceOfFundsMutation(
    $depositId: String!
    $depositVersion: Int!
    $sourceOfFunds: String!
    $paymentIntentId: String!
    $externalIdStripe: String!
    $amount: Decimal!
  ) {
    updatePendingDeposit(
      input: {
        depositId: $depositId
        version: $depositVersion
        sourceOfFunds: $sourceOfFunds
        paymentIntentId: $paymentIntentId
        externalId: $externalIdStripe
        value: { amount: $amount, currency: "EUR" }
      }
    ) {
      id
      version
      value {
        amount
      }
      account {
        id
        positionVersion
      }
      sourceOfFunds
    }
  }
`
export default (): MutationTuple<
  UpdateDepositSourceOfFundsMutation,
  UpdateDepositSourceOfFundsMutationVariables
> => useMutation(UPDATE_DEPOSIT_SOURCE_OF_FUNDS_MUTATION)
