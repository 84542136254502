import React, { FormEventHandler, useState } from "react"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { ButtonView } from "../../Admin/InviteAdminModal/styles"
import Button from "../../../components/Button"
import useInputState, {
  maxGraphValue,
  notEmpty,
} from "../../../hooks/useInputState"
import InputFieldNew from "../../FundFactSheets/AddNewFundFact/InputField"
import { capitalizeFirst } from "../../FundFactSheets/AddNewFundFact/constants"

const UpdateSustainability = ({
  clientFund,
  title,
  fund,
  marketBenchmark,
  onSubmit,
}: {
  onSubmit: (
    titleEdit: string,
    fundEdit: number,
    marketBenchmarkEdit: number
  ) => void
  sustainabilityId: string
  clientFund: string
  title: string
  fund: number
  marketBenchmark: number
}) => {
  const [stateClientFund] = useState<{
    clientFund: string
  }>({
    clientFund,
  })

  const [stateTitle] = useState<{
    title: string
  }>({
    title,
  })

  const [stateFund] = useState<{
    fund: number
  }>({
    fund,
  })
  const [stateMarketBenchmark] = useState<{
    marketBenchmark: number
  }>({
    marketBenchmark,
  })
  const clientFundValue = stateClientFund?.clientFund
  const titleValue = stateTitle.title
  const fundValue = stateFund?.fund === null ? 0 : stateFund?.fund
  const marketBenchmarkValue =
    stateMarketBenchmark?.marketBenchmark === null
      ? 0
      : stateMarketBenchmark?.marketBenchmark
  const titleField = useInputState(titleValue, [notEmpty("Title is required")])
  const fundField = useInputState(fundValue.toString(), [
    maxGraphValue("Must be a valid number"),
  ])
  const marketBenchmarkField = useInputState(marketBenchmarkValue.toString(), [
    maxGraphValue("Must be a valid number"),
  ])
  const validTitle = titleField?.hasValidValue
  const validFund = fundField?.hasValidValue
  const validMarketBenchmark = marketBenchmarkField?.hasValidValue
  const isDisabled = !(validTitle && validFund && validMarketBenchmark)
  const handleSubmit: FormEventHandler = async (ev): Promise<void> => {
    ev.preventDefault()
    onSubmit(
      capitalizeFirst(titleField?.value),
      parseFloat(fundField?.value),
      parseFloat(marketBenchmarkField?.value)
    )
  }

  return (
    <>
      <Title>{strings.EDIT_SUSTAINABILITY}</Title>
      <form onSubmit={handleSubmit}>
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Client Fund"
          value={clientFundValue}
          onChange={() => {}}
          onBlur={() => {}}
          errorMessage=""
          disabled
          placeholder=""
        />
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Title"
          value={titleField?.value}
          onChange={() => {}}
          onBlur={() => {}}
          errorMessage=""
          disabled
          placeholder=""
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Fund"
          value={fundField?.value}
          onChange={fundField?.onChange}
          onBlur={fundField?.onBlur}
          errorMessage={fundField?.errorMessage}
          placeholder=""
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Market Benchmark"
          value={marketBenchmarkField?.value}
          onChange={marketBenchmarkField?.onChange}
          onBlur={marketBenchmarkField?.onBlur}
          errorMessage={marketBenchmarkField?.errorMessage}
          placeholder=""
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={isDisabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default UpdateSustainability
