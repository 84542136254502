import React from "react"
import styled from "styled-components"
import CommunicationPreferences from "./CommunicationPreferences"
import CommunicationHistory from "./CommunicationHistory"

const Wrapper = styled.div``

const Communications = () => {
  return (
    <Wrapper data-testid="communications">
      <CommunicationPreferences />
      <CommunicationHistory />
    </Wrapper>
  )
}

export default Communications
