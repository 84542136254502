import React from "react"
import styled from "styled-components"

import Spinner from "../Spinner"

const StyledButton = styled.button`
  width: 100%;
  padding: 0.75em;
  border-radius: 2em;
  border: 0;
  font-weight: bold;
  font-size: 0.8em;
`

const PrimaryActionButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.disabled ? "#BDBDBD" : props.theme.primaryAction};
  color: ${(props) => props.theme.typographyInverse};

  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }
`

const SecondaryActionButton = styled(StyledButton)`
  background-color: ${(props) => (props.disabled ? "#EEE" : "white")};
  color: ${(props) => (props.disabled ? "#666" : props.theme.typography)};
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow};
  border: ${({ theme }) => `1px solid ${theme.unfocused}`};
  cursor: pointer;
`

const Button = ({
  type,
  text,
  onClick = () => {},
  loading,
  disabled = false,
  mainAction = true,
}: {
  type: "submit" | "button"
  text: string
  onClick?: () => void
  loading: boolean
  disabled?: boolean
  mainAction?: boolean
}) => {
  const MyButton = mainAction ? PrimaryActionButton : SecondaryActionButton
  return (
    <MyButton
      data-testid="button"
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? <Spinner /> : text}
    </MyButton>
  )
}

export default Button
