import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { gql, useQuery } from "@apollo/client"

import { communicationHistory } from "../../../@types/graphql"
import Table, { Row } from "../../../components/Table"
import Title from "../../../components/Title"
import { formatTimeDate } from "../../../helpers/formatters"

export const comunicationHistoryQuery = gql`
  query communicationHistory($personId: String!) {
    node(id: $personId) {
      __typename
      ... on Person {
        id
        communications {
          items {
            id
            method
            content
            sentAt
            deliveredAt
            rejectedAt
            rejectionCause
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`

const CommunicationHistory = () => {
  const { id } = useParams<{ id: string }>()

  const { loading, error, data } = useQuery<communicationHistory>(
    comunicationHistoryQuery,
    {
      variables: { personId: id },
    }
  )

  if (loading) {
    return null
  }

  if (error || !data || data.node.__typename !== "Person") {
    return <Wrapper>Error</Wrapper>
  }

  const rows = data.node.communications.items.map(
    ({ id: commId, method, content, sentAt, deliveredAt, rejectedAt }) => {
      let status = "Sent"
      if (deliveredAt) status = "Delivered"
      if (rejectedAt) status = "Rejected"

      return (
        <Row
          key={commId}
          values={[
            method,
            content,
            formatTimeDate(rejectedAt || deliveredAt || sentAt),
            status,
          ]}
        />
      )
    }
  )
  return (
    <Wrapper>
      <Title>Communication history</Title>
      <Table
        columnNames={["Method", "Type", "Date", "Status"]}
        isEmpty={rows.length === 0}
      >
        {rows}
      </Table>
    </Wrapper>
  )
}

export default CommunicationHistory
