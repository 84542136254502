import React, { MouseEventHandler, ReactNodeArray, ReactNode } from "react"
import styled from "styled-components"

const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 1080px;
  width: 100%;
  margin: 30px auto 0;
  font-size: 0.8em;
`

const StyledHeader = styled.th`
  background-color: #e5e5e5;
  text-align: left;
  padding: 1em;
`

const StyledTableData = styled.td`
  padding: 1em;
`

const StyledRow = styled.tr`
  border-bottom: 1px solid #e5e5e5;

  ${(props) =>
    props.onClick
      ? `
    &:hover {
      background-color: #e5e5e588;
      cursor: pointer;
    }
  `
      : ""}
`

export const Row = ({
  onClick,
  values,
}: {
  onClick?: MouseEventHandler
  values: string[]
}) => {
  return (
    <StyledRow onClick={onClick}>
      {values.map((value, index) => (
        // This is one of the documented cases where use index as key is harmless.
        // More info can be found under "When not to use it" in the official react/no-array-index-key documentation.
        // eslint-disable-next-line react/no-array-index-key
        <StyledTableData key={index}>{value}</StyledTableData>
      ))}
    </StyledRow>
  )
}

export const NoResultsRow = ({
  colSpan,
  message,
}: {
  colSpan: number
  message: string
}) => {
  return (
    <StyledRow>
      <StyledTableData colSpan={colSpan}>{message}</StyledTableData>
    </StyledRow>
  )
}
const Table = ({
  columnNames,
  children,
  isEmpty,
  noResultMessage = "No results found",
}: {
  columnNames: string[]
  children?: ReactNode | ReactNodeArray
  isEmpty: boolean
  noResultMessage?: string
}) => {
  return (
    <StyledTable>
      <thead>
        <StyledRow>
          {columnNames.map((column) => (
            <StyledHeader key={column}>{column}</StyledHeader>
          ))}
        </StyledRow>
      </thead>
      <tbody>
        {isEmpty ? (
          <NoResultsRow
            colSpan={columnNames.length}
            message={noResultMessage}
          />
        ) : (
          children
        )}
      </tbody>
    </StyledTable>
  )
}

export default Table
