import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import {
  updateShareHolding,
  updateShareHoldingVariables,
} from "../../../@types/graphql"
import { FETCH_SHARE_HOLDINGS, UPDATE_SHARE_HOLDING } from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmUpdateShare = ({
  fundId,
  clientfund,
  title,
  percentage,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  fundId: string
  clientfund: string
  title: string
  percentage: number | null
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [updateShareMutation, { loading: updatingShare }] = useMutation<
    updateShareHolding,
    updateShareHoldingVariables
  >(UPDATE_SHARE_HOLDING, {
    refetchQueries: [
      {
        query: FETCH_SHARE_HOLDINGS,
      },
    ],
  })

  const handleSubmit = async (): Promise<void> => {
    const fundIdValue = fundId?.trim()
    const titleValue = title?.trim()
    const percentageValue = percentage === null ? 0 : percentage
    const { data: updatingShareData } = await updateShareMutation({
      variables: {
        id: fundIdValue,
        title: titleValue,
        percentage: percentageValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!updatingShareData) {
      return
    }
    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_FACTSHEET}</Title>
      {clientfund && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.CLIENT_FUND}
          <br />
          {clientfund}
        </Paragraph>
      )}

      {title && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.CONFIRM_TITLE}
          <br />
          {title}
        </Paragraph>
      )}
      {percentage !== 0 && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.PERCENTAGE}
          <br />
          {percentage}
        </Paragraph>
      )}
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={updatingShare}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={updatingShare}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmUpdateShare
