import React from "react"
import styled from "styled-components"

const StyledUserField = styled.div`
  padding-bottom: 10px;
`

const UserFieldName = styled.div`
  font-size: 1em;
`

const EmptyValue = styled.div`
  font-style: italic;
  border: ${({ theme }) => `1px solid ${theme.unfocused}`};
  font-size: 0.8em;
`

const UserFieldValue = styled.div``

const UserField = ({
  fieldName,
  value,
}: {
  fieldName: string
  value: string
}) => {
  const isEmpty = !value || value.trim() === ""
  return (
    <StyledUserField>
      <UserFieldName>{fieldName}</UserFieldName>
      {!isEmpty ? (
        <UserFieldValue>{value}</UserFieldValue>
      ) : (
        <EmptyValue>empty</EmptyValue>
      )}
    </StyledUserField>
  )
}

export default UserField
