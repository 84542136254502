import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useQuery, useMutation } from "@apollo/client"
import { uuid } from "uuidv4"
import { useParams } from "react-router"
import Title from "../../components/Title"
import strings from "../../components/localization/strings"
import { Paragraph } from "../Dashboard/styles"
import Button from "../../components/Button"
import {
  SendDepositMutation,
  SendDepositMutationVariables,
  TopUpAmountDataQuery,
  TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits_items,
} from "../../@types/graphql"
import { SEND_DEPOSIT_MUTATION } from "./mutation"
import { TOP_UP_AMOUNT_DATA_QUERY } from "./queries"
import useCreateDepositMutation from "./use-create-deposit-mutation"
import useUpdateDepositSourceOfFundsMutation from "./use-update-deposit-source-of-funds-mutation"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmRewardAmount = ({
  title,
  description,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  title: string
  description: string
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [paymentIntentId, setPaymentIntentId] = useState("")
  const [depositsID, setDepositsID] = useState("")
  const [amountState, setAmountState] = useState("")
  const [mutationRunning, setMutationRunning] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { data: topUpData, refetch } = useQuery<TopUpAmountDataQuery>(
    TOP_UP_AMOUNT_DATA_QUERY,
    {
      variables: { id },
      fetchPolicy: "network-only",
    }
  )

  const [runCreateDepositMutation] = useCreateDepositMutation()
  const [
    updatePendingDepositSourceOfFunds,
  ] = useUpdateDepositSourceOfFundsMutation()
  const [sendDepositMutation] = useMutation<
    SendDepositMutation,
    SendDepositMutationVariables
  >(SEND_DEPOSIT_MUTATION)

  const person = topUpData?.node
  const account = person?.accounts.items[0]?.account
  const handleNextPress = async (amountVal: string) => {
    setAmountState(String(amountVal))
    try {
      if (paymentIntentId) {
        // If no deposit exists, create deposit.
        setMutationRunning(true)
        await runCreateDepositMutation({
          variables: {
            accountId: account ? account.id : "",
            amount: String(amountVal),
          },
        })
          .then((result) => {
            const depositIdStripe =
              result?.data?.registerCardDeposit?.account?.pendingDeposits
                ?.items[0]?.id
            setDepositsID(depositIdStripe ?? "")
            refetch()
          })
          .catch((error) => {
            onError(error.message)
          })
      }
    } catch (err) {
      onError("create Deposit Mutation Fails!!!")
    }
  }
  const updatePendingDeposit = async (
    depositData: TopUpAmountDataQuery_node_Person_accounts_items_account_pendingDeposits_items
  ) => {
    if (depositData.sourceOfFunds?.length === undefined)
      await updatePendingDepositSourceOfFunds({
        variables: {
          depositId: depositsID,
          depositVersion: depositData.version,
          sourceOfFunds: "Other",
          paymentIntentId,
          externalIdStripe: paymentIntentId,
          amount: amountState,
        },
      }).then((res) => {
        sendDepositMutation({
          variables: {
            depositId: depositsID,
            version: depositData.version + 1,
            accountPositionVersion:
              res.data?.updatePendingDeposit?.account?.positionVersion ?? 1,
            sentAt: new Date().toISOString(),
          },
        })
          .then(() => {
            onSuccess()
            parentCallback()
            setMutationRunning(false)

            setTimeout(() => {
              alert(strings.TOP_UP_SUCCESS_MSG)
            }, [500])
          })
          .catch((error) => {
            onError(error.message)
          })
      })
  }
  /* eslint-disable */
  useEffect(() => {
    setPaymentIntentId(`pro-${uuid().toString()}`)
  }, [])

    /* eslint-disable */
  useEffect(() => {
    const personObj = topUpData?.node
    const accountObj = personObj?.accounts?.items[0]?.account
    const depositData = accountObj?.pendingDeposits?.items[0]
    if (accountObj && mutationRunning && depositData) {
      updatePendingDeposit(depositData)
    }
  }, [topUpData])

  return (
    <>
      <Title>{strings.REWARD_POINTS}</Title>
      <Paragraph style={{ marginTop: 0 }}>
        {`${strings.CONFIRM_USERNAME}:`}
        <br />
        {`${title}`}
      </Paragraph>
      <Paragraph>
        {`${strings.AMOUNT}:`}
        <br />
        {`${description}`}
      </Paragraph>
      <ButtonView>
        <Button
          type="button"
          text={strings.CONFIRM}
          mainAction={false}
          loading={mutationRunning}
          onClick={() => handleNextPress(description)}
        />
        <Button
          type="submit"
          text={strings.CANCEL}
          mainAction={false}
          loading={false}
          disabled={false}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmRewardAmount
