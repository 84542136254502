import React, { ReactElement, useRef, useState } from "react"
import styled from "styled-components"

const QrImage = styled.img`
  width: 170px;
  height: 170px;
  padding: 10px 0;
  align-self: center;
`

export const Button = styled.button`
  align-self: center;
  border: 0;
  background-color: transparent;
  font-size: 1em;
  color: blue;
`

export interface QrCodeData {
  qrDataUrl: string
  otpAuthUrl: string
}

const getCodeFromOtpAuthUrl = (otpAuthUrl: string): string => {
  return otpAuthUrl.split("secret=")[1]
}

const QrCode = ({ qrDataUrl, otpAuthUrl }: QrCodeData): ReactElement => {
  const [copySuccess, setCopySuccess] = useState<boolean>(false)
  const refInput = useRef<HTMLInputElement>(null)

  const copyCodeToClipboard = (): void => {
    if (refInput && refInput.current) {
      refInput.current.select()
    }
    document.execCommand("copy")
    setCopySuccess(true)
  }

  return (
    <>
      <QrImage data-testid="qrImage" src={qrDataUrl} alt="QR code" />
      <Button onClick={copyCodeToClipboard} type="button">
        {copySuccess ? "Copied!" : "Copy code"}
      </Button>
      <input
        ref={refInput}
        aria-hidden="true"
        readOnly
        value={getCodeFromOtpAuthUrl(otpAuthUrl)}
        style={{ position: "absolute", left: "-999em" }}
      />
    </>
  )
}

export default QrCode
