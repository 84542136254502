import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import {
  deleteShareHolding,
  deleteShareHoldingVariables,
} from "../../../@types/graphql"
import { DELETE_SHARE_HOLDING, FETCH_SHARE_HOLDINGS } from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmDeleteShare = ({
  shareId,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  shareId: string
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [deleteShareMutation, { loading: deletingShare }] = useMutation<
    deleteShareHolding,
    deleteShareHoldingVariables
  >(DELETE_SHARE_HOLDING, {
    refetchQueries: [
      {
        query: FETCH_SHARE_HOLDINGS,
      },
    ],
  })

  const handleSubmit = async (): Promise<void> => {
    const { data: deleteShareData } = await deleteShareMutation({
      variables: {
        id: shareId?.trim(),
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })

    if (!deleteShareData) {
      return
    }
    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.DELETE_SHARE}</Title>
      <Paragraph style={{ marginTop: 0, marginBottom: 15 }}>
        {strings.DELETE_STATEMENT}
        <br />
      </Paragraph>
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={deletingShare}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={deletingShare}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmDeleteShare
