import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const GET_PERSON_DETAILS = gql`
  query getPersonDetails($id: String!) {
    node(id: $id) {
      id
      __typename
      ... on Person {
        id
        externalId
        adminTags
        name {
          first
          last
        }
        titles
        dateOfBirth {
          dateOfBirth
        }
        gender
        countryOfBirth
        occupation
        accessCode
        createdAt
        emailAddress
        emailAddressVerified
        mobilePhoneNumber
        financialStatus {
          monthlyIncome {
            amount
          }
          monthlyExpenses {
            amount
          }
          liquidAssets {
            amount
          }
          debt {
            amount
          }
        }
        taxIdentificationNumbers {
          numbers {
            type
            number
          }
        }
        residentialAddress {
          lines
          region
          country
        }
      }
    }
  }
`
