import React, { ReactNode, useState } from "react"
import { CgClose } from "react-icons/cg"
import styled from "styled-components"
import UpdateSustainability from "./updateSustainabilityData"
import ConfirmUpdateSustainability from "./confirmUpdateSustainabilityData"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #20200020;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBox = styled.div`
  position: relative;
  padding: 50px;
  max-width: 30%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 15px;
`

export enum UpdateSustainabilityData {
  Form,
  Confirm,
  Error,
}

const ModalCard = ({
  onClose,
  children,
}: {
  onClose: () => void
  children: ReactNode
}) => {
  return (
    <ModalBox
      data-testid="modalCard"
      onClick={(ev) => {
        ev.stopPropagation()
      }}
    >
      <CloseButton onClick={() => onClose()}>
        <CgClose size={24} />
      </CloseButton>
      {children}
    </ModalBox>
  )
}

const UpdateSustainabilityModal = ({
  sustainabilityId,
  clientFund,
  title,
  fund,
  marketBenchmark,
  onClose,
  parent2Callback,
}: {
  sustainabilityId: string
  clientFund: string
  title: string
  fund: number
  marketBenchmark: number
  onClose: () => void
  parent2Callback: () => void
}) => {
  const [step, setStep] = useState<UpdateSustainabilityData>(
    UpdateSustainabilityData.Form
  )
  const [data, setData] = useState<{
    sustainabilityId: string
    clientFund: string
    titleEdit: string
    fundEdit: number
    marketBenchmarkEdit: number
    error: string
  }>({
    sustainabilityId,
    clientFund,
    titleEdit: "",
    fundEdit: 0,
    marketBenchmarkEdit: 0,
    error: "",
  })

  return (
    <Overlay onClick={() => setStep(UpdateSustainabilityData.Form)}>
      <ModalCard onClose={onClose}>
        {step === UpdateSustainabilityData.Form && (
          <UpdateSustainability
            sustainabilityId={sustainabilityId}
            clientFund={clientFund}
            title={title}
            fund={fund}
            marketBenchmark={marketBenchmark}
            onSubmit={(titleEdit, fundEdit, marketBenchmarkEdit) => {
              setData((prev) => ({
                ...prev,
                titleEdit,
                fundEdit,
                marketBenchmarkEdit,
              }))
              setStep(UpdateSustainabilityData.Confirm)
            }}
          />
        )}
        {step === UpdateSustainabilityData.Confirm && (
          <ConfirmUpdateSustainability
            sustainabilityId={data.sustainabilityId}
            clientfund={data.clientFund}
            title={data.titleEdit}
            fund={data.fundEdit}
            marketBenchmark={data.marketBenchmarkEdit}
            onCancel={() => onClose()}
            onError={(error: any) => {
              setData((prev) => ({ ...prev, error }))
              setStep(UpdateSustainabilityData.Error)
            }}
            onSuccess={() => onClose()}
            parentCallback={() => parent2Callback()}
          />
        )}
        {step === UpdateSustainabilityData.Error && `API Error: ${data.error}`}
      </ModalCard>
    </Overlay>
  )
}

export default UpdateSustainabilityModal
