import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 2em;
`

export const Paragraph = styled.p`
  line-height: 1.3em;
  margin: 0;
`

export const ItemList = styled.ol`
  padding-left: 1em;
  margin: 0;
`

export const SearchItem = styled.li`
  line-height: 1.3em;
`
