import React, { useState } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { gql, useMutation, useQuery } from "@apollo/client"

import {
  actionsAtSecurityTab,
  disableAccount,
  enableAccount,
} from "../../../../@types/graphql"
import Button from "../../../../components/Button"
import ConfirmationDialog from "../../../../components/ConfirmationDialog"

const ButtonList = styled.div`
  margin-bottom: 10px;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
`

export const QUERY = gql`
  query actionsAtSecurityTab($personId: String!) {
    node(id: $personId) {
      id
      __typename
      ... on Person {
        version
        mfaEnabled
        mfaMethod
        disabledAt
      }
    }
  }
`

export const ENABLE_ACCOUNT = gql`
  mutation enableAccount($personId: String!) {
    enablePerson(input: { personId: $personId }) {
      id
      disabledAt
    }
  }
`

export const DISABLE_ACCOUNT = gql`
  mutation disableAccount($personId: String!) {
    disablePerson(input: { personId: $personId }) {
      id
      disabledAt
    }
  }
`

export const CLEAR_MFA = gql`
  mutation clearMFA($personId: String!, $version: Int!) {
    clearMfaSecret(input: { personId: $personId, version: $version }) {
      person {
        id
        version
        mfaMethod
        mfaEnabled
      }
    }
  }
`

export const FORGET_DEVICES = gql`
  mutation forgetDevices($personId: String!, $version: Int!) {
    forgetAllDevices(input: { personId: $personId, version: $version }) {
      success
    }
  }
`

const Actions = () => {
  const { id } = useParams<{ id: string }>()
  const [openDisableMfaConfirmation, setOpenDisableMfaConfirmation] = useState(
    false
  )
  const [
    openEnableAccountConfirmation,
    setOpenEnableAccountConfirmation,
  ] = useState(false)
  const [
    openDisableAccountConfirmation,
    setOpenDisableAccountConfirmation,
  ] = useState(false)
  const [
    openForgetDevicesConfirmation,
    setOpenForgetDevicesConfirmation,
  ] = useState(false)
  const { data, loading, error } = useQuery<actionsAtSecurityTab>(QUERY, {
    variables: { personId: id },
  })

  const [clearMFAMutation, { loading: loadingClearMFAMutation }] = useMutation<
    disableAccount
  >(CLEAR_MFA)

  const [
    enableAccountMutation,
    { loading: loadingEnableAccountMutation },
  ] = useMutation<enableAccount>(ENABLE_ACCOUNT)

  const [
    disableAccountMutation,
    { loading: loadingDisableAccountMutation },
  ] = useMutation<disableAccount>(DISABLE_ACCOUNT)

  const [
    forgetDevicesMutation,
    { loading: loadingForgetDevicesMutation },
  ] = useMutation<disableAccount>(FORGET_DEVICES)

  if (loading) return null

  if (error || data?.node.__typename !== "Person") {
    return <>Error</>
  }

  const { version } = data.node
  const hasMFAEnabled = data.node.mfaEnabled
  const typeMFA = data.node.mfaMethod
  const isAccountDisabled = data.node.disabledAt !== null

  return (
    <ButtonList>
      <>
        <Button
          type="button"
          text={`Disable ${typeMFA || ""} MFA`}
          mainAction={false}
          onClick={() => setOpenDisableMfaConfirmation(true)}
          loading={loadingClearMFAMutation}
          disabled={!hasMFAEnabled}
        />
        {openDisableMfaConfirmation && (
          <ConfirmationDialog
            title="Are you sure you want to disable MFA?"
            open={openDisableMfaConfirmation}
            setOpen={() => setOpenDisableMfaConfirmation(false)}
            onConfirm={() => {
              clearMFAMutation({
                variables: {
                  personId: id,
                  version,
                },
                refetchQueries: ["actionsAtSecurityTab"],
              }).catch((e) => console.log(e))
            }}
          />
        )}
      </>
      {isAccountDisabled ? (
        <>
          <Button
            type="button"
            text="Enable account"
            mainAction={false}
            onClick={() => setOpenEnableAccountConfirmation(true)}
            loading={loadingEnableAccountMutation}
          />
          {openEnableAccountConfirmation && (
            <ConfirmationDialog
              title="Are you sure you want to enable this account?"
              open={openEnableAccountConfirmation}
              setOpen={() => setOpenEnableAccountConfirmation(false)}
              onConfirm={() => {
                enableAccountMutation({
                  variables: {
                    personId: id,
                  },
                  refetchQueries: ["actionsAtSecurityTab"],
                }).catch((e) => console.log(e))
              }}
            />
          )}
        </>
      ) : (
        <>
          <Button
            type="button"
            text="Disable account"
            mainAction={false}
            onClick={() => setOpenDisableAccountConfirmation(true)}
            loading={loadingDisableAccountMutation}
          />
          {openDisableAccountConfirmation && (
            <ConfirmationDialog
              title="Are you sure you want to disable this account?"
              open={openDisableAccountConfirmation}
              setOpen={() => setOpenDisableAccountConfirmation(false)}
              onConfirm={() => {
                disableAccountMutation({
                  variables: {
                    personId: id,
                  },
                  refetchQueries: ["actionsAtSecurityTab"],
                }).catch((e) => console.log(e))
              }}
            />
          )}
        </>
      )}
      <>
        <Button
          type="button"
          text="Forget devices"
          mainAction={false}
          onClick={() => setOpenForgetDevicesConfirmation(true)}
          loading={loadingForgetDevicesMutation}
          disabled={!hasMFAEnabled}
        />
        {openForgetDevicesConfirmation && (
          <ConfirmationDialog
            title="Are you sure you want to forget all devices?"
            open={openForgetDevicesConfirmation}
            setOpen={() => setOpenForgetDevicesConfirmation(false)}
            onConfirm={() => {
              forgetDevicesMutation({
                variables: {
                  personId: id,
                  version,
                },
                refetchQueries: ["actionsAtSecurityTab"],
              }).catch((e) => console.log(e))
            }}
          />
        )}
      </>
    </ButtonList>
  )
}

export default Actions
