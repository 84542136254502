import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import { communicationPreferences } from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import Title from "../../../components/Title"
import Tag from "../../../components/Tag"
import { TagsContainer } from "../../../components/Tag/styles"

export const communicationPreferencesQuery = gql`
  query communicationPreferences($personId: String!) {
    node(id: $personId) {
      __typename
      ... on Person {
        id
        tags
      }
    }
  }
`

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 15px auto 0;
  padding-bottom: 2em;
`

const CommunicationPreferences = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading, error } = useQuery<communicationPreferences>(
    communicationPreferencesQuery,
    {
      variables: { personId: id },
    }
  )

  if (loading) {
    return <Loader />
  }

  if (error || !data || data.node.__typename !== "Person") {
    return <Wrapper>Error</Wrapper>
  }

  const { tags } = data.node

  const hasOptInTips = tags.includes("opt-in-tips")
  const hasOptInNews = tags.includes("opt-in-news")
  const hasTopUp = tags.find((tag) => tag.startsWith("top-up-reminder-"))

  const topUpText = hasTopUp
    ? `Top up reminders - ${hasTopUp.split("-").pop()}`
    : `Top up reminders`

  return (
    <Wrapper>
      <Title>Preferences</Title>
      <TagsContainer>
        <Tag label="Opt in tips" type={hasOptInTips ? "accepted" : "default"} />
        <Tag label="Opt in news" type={hasOptInNews ? "accepted" : "default"} />
        <Tag label={topUpText} type={hasTopUp ? "accepted" : "default"} />
      </TagsContainer>
    </Wrapper>
  )
}

export default CommunicationPreferences
