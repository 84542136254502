import React from "react"
import styled from "styled-components"
import spinnerImg from "../../resources/spinner.svg"

const StyledSpinner = styled.img`
  display: inline-block;
  width: 3em;
  margin: -1em 0;
`
const Spinner = () => <StyledSpinner src={spinnerImg} alt="spinner" />

export default Spinner
