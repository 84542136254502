import React, { useState, FormEvent, FocusEvent, SetStateAction } from "react"
import { gql, useMutation } from "@apollo/client"

import InputField from "../../components/InputField"
import Button from "../../components/Button"
import validateField from "./helper"

import {
  LoginForm,
  ErrorMessage,
  WelcomeText,
  ButtonView,
  CheckboxWrapper,
  Checkbox,
  CheckboxText,
} from "./styles"
import { setDeviceToken } from "../../auth"

export const signInWithMFAMutation = gql`
  mutation signInWithMFA(
    $email: String!
    $password: String!
    $mfaToken: String
    $multiSession: Boolean
  ) {
    signInWithEmailAddress(
      input: {
        emailAddress: $email
        password: $password
        mfaToken: $mfaToken
        multiSession: $multiSession
      }
    ) {
      userToken
      deviceToken
    }
  }
`

const MFAForm = ({
  emailAddress,
  password,
}: {
  emailAddress: string
  password: string
}) => {
  const [mfaToken, setMFAToken] = useState("")
  const [multiSession, setMultiSession] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [errorFieldMessages, setErrorFieldMessages] = useState({
    code: "",
  } as { [key: string]: string })
  const [submitting, setSubmitting] = useState(false)

  const [signInWithMFA] = useMutation(signInWithMFAMutation)

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    validateField(event.target.name, event.target.value, setErrorFieldMessages)
  }

  console.log("test")

  const handleSubmit = (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const mfaTokenIsEmpty = validateField(
      "code",
      mfaToken,
      setErrorFieldMessages
    )

    if (mfaTokenIsEmpty) {
      return Promise.resolve()
    }

    setErrorMessages([])

    setSubmitting(true)
    return signInWithMFA({
      variables: {
        email: emailAddress.trim(),
        password: password.trim(),
        mfaToken: mfaToken.trim(),
        multiSession,
      },
    })
      .then(
        ({
          data: {
            signInWithEmailAddress: { userToken, deviceToken },
          },
        }) => {
          setSubmitting(false)
          localStorage.setItem("adminId", emailAddress.trim())
          setDeviceToken(userToken, deviceToken)
        }
      )
      .catch((error) => {
        setSubmitting(false)
        const messages = error.graphQLErrors.map(
          ({ message }: { message: string }) => message
        )
        if (messages[0] === "Invalid MFA Token") {
          setErrorMessages(["Invalid MFA code"] as SetStateAction<never[]>)
        } else {
          setErrorMessages(["Something went wrong!"] as SetStateAction<never[]>)
        }
      })
  }

  return (
    <LoginForm data-testid="mfaForm" onSubmit={handleSubmit}>
      <WelcomeText>Login to Middle Office</WelcomeText>
      {errorMessages && <ErrorMessage>{errorMessages}</ErrorMessage>}
      <InputField
        type="text"
        fieldName="code"
        placeholder="123456"
        labelText="MFA code"
        value={mfaToken}
        onChange={(event) => setMFAToken(event.target.value.trim() || "")}
        onBlur={handleBlur}
        errorMessage={errorFieldMessages.code}
      />
      <CheckboxWrapper>
        <Checkbox
          id="rememberDevice"
          name="rememberDevice"
          type="checkbox"
          checked={multiSession}
          onChange={() => {
            setMultiSession(!multiSession)
          }}
        />
        <CheckboxText htmlFor="rememberDevice">
          Remember this device
        </CheckboxText>
      </CheckboxWrapper>
      <ButtonView>
        <Button type="submit" text="Next" loading={submitting} />
      </ButtonView>
    </LoginForm>
  )
}

export default MFAForm
