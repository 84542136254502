import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import {
  deleteSustainability,
  deleteSustainabilityVariables,
} from "../../../@types/graphql"
import {
  DELETE_SUSTAINABILITY_DATA,
  FETCH_SUSTAINABILITY_DATA,
} from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmDeleteSustainability = ({
  sustainabilityId,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  sustainabilityId: string
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [
    deleteSustainabilityMutation,
    { loading: deletingSustainability },
  ] = useMutation<deleteSustainability, deleteSustainabilityVariables>(
    DELETE_SUSTAINABILITY_DATA,
    {
      refetchQueries: [
        {
          query: FETCH_SUSTAINABILITY_DATA,
        },
      ],
    }
  )

  const handleSubmit = async (): Promise<void> => {
    const {
      data: deleteSustainbililtyData,
    } = await deleteSustainabilityMutation({
      variables: {
        id: sustainabilityId?.trim(),
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })

    if (!deleteSustainbililtyData) {
      return
    }
    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.DELETE_SUSTAINABILITY}</Title>
      <Paragraph style={{ marginTop: 0, marginBottom: 15 }}>
        {strings.DELETE_STATEMENT}
        <br />
      </Paragraph>
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={deletingSustainability}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={deletingSustainability}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmDeleteSustainability
