import { gql } from "@apollo/client"

export const CREATE_SUSTAINABILITY = gql`
  mutation createSustainability(
    $client_fund: String!
    $sustainability_type: String!
    $fund: Float!
    $market_benchmark: Float!
  ) {
    createSustainability(
      input: {
        client_fund: $client_fund
        sustainability_type: $sustainability_type
        fund: $fund
        market_benchmark: $market_benchmark
      }
    ) {
      client_fund
      fund
      sustainability_type
      market_benchmark
    }
  }
`
export const FETCH_SUSTAINABILITY_DATA = gql`
  query getSustainability {
    getSustainability {
      id
      client_fund
      sustainability_type
      fund
      market_benchmark
    }
  }
`
export const FETCH_UNIQUE_SUSTAINABILITY_DATA = gql`
  query getCountSustainability(
    $client_fund: String!
    $sustainability_type: String!
  ) {
    getCountSustainability(
      input: {
        client_fund: $client_fund
        sustainability_type: $sustainability_type
      }
    ) {
      rowCount
    }
  }
`

export const DELETE_SUSTAINABILITY_DATA = gql`
  mutation deleteSustainability($id: String!) {
    deleteSustainability(input: { id: $id }) {
      fund
    }
  }
`

export const UPDATE_SUSTAINABILITY_DATA = gql`
  mutation updateSustainability(
    $id: String!
    $fund: Float!
    $market_benchmark: Float!
  ) {
    updateSustainability(
      input: { id: $id, fund: $fund, market_benchmark: $market_benchmark }
    ) {
      id
      client_fund
      sustainability_type
      fund
      market_benchmark
    }
  }
`

export const GET_CLIENT_FUND_SUSTAINABILITY_DATA = gql`
  query getDistinctClientFundSustainabilityValues {
    getDistinctClientFundSustainabilityValues {
      client_fund
    }
  }
`
