import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import {
  updateMarketingInsight,
  updateMarketingInsightVariables,
} from "../../../@types/graphql"
import { FETCH_MARKETING_INSIGHTS, UPDATE_MARKET_INSIGHTS } from "../query"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmUpdateInsight = ({
  marketId,
  title,
  subtitle,
  description,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  marketId: string
  title: string
  subtitle: string | null
  description: string | null
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [
    updateMarketInsightMutation,
    { loading: updatingSustainability },
  ] = useMutation<updateMarketingInsight, updateMarketingInsightVariables>(
    UPDATE_MARKET_INSIGHTS,
    {
      refetchQueries: [
        {
          query: FETCH_MARKETING_INSIGHTS,
        },
      ],
    }
  )

  const handleSubmit = async (): Promise<void> => {
    const marketIdValue = marketId?.trim()
    const titleValue = title === null ? "" : title.trim()
    const subtitleValue = subtitle === null ? "" : subtitle.trim()
    const descriptionValue = description === null ? "" : description.trim()
    const { data: updatingInsightData } = await updateMarketInsightMutation({
      variables: {
        id: marketIdValue,
        title: titleValue,
        subtitle: subtitleValue,
        description: descriptionValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!updatingInsightData) {
      return
    }
    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_INSIGHT}</Title>
      {title && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.CONFIRM_TITLE}
          <br />
          {title}
        </Paragraph>
      )}
      {subtitle !== null && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.FUND}
          <br />
          {subtitle}
        </Paragraph>
      )}
      {description !== null && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.MARKET_BENCHMARK}
          <br />
          {description}
        </Paragraph>
      )}
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={updatingSustainability}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={updatingSustainability}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmUpdateInsight
