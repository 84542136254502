import dayjs from "dayjs"
import { UAParser } from "ua-parser-js"

export const capitalizeWord = (word: string | null): string => {
  if (!word) return ""
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const formatTag = (tag: string | null): string => {
  if (tag === "ptsb-user") {
    // eslint-disable-next-line no-param-reassign
    tag = tag.replace("ptsb-user", "PTSB")
  }
  return tag ? capitalizeWord(tag.replace(/-/g, " ")) : ""
}

/**
 * Only change the 00 for a +
 *
 * TODO: Use better formatting. e.g. 00441234123123 → +44 1234 123 123 or 0035317041984 → +353 1 704 1984
 * @param value
 */
export const formatPhoneNumber = (value: string | null): string => {
  if (!value) return ""
  if (!value.startsWith("00") || value.length < 6) {
    return value
  }
  return `+${value.substring(2)}`
}

export const formatUserAgent = (userAgent: string | null): string => {
  if (!userAgent) {
    return "-"
  }

  const result = new UAParser().setUA(userAgent).getResult()
  if (result.browser.name) {
    return `Web / ${result.browser.name} ${result.browser.version}`
  }

  if (result.os.name) {
    return `Mobile / ${result.os.name}`
  }

  if (result.ua.startsWith("okhttp/")) {
    return `Mobile / Android`
  }

  return "unknown"
}

export const formatTimeDate = (
  value: string | null,
  showHourMinutes = true,
  showSeconds = false
): string => {
  if (!value) return "-"

  if (!showHourMinutes) {
    return dayjs(value).format("YYYY-MM-DD")
  }

  if (showSeconds) {
    return dayjs(value).format("YYYY-MM-DD HH:mm:ss")
  }

  return dayjs(value).format("YYYY-MM-DD HH:mm")
}
