import { gql } from "@apollo/client"

export const CREATE_FUND_FACT = gql`
  mutation createFundFact(
    $client_fund: String!
    $asset_class: String!
    $subcategory: String!
    $percentage: Float!
    $main_category: Boolean!
  ) {
    createFundFact(
      input: {
        client_fund: $client_fund
        asset_class: $asset_class
        subcategory: $subcategory
        percentage: $percentage
        main_category: $main_category
      }
    ) {
      client_fund
      asset_class
      subcategory
      percentage
      main_category
    }
  }
`

/** checking Values from the DB to avoid duplication */
export const CHECK_FOR_UNIQUE_VALUES = gql`
  query getCountFundFactsheets(
    $client_fund: String!
    $asset_class: String!
    $subcategory: String!
    $main_category: Boolean!
  ) {
    getCountFundFactsheets(
      input: {
        client_fund: $client_fund
        asset_class: $asset_class
        subcategory: $subcategory
        main_category: $main_category
      }
    ) {
      rowCount
    }
  }
`

export const FETCH_FUND_FACT = gql`
  query getFundFactSheet {
    getFundFactSheet {
      id
      client_fund
      asset_class
      subcategory
      percentage
    }
  }
`
/** Updating Fundfact Values */
export const UPDATE_FUND_FACT = gql`
  mutation updateFundFactSheet(
    $id: String!
    $subcategory: String!
    $percentage: Float!
  ) {
    updateFundFactSheet(
      input: { id: $id, subcategory: $subcategory, percentage: $percentage }
    ) {
      id
      client_fund
      asset_class
      subcategory
      percentage
      main_category
    }
  }
`

export const DELETE_FUND_FACT = gql`
  mutation deleteFundFact($id: String!) {
    deleteFundFact(input: { id: $id }) {
      client_fund
    }
  }
`

/** Getting Distinct Values from the DB for the dropdown */

export const GET_CLIENT_FUND_DROPDOWN = gql`
  query getDistinctClientFundValues {
    getDistinctClientFundValues {
      client_fund
    }
  }
`

export const GET_ASSET_CLASS_DROPDOWN = gql`
  query getDistinctAssetClassValues {
    getDistinctAssetClassValues {
      asset_class
    }
  }
`
export const GET_SUBCATEGORY_DROPDOWN = gql`
  query getDistinctSubcategoryValues($asset_class: String!) {
    getDistinctSubcategoryValues(input: { asset_class: $asset_class }) {
      subcategory
    }
  }
`
