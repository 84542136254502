import React, { ChangeEvent } from "react"
import styled from "styled-components"
import { IoMdSearch } from "react-icons/io"

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #888;
  border-radius: 5px;
  overflow: hidden;
`

const StyledInput = styled.input`
  width: 100%;
  border: 0;
  box-sizing: border-box;
  margin: 0;
  font-size: 1em;
  padding: 1rem 1rem 1rem 2.5rem;
`

const SearchInput = ({
  fieldName,
  value,
  onChange,
}: {
  fieldName: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <InputWrapper data-testid="inputSearch">
      <IoMdSearch
        style={{ marginLeft: "0.5em", position: "absolute" }}
        color="grey"
        size="2em"
      />
      <StyledInput
        type="search"
        id={fieldName}
        name={fieldName}
        value={value}
        onChange={onChange}
        placeholder="Search..."
      />
    </InputWrapper>
  )
}

export default SearchInput
