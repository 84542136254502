import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import { createShareHoldings } from "../../../@types/graphql"
import { CREATE_SHARE_HOLDING } from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmShare = ({
  clientFund,
  title,
  percentage,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  clientFund: string
  title: string
  percentage: string
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [createShareMutation, { loading: creatingShare }] = useMutation<
    createShareHoldings
  >(CREATE_SHARE_HOLDING)

  const handleSubmit = async (): Promise<void> => {
    const clientFundValue = clientFund?.trim()
    const titleValue = title?.trim()
    const percentageValue =
      percentage === "" ? "0" : parseFloat(percentage.trim())
    const { data: creatingShareData } = await createShareMutation({
      variables: {
        clientfund: clientFundValue,
        title: titleValue,
        percentage: percentageValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!creatingShareData) {
      return
    }

    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_SHARE}</Title>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.CLIENT_FUND}
        <br />
        {clientFund}
      </Paragraph>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.CONFIRM_TITLE}
        <br />
        {title}
      </Paragraph>
      <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
        {strings.PERCENTAGE}
        <br />
        {percentage}
      </Paragraph>

      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={creatingShare}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={creatingShare}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmShare
