export const numberFormat = (value: number, showSymbol = true): string => {
  const defaultOptions = {
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  let options: Intl.NumberFormatOptions = defaultOptions

  if (showSymbol) {
    options = {
      ...defaultOptions,
      style: "currency",
    }
  }

  return new Intl.NumberFormat("en-IE", options).format(value)
}
