import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { gql, useQuery } from "@apollo/client"

import { transactions } from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import {
  DepositTransaction,
  OrderTransaction,
  WithdrawalTransaction,
} from "./transaction"

export const ONBOARDING_STALLED = "onboarding-stalled"

export const TRANSACTIONS_QUERY = gql`
  query transactions($id: String!) {
    node(id: $id) {
      ... on Account {
        id
        transactions(types: [Deposit, Order, Withdrawal]) {
          items {
            statusId
            ... on DepositTransaction {
              depositStatus {
                value
                startedAt
                deposit {
                  id
                  value {
                    amount
                    currency
                  }
                  sourceOfFunds
                  externalId
                }
              }
            }
            ... on WithdrawalTransaction {
              withdrawalStatus {
                value
                startedAt
                withdrawal {
                  id
                  value {
                    amount
                    currency
                  }
                  taxes {
                    value {
                      amount
                      currency
                    }
                  }
                  externalId
                  bankAccount {
                    accountNumber
                  }
                }
              }
            }
            ... on OrderTransaction {
              orderStatus {
                value
                startedAt
                order {
                  id
                  side
                  security {
                    symbol
                    name
                  }
                  quantity
                  value
                  price
                }
              }
            }
          }
        }
      }
    }
  }
`

const TransactionsContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
`

const Transactions = () => {
  const { id } = useParams<{ id: string }>()

  const { loading, error, data } = useQuery<transactions>(TRANSACTIONS_QUERY, {
    variables: { id },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data || data.node.__typename !== "Account") {
    return <div>Account not found</div>
  }

  return (
    <TransactionsContainer data-testid="transactions-container">
      {data.node?.transactions?.items?.length > 0 ? (
        data.node.transactions.items
          .map((t) => {
            if (t.__typename === "DepositTransaction") {
              return t.depositStatus
            }
            if (t.__typename === "WithdrawalTransaction") {
              return t.withdrawalStatus
            }
            if (t.__typename === "OrderTransaction") {
              return t.orderStatus
            }

            return undefined
          })
          .filter((t) => t !== undefined)
          .sort((a, b) => (a && b ? b.startedAt.localeCompare(a.startedAt) : 0))
          .map((s) => {
            if (s) {
              if (s.__typename === "DepositStatus") {
                return (
                  <DepositTransaction
                    key={`${s.deposit.id}-${s?.value}`}
                    status={s}
                  />
                )
              }
              if (s.__typename === "WithdrawalStatus") {
                return (
                  <WithdrawalTransaction
                    key={`${s.withdrawal.id}-${s?.value}`}
                    status={s}
                  />
                )
              }
              if (s.__typename === "OrderStatus") {
                return (
                  <OrderTransaction
                    key={`${s.order.id}-${s?.value}`}
                    status={s}
                  />
                )
              }
            }

            return null
          })
      ) : (
        <div>No transactions found.</div>
      )}
    </TransactionsContainer>
  )
}
export default Transactions
