import React from "react"
import ReactDOM from "react-dom"
import { ApolloProvider } from "@apollo/client"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import irishLife from "./themes/irishlife"

import client from "./apolloClient"
import App from "./App"

const theme = irishLife

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
)
