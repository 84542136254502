import React from "react"
import { useParams } from "react-router"

import { useQuery } from "@apollo/client"
import { gql } from "@apollo/client/core"

import { loginHistoryAtSecurityTab } from "../../../../@types/graphql"
import Loader from "../../../../components/Loader"
import Table, { Row } from "../../../../components/Table"
import { formatTimeDate, formatUserAgent } from "../../../../helpers/formatters"
import useFeatureConfig from "../../../../hooks/useFeatureConfig"

export const LOGIN_HISTORY_QUERY = gql`
  query loginHistoryAtSecurityTab($personId: String!) {
    node(id: $personId) {
      id
      __typename
      ... on Person {
        signIns {
          items {
            createdAt
            ipAddress
            userAgent
          }
        }
      }
    }
  }
`

const LoginHistory = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, error } = useQuery<loginHistoryAtSecurityTab>(
    LOGIN_HISTORY_QUERY,
    {
      variables: { personId: id },
    }
  )
  const [hideIPAddress] = useFeatureConfig(["HideIPAddress"])

  if (loading) {
    return <Loader />
  }

  if (error || !data || data.node.__typename !== "Person") {
    return <>Error</>
  }

  const rows = data.node.signIns.items
  const columns = hideIPAddress
    ? ["Date", "Device"]
    : ["Date", "IP address", "Device"]

  return (
    <>
      <Table columnNames={columns} isEmpty={rows?.length === 0}>
        {rows.map(({ createdAt, ipAddress, userAgent }) => (
          <Row
            key={`${createdAt}, ${ipAddress}, ${userAgent}`}
            values={
              hideIPAddress
                ? [formatTimeDate(createdAt), formatUserAgent(userAgent)]
                : [
                    formatTimeDate(createdAt),
                    ipAddress || "",
                    formatUserAgent(userAgent),
                  ]
            }
          />
        ))}
      </Table>
    </>
  )
}

export default LoginHistory
