import React from "react"
import { useParams } from "react-router"

import { gql, useQuery } from "@apollo/client"
import { suitability } from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import { numberFormat } from "../../../helpers/numberFormatter"
import Assessment from "./Assessment"
import { formatTimeDate } from "../../../helpers/formatters"

export type PortfolioInputs = {
  monthlyExpenses: string
  monthlyIncome: string
  assets: string
  debt: string
  responses: {
    "emergency-savings": string
    "experience-assets": string
    "how-long-are-you-investing-for": string
    "investing-experience": string
    "major-changes-to-financial-status": string
    "reaction-to-ten-percent-drop": string
    "selling-point": string
    "tolerance-for-risk": string
    "why-are-you-investing": string
    "sustainability-when-you-think-about-investing": string
    "sustainability-important-areas": string
    "sustainability-how-much-investment": string
    "sustainability-minimum-investment": string
    "sustainability-combat-minimum-investment": string
    "sustainability-accept-preference": string
  }
}

export const ONBOARDING_STALLED = "onboarding-stalled"

export const inputsMap: Record<string, string> = {
  monthlyExpenses: "What are your monthly living expenses?",
  monthlyIncome: "What’s your monthly income (after tax)?",
  assets: "What’s the combined value of your investments and savings?",
  liquidAssets: "What’s the combined value of your investments and savings?",
  debt: "How much debt do you have?",
  "emergency-savings":
    "How many months of living expenses could you cover with your cash savings?",
  "experience-assets": "Which types of assets have you invested in before?",
  "how-long-are-you-investing-for": "How long are you looking to invest for?",
  "investing-experience": "Have you invested before?",
  "major-changes-to-financial-status":
    "Do you think there will be any major changes to your financial situation in the future?",
  "reaction-to-ten-percent-drop":
    "What would you do if your investment portfolio lost 10% of its value in a month?",
  "selling-point":
    "Imagine you've invested €10,000 and the value starts to drop. When would you sell?",
  "tolerance-for-risk":
    "How would you describe your attitude towards investing?",
  "why-are-you-investing": "Why are you interested in investing?",
  "sustainability-when-you-think-about-investing":
    "When you think about investing, is sustainability an important element for you?",
  "sustainability-important-areas":
    "When you think about the impact of your investment on the environment or society, what are the areas that are most important to you?",
  "sustainability-how-much-investment":
    "How much of your potential investment would you want invested in funds that consider these areas?",
  "sustainability-minimum-investment":
    "Imagine you had €10,000 to invest; what is the minimum amount you would want invested in companies addressing climate change and social issues?",
  "sustainability-combat-minimum-investment":
    "Imagine you had €10,000 to invest; what is the minimum amount you would want invested in companies that actively help to combat climate change?",
  "sustainability-accept-preference":
    "Are you happy to adapt your preference and consider funds that currently only monitor the impact on climate change and social and employee matters?",
}

export const SUITABILITY_QUERY = gql`
  query suitability($id: String!) {
    node(id: $id) {
      id
      ... on Account {
        lastUpdatedAt
        portfolioAdvice(acceptedOnly: true) {
          items {
            id
            inputs
            warnings
            acceptedAt
            lastUpdatedAt
            portfolio {
              id
              name
            }
          }
        }
        surveyResponses {
          questionId
          questionText
          response
        }
        personOwners {
          person {
            adminTags
            financialStatus {
              monthlyIncome {
                amount
              }
              monthlyExpenses {
                amount
              }
              liquidAssets {
                amount
              }
              debt {
                amount
              }
            }
          }
        }
      }
    }
  }
`

export const flattenPortfolioInputs = (portfolioInputs: string) => {
  const {
    monthlyExpenses,
    monthlyIncome,
    assets,
    debt,
    responses,
  } = JSON.parse(portfolioInputs) as PortfolioInputs

  return {
    monthlyExpenses,
    monthlyIncome,
    assets,
    debt,
    ...responses,
  }
}

export const convertInputsToSurveyResponses = (
  portfolioInputs: string
): Array<{
  questionId: string
  questionText: string
  response: string
}> => {
  const flattened = flattenPortfolioInputs(portfolioInputs)

  return (
    Object.entries(flattened)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => ({
        questionId: key,
        questionText: inputsMap[key],
        response:
          parseFloat(value) === 0 || (value && typeof value === "number")
            ? numberFormat(parseFloat(value))
            : value,
      }))
  )
}

const Suitability = () => {
  const { id } = useParams<{ id: string }>()

  const { loading, error, data } = useQuery<suitability>(SUITABILITY_QUERY, {
    variables: { id },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data || data.node.__typename !== "Account") {
    return <div>Account not found</div>
  }

  const {
    portfolioAdvice,
    surveyResponses,
    personOwners,
    lastUpdatedAt,
  } = data.node

  // the onboarding stalled
  const isOnboardingStalled =
    personOwners &&
    personOwners[0].person?.adminTags.includes(ONBOARDING_STALLED)

  const hasAcceptedAdvice = portfolioAdvice.items.length > 0

  const financialData = personOwners[0].person?.financialStatus

  const financialInfo = financialData
    ? Object.entries(financialData)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => value !== undefined)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => key !== "__typename")
        .map(([key, value]) => ({
          questionId: key,
          questionText: inputsMap[key],
          response: numberFormat(parseFloat(value?.amount || "0")),
        }))
    : []

  const monthlyIncome = financialData?.monthlyIncome?.amount
  const monthlyExpense = financialData?.monthlyExpenses?.amount
  const assets = financialData?.liquidAssets?.amount
  const debt = financialData?.debt?.amount

  /* Caluating the Monthly Sum */
  const monthRemains = Number(monthlyIncome) - Number(monthlyExpense)
  let monthlySumCalc = 0.5 * monthRemains
  monthlySumCalc = monthlySumCalc < 100 ? 0 : monthlySumCalc
  const monthlySum = numberFormat(monthlySumCalc)

  /* Caluating the Emergency Fund Sum */
  const emergencyFund = Number(monthlyExpense) * 3
  const emergencyMoney = Number(assets) - Number(debt) - Number(emergencyFund)

  /* Caluating the Lump Sum */
  let lumpSumCalc = 0.75 * emergencyMoney
  lumpSumCalc = lumpSumCalc < 100 ? 0 : lumpSumCalc
  const lumpSum = numberFormat(lumpSumCalc)

  const allResponses = [...financialInfo, ...surveyResponses]
  console.log(allResponses)
  const showAssessmentCard = hasAcceptedAdvice ? (
    portfolioAdvice.items.map((advice) => {
      return (
        <Assessment
          key={advice?.id}
          status="Accepted"
          assessmentId={advice?.id}
          recommendedPortfolio={advice?.portfolio.name}
          warnings={advice?.warnings}
          monthlysum={monthlySum}
          lumpsum={lumpSum}
          surveyResponses={convertInputsToSurveyResponses(advice?.inputs)}
          lastUpdatedAt={formatTimeDate(advice?.acceptedAt)}
        />
      )
    })
  ) : (
    <Assessment
      key="-"
      status={isOnboardingStalled ? "Unsuitable" : "Incomplete"}
      assessmentId="-"
      recommendedPortfolio={null}
      warnings={null}
      monthlysum={monthlySum}
      lumpsum={lumpSum}
      surveyResponses={allResponses}
      lastUpdatedAt={formatTimeDate(lastUpdatedAt)}
    />
  )

  return (
    <>
      {portfolioAdvice.items.length === 0 && surveyResponses.length === 0 ? (
        <div style={{ marginTop: 20 }}>No assessments found.</div>
      ) : (
        showAssessmentCard
      )}
    </>
  )
}

export default Suitability
