import React, { FormEventHandler, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import useInputState, { maxValue } from "../../../hooks/useInputState"
import SubcategoryDropDown from "./DropDowns/subCategory"
import AssetClassDropDown from "./DropDowns/assetClass"
import ClientFundDropDown from "./DropDowns/clientFund"
import {
  getCountFundFactsheets,
  getCountFundFactsheetsVariables,
} from "../../../@types/graphql"
import { CHECK_FOR_UNIQUE_VALUES } from "../queries"
import InputFieldNew from "./InputField"
import { capitalizeFirst } from "./constants"

const ButtonView = styled.div`
  margin-top: 2.5em;
`

const ErrorMessage = styled.div`
  position: absolute;
  color: ${(props) => props.theme.error};
  font-size: 0.75em;
  margin: 0.5em 0;
`

const AddNewFundFactForm = ({
  onSubmit,
}: {
  onSubmit: (
    client_fund: string,
    asset_class: string,
    subcategory: string,
    percentage: string,
    main_category: boolean
  ) => void
}) => {
  const [stateClientFund, setStateClientFund] = useState<{
    clientFund: string
  }>({
    clientFund: "",
  })
  const [stateAssetClass, setStateAssetClass] = useState<{
    assetClass: string
  }>({
    assetClass: "",
  })
  const [stateSubCategory, setStateSubCategory] = useState<{
    subCategory: string
  }>({
    subCategory: "",
  })

  const {
    value: percentage,
    hasValidValue: percentageIsValid,
    errorMessage: percentage_ErrorMessage,
    onChange: onChangePercentage,
    onBlur: onBlurPercentage,
  } = useInputState("", [maxValue("Must be a valid number")])

  const getClientFund = (clientFund?: any) => {
    setStateClientFund((prev) => ({
      ...prev,
      clientFund,
    }))
  }

  const getAssetClass = (assetClass?: any) => {
    setStateAssetClass((prev) => ({
      ...prev,
      assetClass,
    }))
  }

  const getSubcategory = (subCategory?: any) => {
    setStateSubCategory((prev) => ({
      ...prev,
      subCategory,
    }))
  }

  const clientFundValue = stateClientFund.clientFund
  const assetClassValue = stateAssetClass.assetClass
  const subCategoryValue = stateSubCategory.subCategory
  const percentageValue = percentage === "" ? "0" : percentage
  const mainCategory = !subCategoryValue
  const { data, loading } = useQuery<
    getCountFundFactsheets,
    getCountFundFactsheetsVariables
  >(CHECK_FOR_UNIQUE_VALUES, {
    variables: {
      client_fund: clientFundValue,
      asset_class: assetClassValue,
      subcategory: subCategoryValue,
      main_category: mainCategory,
    },
    fetchPolicy: "network-only",
  })

  const uniqueValueCount = data?.getCountFundFactsheets?.rowCount
  const isDisabled =
    !(clientFundValue && assetClassValue && percentageIsValid) ||
    !!uniqueValueCount
  const handleSubmit: FormEventHandler = async (ev): Promise<void> => {
    ev.preventDefault()
    onBlurPercentage()
    if (!isDisabled) {
      onSubmit(
        clientFundValue.toUpperCase().trim(),
        capitalizeFirst(assetClassValue),
        capitalizeFirst(subCategoryValue),
        percentageValue.trim(),
        mainCategory
      )
    }
  }
  return (
    <>
      <Title>{strings.ADD_FACTSHEET}</Title>
      <form onSubmit={handleSubmit}>
        <ClientFundDropDown parentCallback={getClientFund} />
        {clientFundValue === undefined ? (
          <ErrorMessage>{strings.CLIENT_ERROR}</ErrorMessage>
        ) : null}
        <AssetClassDropDown parentCallback={getAssetClass} />
        {assetClassValue === undefined ? (
          <ErrorMessage>{strings.ASSET_ERROR}</ErrorMessage>
        ) : null}
        <SubcategoryDropDown
          parentCallback={getSubcategory}
          assetClass={assetClassValue}
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Percentage"
          value={percentage}
          onChange={onChangePercentage}
          onBlur={onBlurPercentage}
          errorMessage={percentage_ErrorMessage}
          placeholder={strings.PERCENTAGE_PLACEHOLDER}
        />
        {uniqueValueCount ? (
          <ErrorMessage>{strings.DUPLICATION_ERROR}</ErrorMessage>
        ) : null}
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={loading}
            disabled={isDisabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default AddNewFundFactForm
