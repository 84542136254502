import React, { ReactElement } from "react"

import Button from "../../components/Button"
import { ButtonView, Paragraph, TitleText } from "./styles"

const SetupComplete = ({
  onSuccess,
}: {
  onSuccess: () => void
}): ReactElement => {
  return (
    <>
      <TitleText>Setup Completed</TitleText>
      <Paragraph>You can now access the Middle Office.</Paragraph>
      <ButtonView>
        <Button
          type="submit"
          text="Login"
          onClick={onSuccess}
          loading={false}
          disabled={false}
        />
      </ButtonView>
    </>
  )
}

export default SetupComplete
