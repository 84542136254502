import { useQuery } from "@apollo/client"
import React from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import Loader from "../../../components/Loader"
import Table, { Row } from "../../../components/Table"
import { GET_PERSON_ID_VERIFICATIONS } from "./query"
import { getPersonIdVerifications } from "../../../@types/graphql"
import { formatTimeDate } from "../../../helpers/formatters"

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 30px auto;
`

export const headings = [
  "Authority",
  "External id",
  "Transaction id",
  "Date created",
  "Rejection cause",
]

export default function IdVerifications() {
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQuery<getPersonIdVerifications>(
    GET_PERSON_ID_VERIFICATIONS,
    {
      variables: { id },
    }
  )

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  if (data.node.__typename !== "Person") {
    return <div>User not found</div>
  }

  const rows = data.node.identityVerifications.items.map(
    ({
      id: idVerificationId,
      authority,
      externalId,
      backOfficeId,
      createdAt,
      rejectionCause,
    }) => {
      return (
        <Row
          key={idVerificationId}
          values={[
            authority,
            externalId || "-",
            backOfficeId || "-",
            formatTimeDate(createdAt),
            rejectionCause || "-",
          ]}
        />
      )
    }
  )

  return (
    <Wrapper data-testid="idVerifications">
      <Table columnNames={headings} isEmpty={rows.length === 0}>
        {rows}
      </Table>
    </Wrapper>
  )
}
