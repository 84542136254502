/* eslint-disable no-return-assign */
import React, { useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"

import { LogoBox, Page } from "../Login/styles"
import CorporateLogo from "../../components/CorporateLogo"
import { Container } from "./styles"
import SetupComplete from "./SetupComplete"
import ScanQrCode from "./ScanQrCode"
import ResetPassword from "./ResetPassword"
import EnterCode from "./EnterCode"
import SetupMfa from "./SetupMfa"

const AcceptInvitation = ({ location }: RouteComponentProps) => {
  const params = new URLSearchParams(location.search)
  const email = params.get("email") as string
  const code = params.get("code") as string

  const history = useHistory()
  const [step, setStep] = useState("ResetPassword")
  const [qrInfo, setQrInfo] = useState<{
    otpauthUrl: string
    qrDataUrl: string
  } | null>(null)

  return (
    <Page data-testid="acceptInvitation">
      <LogoBox>
        <CorporateLogo />
      </LogoBox>
      <Container>
        {step === "ResetPassword" && (
          <ResetPassword
            onNext={() => setStep("SetupMfa")}
            email={email}
            code={code}
          />
        )}
        {step === "SetupMfa" && (
          <SetupMfa onNext={() => setStep("ScanCode")} setQrInfo={setQrInfo} />
        )}
        {step === "ScanCode" && (
          <>
            {qrInfo?.qrDataUrl && qrInfo?.otpauthUrl ? (
              <ScanQrCode
                onNext={() => setStep("EnterCode")}
                qrDataUrl={qrInfo?.qrDataUrl}
                otpAuthUrl={qrInfo?.otpauthUrl}
              />
            ) : (
              <p>An error occurred</p>
            )}
          </>
        )}
        {step === "EnterCode" && (
          <EnterCode onNext={() => setStep("SetupComplete")} />
        )}
        {step === "SetupComplete" && (
          <SetupComplete onSuccess={() => history.push("/")} />
        )}
      </Container>
    </Page>
  )
}

export default AcceptInvitation
