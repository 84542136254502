import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const detailsQuery = gql`
  query personDetails($id: String!) {
    node(id: $id) {
      id
      __typename
      ... on Person {
        name {
          first
          last
        }
        titles
        adminTags
        dateOfBirth {
          dateOfBirth
        }
        emailAddress
        token
        role
        gender
        occupation
        mobilePhoneNumber
        mfaEnabled
        disabledAt
        createdAt
        accessCode
        taxIdentificationNumbers {
          numbers {
            number
          }
        }
        residentialAddress {
          streetNumber
          streetName
          city
          postCode
        }
        mailingAddress {
          streetNumber
          streetName
          city
          postCode
        }
      }
    }
  }
`

export const GET_SUBSCRIPTIONS_BY_PERSON_ID_QUERY = gql`
  query getSubscriptionByPersonId($personId: String!) {
    getSubscriptionByPersonId(input: { personId: $personId }) {
      status
    }
  }
`
