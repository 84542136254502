import React, { ChangeEvent, FocusEvent } from "react"
import styled from "styled-components"

const InputWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize};
  margin-bottom: 15px;
  padding-top: 10px;
`

const StyledLabel = styled.label`
  display: block;
  font-size: 0.75em;
  margin-bottom: 0.5em;
`

const StyledSelect = styled.select`
  display: block;
  border-radius: 2px;
  border: 1px solid #888;
  padding: 0.5em 1em;
  font-size: 1em;
  width: 100%;
`

const ErrorMessage = styled.div`
  position: absolute;
  color: ${(props) => props.theme.error};
  font-size: 0.75em;
  margin: 0.5em 0;
`

const SelectField = ({
  labelText,
  fieldName,
  errorMessage,
  value,
  options,
  onChange,
  onBlur,
}: {
  labelText: string
  fieldName: string
  errorMessage?: string
  value: string | null
  options: { id: string; value: string }[]
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  onBlur: (event: FocusEvent<HTMLSelectElement>) => void
}) => {
  return (
    <InputWrapper>
      <StyledLabel htmlFor={fieldName}>{labelText}</StyledLabel>
      <StyledSelect
        data-testid="selectField"
        id={fieldName}
        name={fieldName}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map(({ id, value: displayedValue }) => (
          <option data-testid="selectOption" key={id} value={id}>
            {displayedValue}
          </option>
        ))}
      </StyledSelect>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </InputWrapper>
  )
}

export default SelectField
