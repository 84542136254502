import React, { ChangeEvent, FocusEvent } from "react"
import styled from "styled-components"

const InputWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize};
  margin-bottom: 15px;
  padding-top: 10px;
  width: 100%;
`

const StyledLabel = styled.label`
  display: block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
`

const StyledInput = styled.input`
  display: block;
  border-radius: 2px;
  appearance: none;
  border: 1px solid #888;
  padding: 0.5em 1em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
`

const ErrorMessage = styled.div`
  position: absolute;
  color: ${(props) => props.theme.error};
  font-size: 0.75em;
  margin: 0.5em 0;
`

const InputFieldNew = ({
  labelText,
  fieldName,
  placeholder,
  type,
  errorMessage,
  value,
  onChange,
  onBlur,
  disabled = false,
}: {
  labelText: string
  fieldName: string
  placeholder?: string
  type: "text" | "number"
  errorMessage?: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
}) => {
  return (
    <InputWrapper>
      <StyledLabel htmlFor={fieldName}>{labelText}</StyledLabel>
      <StyledInput
        data-testid="inputField"
        id={fieldName}
        type={type}
        placeholder={placeholder}
        name={fieldName}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </InputWrapper>
  )
}

export default InputFieldNew
