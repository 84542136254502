import React from "react"
import { useParams } from "react-router"
import { useQuery } from "@apollo/client"

import {
  capitalizeWord,
  formatPhoneNumber,
  formatTimeDate,
} from "../../../helpers/formatters"
import { getPersonDetails } from "../../../@types/graphql"
import Loader from "../../../components/Loader"
import {
  ColumnView,
  ColumnViewWrapper,
  DetailsWrapper,
  EmptyValue,
  InnerColumnView,
  SectionTitle,
  StyledUserField,
  UserFieldName,
  UserFieldValue,
} from "./styles"
import { GET_PERSON_DETAILS } from "./query"
import countries from "./countries"
import { numberFormat } from "../../../helpers/numberFormatter"
import Container from "../../../components/Container"

const fullAddress = ({
  lines,
  region,
  country,
}: {
  lines: string[] | null
  region: string | null
  country: string | null
}) => {
  return {
    line1: lines ? lines[0] : "",
    line2: lines ? lines[1] : "",
    line3: lines ? lines[2] : "",
    region: region || "",
    country: country || "",
  }
}

const UserFieldRow = ({
  fieldName,
  value,
}: {
  fieldName: string
  value: string | undefined
}) => {
  const isEmpty = !value || value.trim() === ""
  return (
    <StyledUserField>
      <UserFieldName>{fieldName}</UserFieldName>
      {!isEmpty ? (
        <UserFieldValue>{value}</UserFieldValue>
      ) : (
        <EmptyValue>empty</EmptyValue>
      )}
    </StyledUserField>
  )
}

const getCountryByID = (countryId: string): string | undefined =>
  countries.find((country) => country.id === countryId)?.option

const Details = () => {
  const { id } = useParams<{ id: string }>()

  const { loading, error, data } = useQuery<getPersonDetails>(
    GET_PERSON_DETAILS,
    {
      variables: { id },
    }
  )

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  if (data.node.__typename !== "Person") {
    return <div>User not found</div>
  }

  return (
    <Container>
      <DetailsWrapper data-testid="Details">
        <ColumnView>
          <SectionTitle>Client Information</SectionTitle>
          <UserFieldRow
            fieldName="Title"
            value={data.node.titles ? data.node.titles.join(" ") : ""}
          />
          <UserFieldRow
            fieldName="First name"
            value={capitalizeWord(data.node.name.first) || ""}
          />
          <UserFieldRow
            fieldName="Last name"
            value={capitalizeWord(data.node.name.last) || ""}
          />
          <UserFieldRow
            fieldName="Date of birth"
            value={
              data.node.dateOfBirth?.dateOfBirth
                ? formatTimeDate(data.node.dateOfBirth.dateOfBirth, false)
                : ""
            }
          />
          <UserFieldRow fieldName="Gender" value={data.node.gender || ""} />
          <UserFieldRow
            fieldName="Country of birth"
            value={getCountryByID(data.node.countryOfBirth || "")}
          />
          <UserFieldRow
            fieldName="Occupation"
            value={data.node.occupation || ""}
          />
          <UserFieldRow
            fieldName="Source"
            value={
              data.node.adminTags.includes("ptsb-user") === true
                ? "PTSB"
                : "IL" || ""
            }
          />
          <UserFieldRow
            fieldName="Corp code"
            value={data.node.accessCode || ""}
          />
          <UserFieldRow
            fieldName="PPS number"
            value={
              data.node.taxIdentificationNumbers?.numbers[0]?.number
                ? data.node.taxIdentificationNumbers.numbers[0].number
                : ""
            }
          />
          <UserFieldRow
            fieldName="Registration date"
            value={formatTimeDate(data.node.createdAt)}
          />
          <UserFieldRow fieldName="Internal ID" value={data.node.id || ""} />
          <UserFieldRow
            fieldName="External ID"
            value={data.node.externalId || ""}
          />
        </ColumnView>
        <ColumnViewWrapper>
          <InnerColumnView>
            <SectionTitle>Contact Information</SectionTitle>
            <UserFieldRow
              fieldName="Email"
              value={data.node.emailAddress || ""}
            />
            <UserFieldRow
              fieldName="Email status"
              value={
                data.node.emailAddressVerified === "false"
                  ? "NOT VERIFIED"
                  : "VERIFIED"
              }
            />
            <UserFieldRow
              fieldName="Mobile number"
              value={formatPhoneNumber(data.node.mobilePhoneNumber)}
            />
            <UserFieldRow
              fieldName="Address"
              value={fullAddress(data.node.residentialAddress).line1 || ""}
            />
            <UserFieldRow
              fieldName=""
              value={fullAddress(data.node.residentialAddress).line2 || ""}
            />
            <UserFieldRow
              fieldName=""
              value={fullAddress(data.node.residentialAddress).line3 || ""}
            />
            <UserFieldRow
              fieldName="County"
              value={fullAddress(data.node.residentialAddress).region || ""}
            />
            <UserFieldRow
              fieldName="Country"
              value={getCountryByID(data.node.residentialAddress.country || "")}
            />
          </InnerColumnView>
          <InnerColumnView>
            <SectionTitle>Financial Information</SectionTitle>
            <UserFieldRow
              fieldName="Monthly income"
              value={numberFormat(
                parseFloat(
                  data.node.financialStatus.monthlyIncome?.amount || "0"
                )
              )}
            />
            <UserFieldRow
              fieldName="Monthly expense"
              value={numberFormat(
                parseFloat(
                  data.node.financialStatus.monthlyExpenses?.amount || "0"
                )
              )}
            />
            <UserFieldRow
              fieldName="Liquid assets"
              value={numberFormat(
                parseFloat(
                  data.node.financialStatus.liquidAssets?.amount || "0"
                )
              )}
            />
            <UserFieldRow
              fieldName="Debt"
              value={numberFormat(
                parseFloat(data.node.financialStatus.debt?.amount || "0")
              )}
            />
          </InnerColumnView>
        </ColumnViewWrapper>
      </DetailsWrapper>
    </Container>
  )
}

export default Details
