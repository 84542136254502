import React, { ReactNode, useState } from "react"
import { CgClose } from "react-icons/cg"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router"
import ConfirmNewAmount from "./confirmAmount"
import { personDetails } from "../../@types/graphql"
import { detailsQuery } from "../Person/PersonProfile/query"
import AddRewardForm from "./addRewardsForm"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #20200020;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBox = styled.div`
  position: relative;
  padding: 50px;
  max-width: 30%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 15px;
`

enum RewardActionTypes {
  Form,
  Confirm,
  Error,
}

const ModalCard = ({
  onClose,
  children,
}: {
  onClose: () => void
  children: ReactNode
}) => {
  return (
    <ModalBox
      data-testid="modalCard"
      onClick={(ev) => {
        ev.stopPropagation()
      }}
    >
      <CloseButton onClick={() => onClose()}>
        <CgClose size={24} />
      </CloseButton>
      {children}
    </ModalBox>
  )
}

const Rewards = ({
  onClose,
  parent2Callback,
}: {
  onClose: () => void
  parent2Callback: () => void
}) => {
  const [step, setStep] = useState<RewardActionTypes>(RewardActionTypes.Form)
  const [data, setData] = useState<{
    title: string
    description: string
    error: string
  }>({
    title: "",
    description: "",
    error: "",
  })

  const { id } = useParams<{ id: string }>()
  const { data: personData, loading: personLoading } = useQuery<personDetails>(
    detailsQuery,
    {
      variables: { id },
    }
  )

  if (personLoading) return null

  return (
    <Overlay onClick={() => setStep(RewardActionTypes.Form)}>
      <ModalCard onClose={onClose}>
        {step === RewardActionTypes.Form && (
          <AddRewardForm
            username={`${personData?.node.name.first} ${personData?.node.name.last}`}
            onSubmit={(description) => {
              setData((prev) => ({ ...prev, description }))
              setStep(RewardActionTypes.Confirm)
            }}
          />
        )}
        {step === RewardActionTypes.Confirm && (
          <ConfirmNewAmount
            title={`${personData?.node.name.first} ${personData?.node.name.last}`}
            description={data.description}
            onCancel={() => setStep(RewardActionTypes.Form)}
            onSuccess={() => onClose()}
            parentCallback={() => parent2Callback()}
            onError={(error) => {
              setData((prev) => ({ ...prev, error }))
              setStep(RewardActionTypes.Error)
            }}
          />
        )}
        {step === RewardActionTypes.Error && `API Error: ${data.error}`}
      </ModalCard>
    </Overlay>
  )
}

export default Rewards
