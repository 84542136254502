export default (
  fieldName: string,
  value: string,
  setErrorFieldMessages: (
    callback: (prev: { [key: string]: string }) => { [key: string]: string }
  ) => void
): boolean => {
  if (!value) {
    setErrorFieldMessages((prev) => ({
      ...prev,
      [fieldName]: `Please enter ${fieldName}`,
    }))
  } else {
    setErrorFieldMessages((prev) => ({
      ...prev,
      [fieldName]: "",
    }))
  }
  return !value
}
