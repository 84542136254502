import { useMutation } from "@apollo/client"
import React from "react"
import styled from "styled-components"
import Button from "../../../components/Button"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { Paragraph } from "../../Dashboard/styles"
import {
  updateFundFactSheet,
  updateFundFactSheetVariables,
} from "../../../@types/graphql"
import { FETCH_FUND_FACT, UPDATE_FUND_FACT } from "../queries"

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ConfirmUpdateFundFact = ({
  fundId,
  clientFund,
  assetClass,
  subcategory,
  percentage,
  onCancel,
  onSuccess,
  onError,
  parentCallback,
}: {
  fundId: string
  clientFund: string | null
  assetClass: string
  subcategory: string
  percentage: number
  onCancel: () => void
  onSuccess: () => void
  parentCallback: () => void
  onError: (error: string) => void
}) => {
  const [updateFundFactMutation, { loading: updatingFundFact }] = useMutation<
    updateFundFactSheet,
    updateFundFactSheetVariables
  >(UPDATE_FUND_FACT, {
    refetchQueries: [
      {
        query: FETCH_FUND_FACT,
      },
    ],
  })

  const handleSubmit = async (): Promise<void> => {
    const fundIdValue = fundId?.trim()
    const subCategoryValue = subcategory?.trim()
    const percentageValue = percentage === null ? 0 : percentage
    const { data: updatingFundFactData } = await updateFundFactMutation({
      variables: {
        id: fundIdValue,
        subcategory: subCategoryValue,
        percentage: percentageValue,
      },
    }).catch((error) => {
      onError(error.message)
      return { data: null }
    })
    if (!updatingFundFactData) {
      return
    }
    onSuccess()
    parentCallback()
  }

  return (
    <>
      <Title>{strings.CONFIRM_FACTSHEET}</Title>
      {clientFund && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.CLIENT_FUND}
          <br />
          {clientFund}
        </Paragraph>
      )}
      {assetClass && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.ASSET_CLASS}
          <br />
          {assetClass}
        </Paragraph>
      )}
      {subcategory && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.SUBCATEGORY}
          <br />
          {subcategory}
        </Paragraph>
      )}
      {percentage !== 0 && (
        <Paragraph style={{ marginTop: 5, marginBottom: 10 }}>
          {strings.PERCENTAGE}
          <br />
          {percentage}
        </Paragraph>
      )}
      <ButtonView>
        <Button
          type="button"
          text="Confirm"
          mainAction={false}
          loading={updatingFundFact}
          onClick={handleSubmit}
        />
        <Button
          type="submit"
          text="Cancel"
          mainAction={false}
          loading={false}
          disabled={updatingFundFact}
          onClick={onCancel}
        />
      </ButtonView>
    </>
  )
}

export default ConfirmUpdateFundFact
