import { gql } from "@apollo/client"

// eslint-disable-next-line import/prefer-default-export
export const ENABLE_PERSON = gql`
  mutation enablePerson($personId: String!) {
    enablePerson(input: { personId: $personId }) {
      id
      emailAddress
      disabledAt
    }
  }
`

export const DISABLE_PERSON = gql`
  mutation disablePerson($personId: String!) {
    disablePerson(input: { personId: $personId }) {
      id
      emailAddress
      disabledAt
    }
  }
`

export const SEND_ADMIN_INVITATION = gql`
  mutation sendAdminInvitation($personId: String!) {
    sendAdminInvitationToPerson(input: { personId: $personId }) {
      success
    }
  }
`
