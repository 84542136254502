import { gql, useMutation } from "@apollo/client"
import React, { ReactElement, useState } from "react"
import {
  MfaMethod,
  setupMfaGenerateMfaSecret,
  setupMfaGenerateMfaSecretVariables,
} from "../../@types/graphql"

import Button from "../../components/Button"
import { ButtonView, ErrorMessage, Paragraph, TitleText } from "./styles"
import { decryptString } from "../../utils/encryptionCrypto"

export const GENERATE_MFA_SECRET_MUTATION = gql`
  mutation setupMfaGenerateMfaSecret($input: GenerateMfaSecretInput!) {
    generateMfaSecret(input: $input) {
      otpauthUrl
      qrDataUrl
    }
  }
`

const SetupMfa = ({
  onNext,
  setQrInfo,
}: {
  onNext: () => void
  setQrInfo: ({
    otpauthUrl,
    qrDataUrl,
  }: {
    otpauthUrl: string
    qrDataUrl: string
  }) => void
}): ReactElement => {
  const [errorMessage, setErrorMessage] = useState("")

  const [
    generateMfaSecret,
    { loading: generateMfaSecretLoading },
  ] = useMutation<
    setupMfaGenerateMfaSecret,
    setupMfaGenerateMfaSecretVariables
  >(GENERATE_MFA_SECRET_MUTATION)

  const handleSetupCode = async (): Promise<void> => {
    await generateMfaSecret({
      variables: {
        input: {
          method: MfaMethod.App,
        },
      },
    })
      .then((response) => {
        const data = response.data?.generateMfaSecret

        if (data?.otpauthUrl && data?.qrDataUrl) {
          setQrInfo({
            otpauthUrl: decryptString(data?.otpauthUrl),
            qrDataUrl: data?.qrDataUrl,
          })
        }
        onNext()
      })
      .catch(() => setErrorMessage("Something went wrong"))
  }

  return (
    <>
      <TitleText>Setup MFA</TitleText>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Paragraph>
        In order to use the Middle Office, you will need an app that generates
        authentication codes. We recommend Google Authenticator or Authy. When
        you have downloaded one of those apps, continue to the next step.
      </Paragraph>
      <ButtonView>
        <Button
          type="submit"
          text="Next"
          onClick={handleSetupCode}
          loading={generateMfaSecretLoading}
          disabled={generateMfaSecretLoading}
        />
      </ButtonView>
    </>
  )
}

export default SetupMfa
