import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"
import {
  AccountDetails,
  AccountDetailsVariables,
  PositionType,
} from "../../../@types/graphql"
import AttributeList from "../../../components/AttributeList"
import Container from "../../../components/Container"
import Loader from "../../../components/Loader"
import Tag from "../../../components/Tag"
import { TagsContainer } from "../../../components/Tag/styles"
import Title from "../../../components/Title"
import { formatTag } from "../../../helpers/formatters"
import { numberFormat } from "../../../helpers/numberFormatter"
import useFeatureConfig from "../../../hooks/useFeatureConfig"

export const ACCOUNT_DETAILS_QUERY = gql`
  query AccountDetails($id: String!) {
    node(id: $id) {
      id
      ... on Account {
        status
        type
        externalId
        adminTags
        portfolio {
          name
          securities {
            items {
              security {
                symbol
              }
            }
          }
        }
        backOfficeAccount {
          backOfficeId
        }
        value(currency: "EUR")
        withdrawals {
          items {
            lastStatus
            value {
              amount
            }
          }
        }
        positions {
          items {
            id
            type
            currentValue: value
          }
        }
        personOwners {
          person {
            id
            adminTags
            token
            name {
              first
              last
            }
            sustainabilityPreference
          }
        }
      }
      __typename
    }
  }
`

const AccountProfileWrapper = styled.div`
  padding: 15px 0;
  > ul {
    margin-bottom: 15px;
  }
`

const getProfileData = (data: AccountDetails) => {
  if (data.node.__typename === "Account") {
    const ownedBy = `${data.node.personOwners[0].person?.name.first} ${data.node.personOwners[0].person?.name.last} (${data.node.personOwners[0].person?.id})`
    const { status, type } = data.node
    const custodianId = data.node.backOfficeAccount?.backOfficeId || "-"
    const sustainabilityPreference =
      data.node.personOwners[0].person?.sustainabilityPreference || "-"
    const portfolio = data.node.portfolio?.name || "-"
    const aum = parseFloat(data.node.value || "0")
    const user =
      data.node.personOwners[0].person?.adminTags.includes("ptsb-user") === true
        ? "PTSB"
        : "IL"

    const isPtsbUser = data.node.personOwners[0].person?.adminTags.includes(
      "ptsb-user"
    )

    const ptsbSellerCode =
      isPtsbUser && data.node.personOwners[0].person?.token
        ? data.node.personOwners[0].person?.token
        : "PTS1"

    const d2cSellerCode =
      !isPtsbUser && data?.node?.personOwners[0]?.person?.token
        ? data.node.personOwners[0].person?.token
        : "4899"

    const seller = isPtsbUser ? ptsbSellerCode : d2cSellerCode

    const netDepositPosition = data.node.positions.items.find(
      (position) => position.type === PositionType.NetDeposit
    )
    const grossDepositPosition = data.node.positions.items.find(
      (position) => position.type === PositionType.GrossDeposit
    )

    const netDeposits = parseFloat(netDepositPosition?.currentValue ?? "0")
    const totalDeposits = parseFloat(grossDepositPosition?.currentValue ?? "0")

    const totalWithdrawals = data.node.withdrawals.items
      .filter((withdrawal) => withdrawal.lastStatus === "Accepted")
      .reduce((acc, withdrawal) => acc + parseFloat(withdrawal.value.amount), 0)

    const securitySymbol =
      data.node.portfolio?.securities?.items[0]?.security.symbol

    return {
      aum,
      custodianId,
      netDeposits,
      totalDeposits,
      totalWithdrawals,
      ownedBy,
      status,
      type,
      portfolio,
      securitySymbol,
      user,
      seller,
      sustainabilityPreference,
    }
  }

  return {
    aum: 0,
    custodianId: null,
    netDeposits: 0,
    totalDeposits: 0,
    totalWithdrawals: 0,
    ownedBy: null,
    status: null,
    type: null,
    portfolio: null,
    securitySymbol: null,
    user: "",
    seller: "",
    sustainabilityPreference: "",
  }
}

const AccountProfile = () => {
  const { id } = useParams<{ id: string }>()
  const [showExternalIdAsAccountId] = useFeatureConfig([
    "ExternalIdAsAccountId",
  ])

  const { loading, error, data } = useQuery<
    AccountDetails,
    AccountDetailsVariables
  >(ACCOUNT_DETAILS_QUERY, { variables: { id } })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error</div>
  }

  if (!data || data.node.__typename !== "Account") {
    return <div>Account not found</div>
  }

  const {
    ownedBy,
    status,
    type,
    portfolio,
    aum,
    custodianId,
    netDeposits,
    totalDeposits,
    totalWithdrawals,
    securitySymbol,
    user,
    seller,
    sustainabilityPreference,
  } = getProfileData(data)
  return (
    <Container data-testid="AccountProfile">
      <AccountProfileWrapper>
        <Title>
          {showExternalIdAsAccountId ? data.node.externalId : data.node.id}
        </Title>
        <TagsContainer>
          {data.node.adminTags.map((t) => (
            <Tag key={t} label={formatTag(t)} />
          ))}
        </TagsContainer>
        <AttributeList orientation="horizontal">
          <li>
            <h4>Source</h4>
            <p>{user}</p>
          </li>
          <li>
            <h4>Seller</h4>
            <p>{seller}</p>
          </li>
          <li>
            <h4>Plan ID</h4>
            <p>{custodianId}</p>
          </li>
          <li>
            <h4>Owned by</h4>
            <Link to={`/person/${data.node.personOwners[0].person?.id}`}>
              {ownedBy}
            </Link>
          </li>
        </AttributeList>
        <AttributeList orientation="vertical">
          <li>
            <h4>Type</h4>
            <p>{type}</p>
          </li>
          <li>
            <h4>Status</h4>
            <p>{status}</p>
          </li>
          <li>
            <h4>Portfolio</h4>
            <p>{portfolio}</p>
          </li>
          <li>
            <h4>Sustainability Preference</h4>
            <p>{sustainabilityPreference}</p>
          </li>
          <li>
            <h4>Fund</h4>
            <p>{securitySymbol}</p>
          </li>
          <li>
            <h4>AUM</h4>
            <p>{numberFormat(aum)}</p>
          </li>
          <li>
            <h4>Net Deposits</h4>
            <p>{numberFormat(netDeposits)}</p>
          </li>
          <li>
            <h4>Total Deposits</h4>
            <p>{numberFormat(totalDeposits)}</p>
          </li>
          <li>
            <h4>Total Withdrawals</h4>
            <p>{numberFormat(totalWithdrawals)}</p>
          </li>
        </AttributeList>
      </AccountProfileWrapper>
    </Container>
  )
}

export default AccountProfile
