import { useQuery } from "@apollo/client"
import { gql } from "@apollo/client/core"
import React from "react"
import { Link } from "react-router-dom"
import { searchByEmail } from "../../../@types/graphql"
import Button from "../../../components/Button"
import Title from "../../../components/Title"
import { rolesMap } from "./InviteConfirm"
import { ButtonWrapper } from "./styles"

export const SEARCH_PERSON_BY_EMAIL = gql`
  query searchByEmail($email: String!) {
    tenant {
      people(first: 1, query: $email) {
        items {
          id
          role
        }
      }
    }
  }
`

const InviteExisting = ({
  email,
  role,
  onClose,
}: {
  email: string
  role: string
  onClose: () => void
}) => {
  const { loading, error, data } = useQuery<searchByEmail>(
    SEARCH_PERSON_BY_EMAIL,
    {
      variables: { email },
    }
  )

  if (loading || error) {
    return null
  }

  const existingRole = data?.tenant?.people.items[0].role || ""
  const personId = data?.tenant?.people.items[0].id || ""

  return (
    <>
      <Title>Existing email address</Title>
      <p style={{ marginTop: 0 }}>
        Email
        <br />
        {email}
      </p>
      <p>
        Role
        <br />
        {rolesMap[role]}
      </p>
      <p>
        This email already has an existing {rolesMap[existingRole]} account.{" "}
        <Link to={`/person/${personId}`}> View User Profile</Link>.{" "}
      </p>
      <p>
        Emails used to create a User account cannot access the Middle Office.{" "}
      </p>
      <ButtonWrapper>
        <Button
          type="button"
          text="OK"
          mainAction={false}
          loading={false}
          disabled={false}
          onClick={() => onClose()}
        />
      </ButtonWrapper>
    </>
  )
}

export default InviteExisting
