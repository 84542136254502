import React, { FormEventHandler, useState } from "react"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import InputFieldNew from "../AddNewFundFact/InputField"
import { ButtonView } from "../../Admin/InviteAdminModal/styles"
import Button from "../../../components/Button"
import { capitalizeFirst } from "../AddNewFundFact/constants"
import useInputState, { maxValue, notEmpty } from "../../../hooks/useInputState"

const UpdateFundFactForm = ({
  clientFund,
  assetClass,
  subCategory,
  percentage,
  onSubmit,
}: {
  onSubmit: (subcategoryEdit: string, percentageEdit: number) => void
  fundId: string
  clientFund: string
  assetClass: string
  subCategory: string
  percentage: number
}) => {
  const [stateClientFund] = useState<{
    clientFund: string
  }>({
    clientFund,
  })
  const [stateAssetClass] = useState<{
    assetClass: string
  }>({
    assetClass,
  })
  const [stateSubCategory] = useState<{
    subCategory: string
  }>({
    subCategory,
  })

  const [statePercentage] = useState<{
    percentage: number
  }>({
    percentage,
  })
  const clientFundValue = stateClientFund?.clientFund
  const assetClassValue = stateAssetClass?.assetClass
  const subCategoryValue = stateSubCategory.subCategory
  const percentageValue =
    statePercentage?.percentage === null ? 0 : statePercentage?.percentage
  const subcategoryField = useInputState(subCategoryValue, [
    notEmpty("Subcategory is required"),
  ])
  const percentageField = useInputState(percentageValue.toString(), [
    maxValue("Must be a valid number"),
  ])
  const validPercentage = percentageField?.hasValidValue
  const handleSubmit: FormEventHandler = async (ev): Promise<void> => {
    ev.preventDefault()
    onSubmit(
      capitalizeFirst(subcategoryField?.value),
      parseFloat(percentageField?.value)
    )
  }

  return (
    <>
      <Title>{strings.EDIT_FACTSHEET}</Title>
      <form onSubmit={handleSubmit}>
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Client Fund"
          value={clientFundValue}
          onChange={() => {}}
          onBlur={() => {}}
          errorMessage=""
          disabled
          placeholder=""
        />
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Asset Class"
          value={assetClassValue}
          onChange={() => {}}
          onBlur={() => {}}
          disabled
          errorMessage=""
          placeholder=""
        />
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Subcategory"
          value={subcategoryField?.value}
          onChange={subcategoryField?.onChange}
          onBlur={subcategoryField?.onBlur}
          errorMessage={subcategoryField?.errorMessage}
          placeholder=""
        />
        <InputFieldNew
          type="number"
          fieldName="text"
          labelText="Percentage"
          value={percentageField?.value}
          onChange={percentageField?.onChange}
          onBlur={percentageField?.onBlur}
          errorMessage={percentageField?.errorMessage}
          placeholder=""
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={!validPercentage}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default UpdateFundFactForm
