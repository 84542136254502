import React, { FC } from "react"
import styled from "styled-components"

import { initAuth } from "./auth"
import { initFeatureConfig } from "./feature-config"
import Routes from "./screens"

const StyledApp = styled.div`
  font-family: ${(props) => props.theme.font};
`
const App: FC = () => {
  initAuth()

  initFeatureConfig({
    AdminManagement: (_, role) =>
      role !== null && ["Admin", "SuperAdmin"].includes(role),
    ExternalIdAsAccountId: (tenant) => tenant === "IL",
    HideIPAddress: (tenant) => tenant === "IL",
    TagAsDocumentFilename: (tenant) => tenant === "IL",
  })

  return (
    <StyledApp>
      <Routes />
    </StyledApp>
  )
}

export default App
