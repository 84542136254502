import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import CreatableSelect from "react-select/creatable"
import styled from "styled-components"
import {
  getDistinctSubcategoryValues,
  getDistinctSubcategoryValuesVariables,
} from "../../../../@types/graphql"
import { GET_SUBCATEGORY_DROPDOWN } from "../../queries"
import { createOption, Option } from "../constants"
import strings from "../../../../components/localization/strings"

const LabelView = styled.div`
  margin: 0.3em;
`

const SubcategoryDropDown = ({
  assetClass,
  parentCallback,
}: {
  parentCallback: (subcategory: string) => void
  assetClass: string
}) => {
  /** * SUBCATEGORY VALUES */
  let subcategoryRows: any
  let subcategoryValue: any

  const [value, setValue] = useState<Option | null>()
  const [isLoading, setIsLoading] = useState(false)

  const { data: dataSubcategory, refetch } = useQuery<
    getDistinctSubcategoryValues,
    getDistinctSubcategoryValuesVariables
  >(GET_SUBCATEGORY_DROPDOWN, {
    variables: { asset_class: assetClass },
    fetchPolicy: "network-only",
  })
  const subcategoryData = dataSubcategory?.getDistinctSubcategoryValues
  const subcategoryFromDB = subcategoryData?.map(({ subcategory }) => {
    subcategoryRows = subcategory
    const subcategoryRowValues = createOption(subcategoryRows)
    return subcategoryRowValues
  })
  const [, setSubcategoryOptions] = useState(subcategoryFromDB)
  // eslint-disable-next-line prefer-const
  subcategoryValue = value === undefined ? "" : value?.value

  /* eslint-disable */
  useEffect(() => {
    ;(async (): Promise<void> => {
      if (dataSubcategory?.getDistinctSubcategoryValues) {
        parentCallback(subcategoryValue)
        refetch()
      }
    })()
  }, [subcategoryValue, dataSubcategory, refetch])

  const handleCreateSubcategory = (inputValue: string) => {
    setIsLoading(true)
    setTimeout(() => {
      const newOption = createOption(inputValue)
      setIsLoading(false)
      setSubcategoryOptions((prev) => [
        ...(prev !== undefined ? prev : []),
        newOption,
      ])
      setValue(newOption)
    }, 1000)

    return value
  }
  return (
    <>
      <br />
      <LabelView>{strings.SUBCATEGORY}</LabelView>
      <CreatableSelect
        isDisabled={isLoading}
        placeholder="Enter Subcategory"
        isLoading={isLoading}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#888888",
          }),
        }}
        onChange={(newValue) => setValue(newValue)}
        onCreateOption={handleCreateSubcategory}
        isClearable
        options={subcategoryFromDB}
        value={value}
      />
    </>
  )
}
export default SubcategoryDropDown
