import { gql } from "@apollo/client"

export const CREATE_SHARE_HOLDING = gql`
  mutation createShareHoldings(
    $clientfund: String!
    $title: String!
    $percentage: Float!
  ) {
    createShareHoldings(
      input: { clientfund: $clientfund, title: $title, percentage: $percentage }
    ) {
      clientfund
      title
      percentage
    }
  }
`
export const FETCH_SHARE_HOLDINGS = gql`
  query getShareHoldings {
    getShareHoldings {
      id
      clientfund
      title
      percentage
    }
  }
`
export const FETCH_UNIQUE_SHARES = gql`
  query getCountShareHoldings($clientfund: String!, $title: String!) {
    getCountShareHoldings(input: { clientfund: $clientfund, title: $title }) {
      rowCount
    }
  }
`

export const DELETE_SHARE_HOLDING = gql`
  mutation deleteShareHolding($id: String!) {
    deleteShareHolding(input: { id: $id }) {
      percentage
    }
  }
`
/** Updating Share Values */
export const UPDATE_SHARE_HOLDING = gql`
  mutation updateShareHolding(
    $id: String!
    $title: String!
    $percentage: Float!
  ) {
    updateShareHolding(
      input: { id: $id, title: $title, percentage: $percentage }
    ) {
      id
      clientfund
      title
      percentage
    }
  }
`

export const GET_CLIENT_FUND_SHARES = gql`
  query getDistinctClientFunds {
    getDistinctClientFunds {
      clientfund
    }
  }
`
