import React from "react"
import styled, { keyframes } from "styled-components"

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: ${(props) => `3px solid ${props.theme.primary}`};
  border-right: ${(props) => `3px solid ${props.theme.primary}`};
  border-bottom: ${(props) => `3px solid ${props.theme.primary}`};
  border-left: 3px solid white;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 10em;
  left: 40em;
`
const Loader = () => <Spinner />

export default Loader
