import { useQuery } from "@apollo/client"
import React, { FC, useState } from "react"
import styled from "styled-components"
import {
  getShareHoldings,
  getShareHoldings_getShareHoldings,
} from "../../@types/graphql"
import Table, { Row } from "../../components/Table"
import Button from "../../components/Button"
import { Paragraph, Wrapper } from "../Dashboard/styles"
import strings from "../../components/localization/strings"
import { FETCH_SHARE_HOLDINGS } from "./queries"
import AddNewShareModal from "./AddNewShareHolding"
import DeleteShareModal from "./DeleteShareHolding"
import UpdateShareModal from "./UpdateShareHolding.tsx"

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonView = styled.div`
  padding-left: 1em;
  width: 20%;
`
const ShareHoldings: FC = () => {
  const headers = ["Client Fund", "Title", "Percentage", "", "Action"]
  const { data, refetch } = useQuery<
    getShareHoldings,
    getShareHoldings_getShareHoldings
  >(FETCH_SHARE_HOLDINGS)

  const shareHoldingData = data?.getShareHoldings
  const [showAddShare, setShowAddShare] = useState<boolean>(false)
  const [showUpdateShare, setShowUpdateShare] = useState<boolean>(false)
  const [showDeleteShare, setShowDeleteShare] = useState<boolean>(false)
  const [id, setId] = useState<string>(" ")
  const [clientFundValue, setClientFundValue] = useState<string>("")
  const [titleValue, setTitleValue] = useState<string>(" ")
  const [percentageValue, setPercentageValue] = useState<number>(0)
  const [, setReload] = useState(false)

  const shareHoldingRows = shareHoldingData?.map(
    ({ id: shareId, clientfund, title, percentage }) => {
      const deleteButton = (
        <Button
          type="button"
          text="Delete"
          mainAction={false}
          onClick={() => {
            setId(shareId)
            setShowDeleteShare(true)
          }}
          loading={false}
        />
      )

      const updateButton = (
        <Button
          type="button"
          text="Update"
          mainAction={false}
          onClick={() => {
            setId(shareId)
            setClientFundValue(clientfund)
            setTitleValue(title)
            if (percentage !== null) setPercentageValue(percentage)
            setShowUpdateShare(!showUpdateShare)
          }}
          loading={false}
        />
      )

      const DeleteShareButton = () => {
        return deleteButton
      }

      const UpdateShareButton = () => {
        return updateButton
      }
      const shareValues = [
        clientfund,
        title,
        percentage !== 0 ? [percentage?.toString(), " %"] : null,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        UpdateShareButton() as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        DeleteShareButton() as any,
      ]
      return <Row key={shareId} values={shareValues} />
    }
  )
  return (
    <>
      {showAddShare && (
        <AddNewShareModal
          onClose={() => {
            setShowAddShare(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
        />
      )}
      {showUpdateShare && (
        <UpdateShareModal
          onClose={() => {
            setShowUpdateShare(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          fundId={id}
          clientFund={clientFundValue}
          title={titleValue}
          percentage={percentageValue}
        />
      )}
      {showDeleteShare && (
        <DeleteShareModal
          onClose={() => {
            setShowDeleteShare(false)
          }}
          parent2Callback={() => {
            refetch()
            setReload(true)
          }}
          shareId={id}
        />
      )}
      <Wrapper>
        <TopArea>
          <Paragraph>{strings.SHARE_HOLDINGS}</Paragraph>
          <ButtonView>
            <Button
              type="button"
              text={strings.ADD_SHARE}
              mainAction={false}
              onClick={() => setShowAddShare(true)}
              loading={false}
            />
          </ButtonView>
        </TopArea>
        <TopArea>
          <Table columnNames={headers} isEmpty={shareHoldingRows?.length === 0}>
            {shareHoldingRows}
          </Table>
        </TopArea>
      </Wrapper>
    </>
  )
}

export default ShareHoldings
