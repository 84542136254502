import styled from "styled-components"

export const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
