import styled from "styled-components"

export const TitleText = styled.p`
  font-weight: ${({ theme }): string => theme.fontBold};
  font-family: ${({ theme }): string => theme.font};
  font-size: ${({ theme }): string => theme.titleFontSize};
  color: ${({ theme }): string => theme.secondaryAction};
  margin: 0;
  padding-bottom: 0.7em;
  padding-right: 1.5em;
`

export const Paragraph = styled.p`
  line-height: 1.3em;
  margin: 0;
  padding: 10px 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  color: black;
  padding: 30px;
  border-radius: 5px;
  width: 20em;
`

export const ButtonView = styled.div`
  align-self: center;
  padding-top: 1.1em;
  width: 40%;
`

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.error};
  margin-bottom: 0.5em;
`
