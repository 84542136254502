import React, { FormEventHandler, useState } from "react"
import strings from "../../../components/localization/strings"
import Title from "../../../components/Title"
import { ButtonView } from "../../Admin/InviteAdminModal/styles"
import Button from "../../../components/Button"
import useInputState, { notEmpty } from "../../../hooks/useInputState"
import InputFieldNew from "../../FundFactSheets/AddNewFundFact/InputField"
import { capitalizeFirst } from "../../FundFactSheets/AddNewFundFact/constants"

const UpdateMarketInsight = ({
  title,
  subtitle,
  description,
  onSubmit,
}: {
  onSubmit: (
    titleEdit: string,
    subtitleEdit: string,
    descriptionEdit: string
  ) => void
  marketId: string
  title: string
  subtitle: string
  description: string
}) => {
  const [stateTitle] = useState<{
    title: string
  }>({
    title,
  })

  const [stateSubtitle] = useState<{
    subtitle: string
  }>({
    subtitle,
  })
  const [stateDescription] = useState<{
    description: string
  }>({
    description,
  })
  const titleValue = stateTitle.title
  const subtitleValue =
    stateSubtitle?.subtitle === null ? "" : stateSubtitle?.subtitle
  const descriptionValue =
    stateDescription?.description === null ? "" : stateDescription?.description
  const titleField = useInputState(titleValue, [notEmpty("Title is required")])
  const subtitleField = useInputState(subtitleValue, [
    notEmpty("Subtitle is required"),
  ])
  const descriptionField = useInputState(descriptionValue, [
    notEmpty("Description is required"),
  ])
  const validTitle = titleField?.hasValidValue
  const validSubtitle = subtitleField?.hasValidValue
  const validDescription = descriptionField?.hasValidValue
  const isDisabled = !(validTitle && validSubtitle && validDescription)
  const handleSubmit: FormEventHandler = async (ev): Promise<void> => {
    ev.preventDefault()
    onSubmit(
      capitalizeFirst(titleField?.value),
      subtitleField?.value,
      descriptionField?.value
    )
  }

  return (
    <>
      <Title>{strings.UPDATE_INSIGHT}</Title>
      <form onSubmit={handleSubmit}>
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Title"
          value={titleField?.value}
          onChange={titleField?.onChange}
          onBlur={titleField?.onBlur}
          errorMessage={titleField?.errorMessage}
          placeholder=""
        />
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Title"
          value={subtitleField?.value}
          onChange={subtitleField?.onChange}
          onBlur={subtitleField?.onBlur}
          errorMessage={subtitleField?.errorMessage}
          placeholder=""
        />
        <InputFieldNew
          type="text"
          fieldName="text"
          labelText="Title"
          value={descriptionField?.value}
          onChange={descriptionField?.onChange}
          onBlur={descriptionField?.onBlur}
          errorMessage={descriptionField?.errorMessage}
          placeholder=""
        />
        <ButtonView>
          <Button
            type="submit"
            text="Next"
            loading={false}
            disabled={isDisabled}
          />
        </ButtonView>
      </form>
    </>
  )
}

export default UpdateMarketInsight
