import React, { ReactNode, useState } from "react"
import { CgClose } from "react-icons/cg"
import styled from "styled-components"
import ConfirmDeleteInsight from "./confirmDeleteMarketInsight"

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #20200020;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBox = styled.div`
  position: relative;
  padding: 50px;
  max-width: 30%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 15px;
`

enum DeletingMarketInsight {
  Confirm,
  Error,
}

const ModalCard = ({
  onClose,
  children,
}: {
  onClose: () => void
  children: ReactNode
}) => {
  return (
    <ModalBox
      data-testid="modalCard"
      onClick={(ev) => {
        ev.stopPropagation()
      }}
    >
      <CloseButton onClick={() => onClose()}>
        <CgClose size={24} />
      </CloseButton>
      {children}
    </ModalBox>
  )
}

const DeleteMarketModal = ({
  onClose,
  parent2Callback,
  marketId,
}: {
  onClose: () => void
  parent2Callback: () => void
  marketId: string
}) => {
  const [step, setStep] = useState<DeletingMarketInsight>(
    DeletingMarketInsight.Confirm
  )

  const [errordata, setErrorData] = useState<{
    error: string
  }>({
    error: "",
  })

  return (
    <Overlay onClick={() => onClose()}>
      <ModalCard onClose={onClose}>
        {step === DeletingMarketInsight.Confirm && (
          <ConfirmDeleteInsight
            marketId={marketId}
            onCancel={() => onClose()}
            onError={(error) => {
              setErrorData((prev) => ({ ...prev, error }))
              setStep(DeletingMarketInsight.Error)
            }}
            onSuccess={() => onClose()}
            parentCallback={() => parent2Callback()}
          />
        )}
        {step === DeletingMarketInsight.Error &&
          `API Error: ${errordata.error}`}
      </ModalCard>
    </Overlay>
  )
}

export default DeleteMarketModal
