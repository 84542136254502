import { gql, MutationTuple, useMutation } from "@apollo/client"
import {
  CreateDepositMutation,
  CreateDepositMutationVariables,
} from "../../../@types/graphql"

export const CREATE_DEPOSIT_MUTATION = gql`
  mutation CreateDepositMutation(
    $accountId: String!
    $amount: Decimal!
    $sourceOfFunds: String
    $tags: [String!]
  ) {
    registerCardDeposit(
      input: {
        accountId: $accountId
        value: { amount: $amount, currency: "EUR" }
        sourceOfFunds: $sourceOfFunds
        tags: $tags
      }
    ) {
      id
      account {
        id
        pendingDeposits: deposits(includeLastStatuses: [Pending]) {
          items {
            id
            version
            value {
              amount
            }
            sourceOfFunds
            tags
          }
        }
      }
    }
  }
`

export default (): MutationTuple<
  CreateDepositMutation,
  CreateDepositMutationVariables
> => useMutation(CREATE_DEPOSIT_MUTATION)
